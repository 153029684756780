import React, { useState } from "react";
import { Row } from "react-bootstrap";
// import Rating from "react-rating";

import { Rating, ThinStar } from "@smastrom/react-rating";

import "@smastrom/react-rating/style.css";

const OneRating = ({ topic, handleFn, handleVal, id }) => {
  // const [rating, setRating] = useState(0); // Initial value

  const myStyles = {
    itemShapes: ThinStar,
    activeFillColor: "#ffb700",
    inactiveFillColor: "#9b9b9b",
  };

  // console.log("rating", rating);

  return (
    <div className="d-flex justify-content-between w-100  align-items-center">
      <div className="">{topic} </div>
      <div className="">
        <Rating
          itemStyles={myStyles}
          style={{ maxWidth: 150 }}
          value={handleVal}
          onChange={handleFn}
          halfFillMode="svg"
          fractions={2}
          spaceBetween="small"
        />
      </div>
    </div>
  );
};

export default OneRating;
