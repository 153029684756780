import React from "react";
import { createChat, createConversation } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateChat as updateChatApi } from "./../services/candidateApi";
import { updateProfileData } from "../services/apiSetting";
import { toast } from "react-toastify";

export const useUpdateProfileData = () => {
  const queryClient = useQueryClient();

  const { mutate: updateProfileDetails, isPending } = useMutation({
    mutationFn: ({ data }) => updateProfileData(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["getMe"],
      });
      toast.success("Profile Updated Successfully!");
    },
    onError: (error) => {
      toast.error("Error during update Profile Data: ", error);
    },
  });

  return { updateProfileDetails, isPending };
};
