import { useQuery } from "@tanstack/react-query";
import React from "react";
import {
  getAllRecruiter,
  getAllcandidate,
  getMe,
} from "../services/candidateApi";

export const useGetAllRecruiter = () => {
  const { data: recruiter, isPending } = useQuery({
    queryKey: ["recruiter"],
    queryFn: getAllRecruiter,
  });

  return { recruiter, isPending };
};
