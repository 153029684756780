import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getAllcandidate, getMe } from "../services/candidateApi";

export const useGetAllCandidate = () => {
  const { data: allCandidate, isPending } = useQuery({
    queryKey: ["allCandidate"],
    queryFn: getAllcandidate,
  });

  return { allCandidate, isPending };
};
