import React, { useState } from "react";
import axios from "axios";
import ChatInterface from "../components/ChatInterface";
import Trends from "../components/Trends";
import Spinner from "../ui/Spinner";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import { newsData } from "../services/recruiterApi";
import "../css/InterviewPrep.css"; // Ensure CSS is styled similarly to JobSearch
import { useQuery } from "@tanstack/react-query";
import CustomizedSpinner from "../ui/CustomizedSpinner";

const InterviewPrepRecruiter = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [awaitingNextAction, setAwaitingNextAction] = useState(false);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const token = sessionStorage.getItem("authToken");

  // Fetching interview preparation news using React Query, similarly to job news
  const { data: interviewNews, isLoading } = useQuery({
    queryKey: ["news", "interview news"],
    queryFn: newsData,
  });

  if (isLoading) return <CustomizedSpinner />;

  const handleFilesUploaded = (uploadedFiles) => {
    setFiles([...files, ...Array.from(uploadedFiles)]);
    setChatMessages([
      ...chatMessages,
      {
        bot: "Thank you for uploading! Please specify the role your candidate is interested in for tailored interview prep.",
      },
    ]);
    setShowFileUpload(false);
  };

  const handleConversation = async (roles) => {
    const token = sessionStorage.getItem("authToken");
    if (awaitingNextAction) {
      handleYesNoResponse(roles);
      return;
    }

    if (!roles || roles.length < 2) {
      setChatMessages([
        ...chatMessages,
        { bot: "Please enter a valid role description." },
      ]);
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("cvs", file);
      });

      const response = await axios.post(
        `${baseUrl}/files/interview-prep/recruiter/?roles=${roles}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setChatMessages([
        ...chatMessages,
        { bot: JSON.stringify(response.data) },
      ]);
      setLoading(false);
    } catch (error) {
      console.error("Error processing request:", error);
      setChatMessages([
        ...chatMessages,
        {
          bot: "We encountered an error while processing your request. Please check your connection and try again later",
        },
      ]);
      setLoading(false);
    }
  };

  const handleYesNoResponse = (userResponse) => {
    if (userResponse.toLowerCase() === "yes") {
      setShowFileUpload(false);
      setChatMessages([
        ...chatMessages,
        { bot: "Please upload another document." },
      ]);
      setFiles([]);
      setAwaitingNextAction(false);
      setTimeout(() => setShowFileUpload(true), 100);
    } else if (userResponse.toLowerCase() === "no") {
      setChatMessages([
        ...chatMessages,
        {
          bot: "Thank you for your time. Good luck with your interview preparation!",
        },
      ]);
      setAwaitingNextAction(false);
    } else {
      setChatMessages([
        ...chatMessages,
        { bot: "Please respond with 'yes' or 'no'." },
      ]);
    }
  };

  if (isLoading) {
    return <CustomizedSpinner />;
  }

  return (
    <div
      className="interview-prep-container"
      style={{ height: "95vh", overflow: "scroll", paddingBottom: "30px" }}
    >
      <div
        className="home-container d-flex flex-column justify-content-center"
        style={{ width: "90%", margin: "auto" }}
      >
        <div
          className={`news-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={{ marginBottom: "10px" }}
        >
          <div className="newsPageTitle d-flex flex-row justify-content-between">
            <h5>Interview Prep News (from API)</h5>
            <a
              onClick={() => setNewsPanelVisible(false)}
              className="interview-news-title-close-btn"
            >
              Close
            </a>
          </div>
          <Trends data={interviewNews} />
        </div>
        <div
          className="chat-container"
          style={{ flex: "2 1 0", marginBottom: "30px" }}
        >
          <ChatInterface
            setChatMessages={setChatMessages}
            messages={chatMessages}
            onNewMessage={handleConversation}
            onFilesUploaded={handleFilesUploaded}
            firstMsg={
              <Typewriter
                words={["Please select the candidate's resume in PDF form!"]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default InterviewPrepRecruiter;
