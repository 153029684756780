import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./FocusBarCandidate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SidebarBtn from "../ui/SidebarBtn";
import logo from "./../assets/logo.png";

const renderTooltip = (props, text) => (
  <Tooltip id="button-tooltip" {...props}>
    {text}
  </Tooltip>
);

const FocusBarCoach = ({
  isNewChat = false,
  isSidebarOpen,
  isTemparySidebarOpen,
}) => {
  const isMobile = window.innerWidth <= 768; // Set your mobile breakpoint here
  const tooltipPlacement = isMobile ? "bottom" : "right";
  const location = useLocation();

  return (
    <div className=" sidebar-FocusBarCandidate mx-xl-2 mx-lg-1 ">
      <div
        className={`h-100 align-items-md-center ${
          isSidebarOpen && isTemparySidebarOpen
            ? " align-items-start align-items-lg-start align-items-md-center "
            : "align-items-center"
        } d-flex flex-column  `}
      >
        <div className="position-relative h-100 w-100">
          <div className="d-flex flex-column gap-2  align-items-center position-absolute top-0">
            <div className="-content-center ">
              <div className="d-block d-lg-block d-md-none">
                {isSidebarOpen && isTemparySidebarOpen ? (
                  <Link to="/coach/connect-to-professionals">
                    <img
                      className="mb-2 "
                      src={logo}
                      alt="logo"
                      style={{ height: "28px" }}
                    />
                  </Link>
                ) : (
                  <Link to="/coach/connect-to-professionals">
                    <img
                      className="mb-2"
                      src="/CareerGPT_Brand_Mark_Green.png"
                      alt="logo"
                      style={{ height: "28px" }}
                    />
                  </Link>
                )}
              </div>

              <div className="d-lg-none d-md-block d-none">
                <Link to="/coach/salary">
                  <img
                    className="mb-2"
                    src="/CareerGPT_Brand_Mark_Green.png"
                    alt="logo"
                    style={{ height: "28px" }}
                  />
                </Link>
              </div>
            </div>
            {/* <SidebarBtn

              renderTooltipTest="Tell us your desired job title, location, and upload your resume. We'll analyze job market data and your skills to provide a personalized salary estimate."
              to="/coach/salary"
              text="Salary Sensei"
              iconeFolder="candidateSidebar"
              iconeName="invoice-accounting-documet-chart.png"
              isSidebarOpen={isSidebarOpen}
            /> */}
             <SidebarBtn
              renderTooltipTest="Connect with other users on the CareerGPT platform"
              // renderTooltipTest="Coming Soon"
              to="/coach/connect-to-professionals"
              // to="#"
              text="Connect to Professionals"
              iconeFolder="candidateSidebar"
              iconeName="connetToProf.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            />

            <SidebarBtn
              renderTooltipTest="Find the perfect mentee that exactly matches your background using our unique vectorization algorithm"
              to="/coach/search-candidate"
              text=" Mentee Search"
              iconeFolder="coachSidebar"
              iconeName="searchCand.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            />
            <SidebarBtn
              renderTooltipTest="Tell us your desired job title, location, and upload your resume. We'll analyze job market data and your skills to provide a personalized salary estimate."
              to="/coach/salary"
              text="Salary Sensei"
              iconeFolder="coachSidebar"
              iconeName="invoice-accounting-documet-chart.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            />
            {/* <SidebarBtn
            renderTooltipTest={"coming soon!"}
              renderTooltipTest="Gain insights into how to optimize a candidates resume"
              to="/coach/resume-formatting"
              text="Resume Format Optimization"
              iconeFolder="coachSidebar"
              iconeName="resumeFormatting.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            /> */}
            {/* <SidebarBtn
              renderTooltipTest="updating..."
              to="/coach/resume-formatting"
              text="Resume Formatting"
              iconeFolder="coachSidebar"
              iconeName="resumeFormatting.png"
              isSidebarOpen={isSidebarOpen}
            /> */}
            <SidebarBtn
              renderTooltipTest="Summarize a candidates resume to for a transarent look into their history and what steps they should take next!"
              to="/coach/concise-summary"
              text="Summarize Experience"
              iconeFolder="coachSidebar"
              iconeName="concise.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            />
           

            {/* <SidebarBtn
              // renderTooltipTest={"coming soon!"}
              renderTooltipTest="Summarize a candidates resume to for a transarent look into their history and what steps they should take next!"
              to="/coach/concise-summary"
              iconeFolder="coachSidebar"
              text="concise-summary"
              iconeName="releventWork.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            /> */}
            {/* <SidebarBtn
              renderTooltipTest="Help candidates figure out which skills they need to level up."
              renderTooltipTest={"coming soon!"}
              to="/coach/tailored-skills"
              text="Skill Assessment"
              iconeFolder="coachSidebar"
              iconeName="tailored.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            /> */}

            {/* <SidebarBtn
              renderTooltipTest="Use a candidates resume and skills to recommend what degree program, or course they should pursue next."
              to="/coach/education-certification"
              renderTooltipTest={"coming soon!"}
              text="Education and Certifications"
              iconeFolder="coachSidebar"
              iconeName="educationa.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            /> */}
            {/* <SidebarBtn
              renderTooltipTest="updating..."
              to="/coach/relevant-work"
              iconeFolder="coachSidebar"
              text="Relevant Work Experience"
              iconeName="releventWork.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            /> */}
            {/* <SidebarBtn
              renderTooltipTest="updating..."
              to="/coach/relevant-volunteer"
              iconeFolder="coachSidebar"
              text="Relevant Volunteer Work"
              iconeName="releventVolunt.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            /> */}

            <SidebarBtn
              renderTooltipTest="Upload the candidate's resume and let our AI coach analyze his/her skills and experience to provide actioanble interview guidance."
              to="/coach/interview-prep"
              iconeFolder="candidateSidebar"
              text="Interview Preparation"
              iconeName="interviewPre.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            />
          </div>
          {/* <div className=""> */}
          <div className="position-absolute bottom-0 ">
            <SidebarBtn
              to="/coach/setting/profile"
              text="Settings"
              iconeFolder="candidateSidebar"
              iconeName="settings.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            />
            <SidebarBtn
              to="/coach/help-and-support"
              text="Help & Support"
              iconeFolder="candidateSidebar"
              iconeName="help.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            />
            {/* <SidebarBtn
              renderTooltipTest="Updated!!"
              to="/coach/update"
              text="Updated!"
              iconeFolder="candidateSidebar"
              iconeName="update.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FocusBarCoach;
