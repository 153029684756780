import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSocialMediaData } from "../../services/apiSetting";
import { toast } from "react-toastify";
import { getMe } from "../../services/candidateApi";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../ui/Spinner";
import CustomizedSpinner from "../../ui/CustomizedSpinner";

function SocialProfile() {
  const { data: myData, isPending } = useQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
  });
  useEffect(() => {
    setTempFacebook(myData?.facebook);
    setTempLinkedIn(myData?.linkdin);
    setTempInstagram(myData?.instragram);
    setTempX(myData?.x);
  }, [myData]);
  const [tempFacebook, setTempFacebook] = useState("");
  const [tempLinkedIn, setTempLinkedIn] = useState("");
  const [tempInstagram, setTempInstagram] = useState("");
  const [tempX, setTempX] = useState("");
  const [tempOther, setTempOther] = useState("");
  // const id = localStorage.getItem("userId");

  const queryClient = useQueryClient();

  const {
    mutate: updateSocialMediaDetails,
    isPending: updatingSocialMediaDetails,
  } = useMutation({
    mutationFn: ({ data }) => updateSocialMediaData(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["getMe"],
      });
      // toast.success("Social Profile Updated Successfully");
    },
    onError: (error) => {
      toast.error("Error during update social Profile Data: ", error);
    },
  });
  const saveSocialProfile = async () => {
    const data = {
      facebook: tempFacebook,
      linkdin: tempLinkedIn,
      instragram: tempInstagram,
      x: tempX,
    };
    updateSocialMediaDetails({ data });
  };

  if (isPending) return <CustomizedSpinner />;

  return (
    <div className=" ">
      <div className="mt-3">
        <div className=" fs-xs ">Facebook</div>

        <input
          className="w-100  mt-1 py-2 px-2   fs-sm"
          value={tempFacebook}
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setTempFacebook(e.target.value)}
          onFocus={(e) => (e.target.style.border = "2px solid #525252")}
          onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
        />
      </div>
      <div className="mt-3">
        <div className=" fs-xs ">Linkedin</div>

        <input
          className="w-100  mt-1 py-2 px-2  fs-sm"
          value={tempLinkedIn}
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setTempLinkedIn(e.target.value)}
          onFocus={(e) => (e.target.style.border = "2px solid #525252")}
          onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
        />
      </div>
      <div className="mt-3">
        <div className=" fs-xs ">Instagram</div>

        <input
          className="w-100  mt-1 py-2 px-2 fs-sm"
          value={tempInstagram}
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setTempInstagram(e.target.value)}
          onFocus={(e) => (e.target.style.border = "2px solid #525252")}
          onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
        />
      </div>
      <div className="mt-3">
        <div className=" fs-xs ">X</div>

        <input
          className="w-100  mt-1 py-2 px-2  fs-sm"
          value={tempX}
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setTempX(e.target.value)}
          onFocus={(e) => (e.target.style.border = "2px solid #525252")}
          onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
        />
      </div>
      {/* <div className="mt-3">
        <div className=" fs-xs ">Other</div>

        <input
          className="w-100  mt-1 py-2   fs-sm"
          value={tempOther}
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setTempOther(e.target.value)}
          onFocus={(e) => (e.target.style.border = "2px solid #525252")}
          onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
        />
      </div> */}
      <div className=" d-flex  justify-content-end  mt-4">
        {updatingSocialMediaDetails ? (
          <button
            className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0`}
          >
            <img
              src="/Rolling@1x-1.1s-200px-200px.svg"
              alt="loading.."
              style={{
                width: "20px",
                height: "20px",
              }}
            ></img>
          </button>
        ) : (
          <button
            onClick={saveSocialProfile}
            className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0`}
          >
            <div className="mx-1">Save</div>
          </button>
        )}
      </div>
    </div>
  );
}

export default SocialProfile;
