import React, { useEffect, useRef, useState } from "react";
// import MobileSideBarButton from "./MobileSideBarButton";

import Style from "./../css/candidateNavBar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { baseUrl } from "../services/api";
import axios from "axios";
import NotificationBtn from "./NotificationBtn";
import { getNotification } from "../services/notificationApi";
import { useQuery } from "@tanstack/react-query";
import { useOutsideClick } from "../hooks/useOutSideClick";
import {
  getAllCoach,
  getAllAllCoach,
  getAllRecruiter,
  getAllcandidate,
  getMe,
} from "../services/candidateApi";
import Spinner from "./Spinner";
import NotificationComponent from "./NotificationComponent";
import MobileSideBarButton from "../components/MobileSideBarButton";
import CustomizedSpinner from "./CustomizedSpinner";

import MobileNotification from "./MobileNotification";
import { useGetMe } from "../hooks/useGetMe";
import { useGetAllCandidate } from "../hooks/useGetAllCandidate";
import { useGetAllRecruiter } from "../hooks/useGetAllRecruiter";
import { useGetAllCoach } from "../hooks/useGetAllCoach";
import { useSocket } from "../context/SocketContext";
import SubscriptionModal from "./SubscriptionModal";
import { useUpdateFirstSignIn } from "../hooks/useUpdateFirstSignIn";
import { updateUserFirstSignIn } from "../services/userApi";
// import { useSocket } from "../../provider/Socket.context";
// import getSocketInit from '../socket';

const UserNavbar = ({
  setIsSidebarOpen,
  isSidebarOpen,
  isTemparySidebarOpen,
  setIsTemparySidebarOpen,
}) => {
  const changeFavicon = (faviconPath) => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = faviconPath;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  const [showModal, setShowModal] = React.useState(false);
  const { socket } = useSocket();

  const [notificationData, setNotificationData] = useState([]);
  const [hasNotification, setHasNotification] = useState(false);

  const { data: notification, isPending: isNotification } = useQuery({
    queryKey: ["notification"],
    queryFn: getNotification,
  });

  // const { hasNotification } = useSocket();
  const { myData, isPending: mydataLoading } = useGetMe();

  useEffect(() => {
    if (notification) {
      setNotificationData(notification);

      if (
        notification &&
        Array.isArray(notification) &&
        notification?.length > 0
      ) {
        const isClickNotify = notification.find((notify) => {
          if (!notify.isClick) {
            // console.log("Notification clicked");
            // document.title = "Notification";

            setHasNotification(true);
            return notify;
          }
        });
        // console.log("Is click notify", isClickNotify);

        if (isClickNotify) {
          // console.log("why");
          changeFavicon("/notify-favIcon.png");
        } else {
          changeFavicon("/favicon.png");
        }
      }
    }
  }, [notification]);

  useEffect(() => {
    const handleNotification = (data) => {
      // console.log("Notification data", data);
      // console.log("My data", myData);
      // console.log(+data.userId, myData?.id);
      if (+data.userId == myData?.id) {
        setNotificationData((notify) => [...notify, data]);
        setHasNotification(true);
        console.log("why");
        changeFavicon("/notify-favIcon.png");
      }
    };

    socket.on("notofication", handleNotification);

    return () => {
      socket.off("notofication", handleNotification);
    };
  }, [socket]);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const [isProfileOpen, setIsProfileOpen] = React.useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [isMobileNotificationOpen, setIsMobileNotificationOpen] =
    React.useState(false);
  const handleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const closeNotification = () => setIsNotificationOpen(false);
  const notificationRef = useOutsideClick(closeNotification);

  const closeSidebar = () => setIsMenuOpen(false);
  const sidebarRef = useOutsideClick(closeSidebar);

  const close = () => setIsProfileOpen(false);
  const menuRef = useOutsideClick(close);

  const { allCandidate } = useGetAllCandidate();

  const { recruiter, isPending: loadingRecruiter } = useGetAllRecruiter();

  const { allCoach, isPending: loadingallCoach } = useGetAllCoach();

  useEffect(() => {
    if (!myData?.firstSignIn) {
      setShowModal(false);
    }
  }, [myData?.firstSignIn]);

  if (mydataLoading) {
    return <CustomizedSpinner />;
  }
  if (loadingRecruiter) {
    return <CustomizedSpinner />;
  }

  if (loadingallCoach) {
    return <CustomizedSpinner />;
  }

  if (!pathName.startsWith(`/${myData.userType}/chat`)) {
    setIsTemparySidebarOpen(true);
  } else {
    setIsTemparySidebarOpen(false);
  }

  // const userType = localStorage.getItem("userType");

  function togaleNavbarBtn() {
    setIsMenuOpen(!isMenuOpen);
  }
  // const id = localStorage.getItem("userId");

  const handleLogout = async () => {
    const token = sessionStorage.getItem("authToken");

    try {
      await axios.post(
        `${baseUrl}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Retrieve the token from session storage
          },
        }
      );

      // Remove token from session storage
      sessionStorage.removeItem("authToken");

      // Clear user data from local storage
      localStorage.removeItem("userType");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      localStorage.removeItem("userObject");
      localStorage.removeItem("isPaid");

      if (window.socket) {
        window.socket.disconnect();
      }

      // Navigate to the sign-in page
      navigate("/signin");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleplane = () => {
    navigate(`/${myData.userType}/subscription-plan`);
    setIsProfileOpen(false);
  };

  const handleProfile = () => {
    navigate(`/${myData.userType}/profile`);
    setIsProfileOpen(false);
  };

  const handleNews = () => {
    navigate(`/${myData.userType}/news`);
  };

  const handleChat = () => {
    setIsTemparySidebarOpen(false);
    navigate(`/${myData.userType}/chat`);
  };

  const handleNotification = () => {
    setIsNotificationOpen((isNotificationOpen) => !isNotificationOpen);
  };

  const handleNotificationMobile = () => {
    setIsMobileNotificationOpen((isNotificationOpen) => !isNotificationOpen);
  };

  // const handleClose = () => {
  //   setShowModal(false);
  // };

  async function handleClosebtn() {
    setShowModal(false);
    const update = await updateUserFirstSignIn();
  }

  const handleSuccess = async () => {
    const update = await updateUserFirstSignIn();
    navigate(`/${myData.userType}/subscription-plan`);
    setShowModal(false);
  };

  if (showModal) {
    return (
      <SubscriptionModal
        show={showModal}
        handleClosebtn={handleClosebtn}
        // navigateToSubscription={navigateToSubscription}
        handleSuccess={handleSuccess}
        title="Welcome!"
      >
        <h5>Congratulations on signing in to your account!</h5>
        <p>You have a 7-day free trial to explore all our features.</p>
        <p>Upgrade to our Pro package for our premium features.</p>
      </SubscriptionModal>
    );
  }

  return (
    <>
      {/* <NotificationComponent /> */}

      <div
        className="d-flex justify-content-between align-items-center py-2 px-3"
        style={{
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
        ref={menuRef}
      >
        <button
          onClick={handleSidebar}
          className="border-0 d-lg-block d-none "
          style={{ backgroundColor: "#fff" }}
        >
          {isSidebarOpen && isTemparySidebarOpen ? (
            <img
              src={`/icons/candidateLayoutNav/window-resize-left.png`}
              alt="salary information icon"
              style={{ height: "24px", width: "24px" }}
            />
          ) : (
            <img
              src={`/icons/candidateLayoutNav/Group.png`}
              alt="salary information icon"
              style={{ height: "24px", width: "24px" }}
            />
          )}
        </button>
        <div className=" gap-lg-4 gap-4 gap-md-3 d-md-flex d-none">
          <CandidateNavBarMiddleContent
            number={allCoach ? allCoach : 0}
            text="Total Career Coaches"
            theme={myData.theme}
          />

          <CandidateNavBarMiddleContent
            number={recruiter ? recruiter.length : 0}
            text="Total Recruiters/Hiring Managers"
            theme={myData.theme}
          />

          <CandidateNavBarMiddleContent
            number={allCandidate ? allCandidate : 0}
            text="Total Candidates"
            theme={myData.theme}
          />
        </div>
        <div className="d-md-none gap-2 d-flex align-items-center">
          <button
            onClick={togaleNavbarBtn}
            className="border-0"
            style={{ backgroundColor: "#fff" }}
          >
            {" "}
            <img
              className="mb-2"
              src="/icons/candidateLayoutNav/mobileResponsive.png"
              alt="logo"
              style={{ height: "12px", width: "18px" }}
            />
          </button>
          <div>
            <img
              className="mb-2"
              src="/CareerGPT_Brand_Mark_Green.png"
              alt="logo"
              style={{ height: "28px" }}
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <div>
            {pathName == `/${myData.userType}/chat` ? (
              <button
                onClick={handleChat}
                className="border-0"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={`/chatClick.png`}
                  alt=" icon"
                  style={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: "#fff",
                  }}
                />
              </button>
            ) : (
              <button
                onClick={handleChat}
                className="border-0"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={`/icons/candidateLayoutNav/mailbox-line.png`}
                  alt=" icon"
                  style={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: "#fff",
                  }}
                />
              </button>
            )}
          </div>
          <div>
            {/* <Link to={`${myData.userType}/news`}> */}

            {pathName == `/${myData.userType}/news` ? (
              <button
                onClick={handleNews}
                className="border-0"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={`/clickNews.png`}
                  alt="news icon"
                  style={{ height: "24px", width: "24px" }}
                />
              </button>
            ) : (
              <button
                onClick={handleNews}
                className="border-0"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={`/icons/candidateLayoutNav/suitcase-work-job-document.png`}
                  alt="news icon"
                  style={{ height: "24px", width: "24px" }}
                />
              </button>
            )}
          </div>

          {/* </Link> */}

          <div
            // ref={notificationRef}
            className="d-flex flex-column position-relative"
          >
            {isNotificationOpen ? (
              <button
                onClick={handleNotification}
                className="border-0 d-md-block d-none"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={`/app-clicknotification.png`}
                  alt="notification icon"
                  style={{ height: "24px", width: "24px" }}
                />
              </button>
            ) : (
              <button
                onClick={handleNotification}
                className="border-0 d-md-block d-none"
                style={{ backgroundColor: "#fff" }}
              >
                {hasNotification ? (
                  <img
                    src={`/icons/candidateLayoutNav/app-has-notification.png`}
                    alt="notification icon"
                    style={{ height: "24px", width: "24px" }}
                  />
                ) : (
                  <img
                    src={`/icons/candidateLayoutNav/app-notification.png`}
                    alt="notification icon"
                    style={{ height: "24px", width: "24px" }}
                  />
                )}
              </button>
            )}
            {isMobileNotificationOpen ? (
              <button
                onClick={handleNotificationMobile}
                className="border-0 d-md-none d-block"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={`/app-clicknotification.png`}
                  alt="notification icon"
                  style={{ height: "24px", width: "24px" }}
                />
              </button>
            ) : (
              <button
                onClick={handleNotificationMobile}
                className="border-0 d-md-none d-block"
                style={{ backgroundColor: "#fff" }}
              >
                <img
                  src={`/icons/candidateLayoutNav/app-notification.png`}
                  alt="notification icon"
                  style={{ height: "24px", width: "24px" }}
                />
              </button>
            )}
          </div>
          <div>
            <button
              style={{
                backgroundColor: "#fff",
                border: "none",
                padding: "0",
              }}
              onClick={() => setIsProfileOpen(!isProfileOpen)}
            >
              <CandidateProfile image={myData.image} />
            </button>
          </div>

          <Dropdown.Menu
            style={{
              marginTop: "36px",
              border: "none",
            }}
            show={isNotificationOpen}
          >
            <NotificationComponent
              setHasNotification={setHasNotification}
              notification={notificationData}
              setIsNotificationSmallOpen={setIsNotificationOpen}
              isNotificationSmallOpen={isNotificationOpen}
            />
          </Dropdown.Menu>

          {/* <div className="d-sm-none d-inline "> */}
          <Dropdown.Menu
            style={{
              marginTop: "36px",
              border: "none",
            }}
            show={isMobileNotificationOpen}
            // className="d-sm-none d-inline "
          >
            <MobileNotification
              setIsNotificationSmallOpen={setIsMobileNotificationOpen}
              isNotificationSmallOpen={isMobileNotificationOpen}
            />
          </Dropdown.Menu>
          {/* </div> */}

          <Dropdown.Menu
            show={isProfileOpen}
            style={{
              marginTop: "36px",
            }}
          >
            <Dropdown.Item onClick={handleProfile}>My Profile</Dropdown.Item>
            <Dropdown.Item onClick={handleplane}>
              Subscription Tier
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </div>
      </div>

      {/* here is the mobile sidebar */}
      <div
        ref={sidebarRef}
        style={{
          backgroundColor: "#f5f4f4",
          animation: `${
            isMenuOpen ? Style.slideIn : Style.slideOut
          } 0.5s forwards`,
        }}
        className={
          isMenuOpen
            ? "z-3 position-fixed   right-0 top-0 w-75 d-md-none d-block h-100  p-2  "
            : "position-fixed left-[100%] d-none top-0 p-10 "
        }
      >
        <button
          style={{
            backgroundColor: "#f5f4f4",
            color: "#F04438",
          }}
          className="border-0 mb-4"
          onClick={togaleNavbarBtn}
        >
          close
        </button>
        <div className="h-100 border-2 position-relative ">
          <div
            style={{
              justifyContent: "start",
            }}
            className="align-items-start  d-flex flex-column position-absolute top-0 "
          >
            {myData.userType === "candidate" && (
              <>
                          <MobileSideBarButton
                  // renderTooltipTest="coming soon!"
                  renderTooltipTest="Directly connect with other users on the CareerGPT platform."
                  text="Connect to Professionals"
                  to="/candidate/connect-to-professionals"
                  iconeName="connetToProf.png"
                  iconeFolder="candidateSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  // renderTooltipTest="coming soon!"
                  to="/candidate/networking"
                  text="Find the perfect individual to move your career forward using our unique vectorization algorithm."
                  iconeName="searchRec.png"
                  iconeFolder="candidateSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  // renderTooltipTest="coming soon!"
                  renderTooltipTest="Tell us your desired job title, location, and upload your resume. We'll analyze job market data and your skills to provide a personalized salary estimate."
                  to="/candidate/salary"
                  text="Salary Sensei"
                  iconeName="invoice-accounting-documet-chart.png"
                  iconeFolder="candidateSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  renderTooltipTest="Strengthen your resume though AI resume analysis and keyword gap detection."
                  // renderTooltipTest="coming soon!"
                  to="/candidate/resumereview"
                  text="Resume Review"
                  iconeName="resum-review.png"
                  iconeFolder="candidateSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
      
                <MobileSideBarButton
                  renderTooltipTest="Find a job opportunity that matches your experience and requirements."
                  to="/candidate/jobsearch"
                  text="AI Job Search "
                  iconeName="jobSearch.png"
                  iconeFolder="candidateSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  renderTooltipTest="Upload your resume and let our AI coach analyze your skills and experience to help you anticipate what the interviewer might ask."
                  iconeFolder="candidateSidebar"
                  to="/candidate/interview"
                  text="Interview Preparation"
                  iconeName="interviewPre.png"
                  handleSidebar={togaleNavbarBtn}
                />
                {/* <MobileSideBarButton
                  renderTooltipTest="Get expert tips and insights into for your goals."
                  to="/candidate/progress"
                  text="Set and Track Goals"
                  iconeName="goal.png"
                  iconeFolder="candidateSidebar"
                  handleSidebar={togaleNavbarBtn}
                /> */}
              </>
            )}
            {myData.userType === "recruiter" && (
              <>
                <MobileSideBarButton
                  renderTooltipTest="Connect with other users on the CareerGPT platform"
                  to="/recruiter/connect-to-professionals"
                  text="Connect to Professionals"
                  iconeName="conectPro.png"
                  iconeFolder="recruiterSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  renderTooltipTest="Find the perfect individual to move your career forward using our unique vectorization algorithm."
                  to="/recruiter/networking"
                  text="Candidate Matching"
                  iconeName="candidateSerch.png"
                  iconeFolder="recruiterSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  renderTooltipTest="Tell us your desired job title, location, and upload your resume. We'll analyze job market data and your skills to provide a personalized salary estimate."
                  to="/recruiter/salary"
                  text="Salary Sensei"
                  iconeName="invoice-accounting-documet-chart.png"
                  iconeFolder="candidateSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  // renderTooltipTest="Coming Soon"
                  renderTooltipTest="Returns the best matching resume for the job description you have input based on a similarity score."
                  to="/recruiter/resumeranking"
                  text="Resume Ranker"
                  iconeName="resumeRanking.png"
                  iconeFolder="recruiterSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                
                <MobileSideBarButton
                  renderTooltipTest="Fine-tune a job description to include the best keywords to attract the best candidates."

                  to="/recruiter/jobposting"
                  
                  text=" Refine Job Description"
                  iconeName="jobPosting.png"
                  iconeFolder="recruiterSidebar"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  // renderTooltipTest="Prepare for your next interview with a candidate"
                  renderTooltipTest="coming soon!"
                  iconeFolder="recruiterSidebar"
                  to="/recruiter/interview"
                  text="Interview Preparation Assistance"
                  iconeName="interviewPrep.png"
                  handleSidebar={togaleNavbarBtn}
                />
              </>
            )}
            {myData.userType === "coach" && (
              <>
                 <MobileSideBarButton
                  renderTooltipTest="Connect with other users on the CareerGPT platform"
                  // renderTooltipTest="Coming Soon"
                  to="/coach/connect-to-professionals"
                  // to="#"
                  text="Connect to Professionals"
                  iconeFolder="candidateSidebar"
                  iconeName="connetToProf.png"
                  isSidebarOpen={isSidebarOpen}
                  isTemparySidebarOpen={isTemparySidebarOpen}
                />
                {/* <MobileSideBarButton
                  renderTooltipTest="coming soon!"
                  to="/coach/tailored-skills"
                  text="Skill Assessment"
                  iconeFolder="coachSidebar"
                  iconeName="tailored.png"
                  handleSidebar={togaleNavbarBtn}
                /> */}
                <MobileSideBarButton
                  renderTooltipTest="Find the perfect individual to move your career forward using our unique vectorization algorithm."
                  to="/coach/search-candidate"
                  text=" Mentee Search"
                  iconeFolder="coachSidebar"
                  iconeName="searchCand.png"
                  handleSidebar={togaleNavbarBtn}
                />
                <MobileSideBarButton
                  renderTooltipTest="Tell us your desired job title, location, and upload your resume. We'll analyze job market data and your skills to provide a personalized salary estimate."
                  to="/coach/salary"
                  text="Salary Sensei"
                  iconeFolder="coachSidebar"
                  iconeName="invoice-accounting-documet-chart.png"
                  handleSidebar={togaleNavbarBtn}
                />

                {/* <MobileSideBarButton
                  renderTooltipTest="coming soon!"
                  to="/coach/resume-formatting"
                  text="Resume Format Optimization"
                  iconeFolder="coachSidebar"
                  iconeName="resumeFormatting.png"
                  handleSidebar={togaleNavbarBtn}
                /> */}
                <MobileSideBarButton
                  renderTooltipTest="Summarize a candidates resume to for a transarent look into their history and what steps they should take next!"
                  to="/coach/concise-summary"
                  text="Summarize Experience"
                  iconeFolder="coachSidebar"
                  iconeName="concise.png"
                  handleSidebar={togaleNavbarBtn}
                />
                {/* <MobileSideBarButton
                  renderTooltipTest="coming soon!"
                  to="/coach/education-certification"
                  text="Education and Certifications"
                  iconeFolder="coachSidebar"
                  iconeName="educationa.png"
                  handleSidebar={togaleNavbarBtn}
                /> */}
                <MobileSideBarButton
                  renderTooltipTest="Upload the candidate's resume and let our AI coach analyze his/her skills and experience to provide actioanble interview guidance."
                  to="/coach/interview-prep"
                  iconeFolder="candidateSidebar"
                  text="Interview Preparation"
                  iconeName="interviewPre.png"
                  handleSidebar={togaleNavbarBtn}
                />
                {/* <MobileSideBarButton
                  renderTooltipTest="coming soon!"
                  to="/coach/relevant-volunteer"
                  iconeFolder="coachSidebar"
                  text="Relevant Volunteer Work"
                  iconeName="releventVolunt.png"
                  handleSidebar={togaleNavbarBtn}
                /> */}
              </>
            )}
          </div>

          <div className="position-absolute bottom-0 mb-5">
            <div>
              <MobileSideBarButton
                renderTooltipTest="Profile!"
                to={`/${myData.userType}/setting/profile`}
                text="Settings"
                iconeName="settings.png"
                iconeFolder="candidateSidebar"
                handleSidebar={togaleNavbarBtn}
              />
              <MobileSideBarButton
                to={`/${myData.userType}/help-and-support`}
                text="Help & Support"
                iconeName="help.png"
                iconeFolder="candidateSidebar"
                handleSidebar={togaleNavbarBtn}
              />
              {/* <MobileSideBarButton
                renderTooltipTest="Updated!!"
                to={`/${myData.userType}/update`}
                text="Updated!"
                iconeFolder="candidateSidebar"
                iconeName="update.png"
                handleSidebar={togaleNavbarBtn}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserNavbar;

// rafce

function CandidateNavBarMiddleContent({ number, text, theme }) {
  const color =
    theme === "primary"
      ? "#29b475"
      : theme === "secondary"
      ? "#2E90FA"
      : theme === "success"
      ? "#F79009"
      : theme === "danger"
      ? "#F04438"
      : theme === "warning"
      ? "#9E77ED"
      : theme === "light"
      ? "#F63D68"
      : "#3B3D3C";
  return (
    <div
      // style={{
      //   height: "1rem",
      // }}
      className="d-flex   align-items-center gap-2"
    >
      <div
        className={`rounded-2 px-2  bg-${theme}-subtle `}
        style={{
          border: `2px solid ${color}`,

          color: color,
          borderColor: color,
        }}
      >
        {number}
      </div>
      <div className="fw-bold fs-6">{text}</div>
    </div>
  );
}

function CandidateProfile({ image }) {
  return (
    <button
      className="border-0 position-relative "
      style={{ backgroundColor: "#fff" }}
    >
      <img
        className="rounded-circle"
        src={image ? image : `/icons/candidateLayoutNav/user.png`}
        alt="salary information icon"
        style={{ height: "30px", width: "30px" }}
      />
      <div
        className="rounded-circle position-absolute bottom-0 end-0 "
        style={{ width: "16px", height: "16px", backgroundColor: "#fff" }}
      >
        <div
          className={`rounded-circle bg-primary`}
          style={{
            width: "12px",
            height: "12px",
            margin: "2px",
          }}
        ></div>
      </div>
    </button>
  );
}
