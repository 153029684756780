// App.js

import React, { Suspense, lazy, useState } from "react";

import { LinkedInCallback } from "react-linkedin-login-oauth2";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient } from "@tanstack/query-persist-client-core";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";

import ChatSocketHandler from "./components/ChatSocketHandler";
import PrivateRoute from "./components/PrivateRoute";
// import SubscriptionModal from "./components/SubscriptionModal";
import Success from "./components/Success";
import Cansel from "./components/Cansel";
import EmailVerification from "./ui/EmailVerification";

import CandidateLayout from "./layout/CandidateLayout";
import RecruiterLayout from "./layout/RecruiterLayout";
import AuthLayout from "./layout/AuthLayout";
import SettingLayout from "./layout/SettingLayout";
import CoachLayout from "./layout/CoachLayout";

import UserConversation from "./features/chat/UserConversation";
import NoClickChat from "./features/chat/NoClickChat";

import MessageNotification from "./components/MessageNotification";
import { SocketContextProvider, useSocket } from "./context/SocketContext";

import Lock from "./ui/Lock";

import RouteProtecter from "./services/RouteProtecter";
import CustomizedSpinner from "./ui/CustomizedSpinner";

import InterviewPrepRecruiter from "./pages/InterviewPrepRecruiter";

import FeedbackForm from "./pages/FeedbackForm";
import ForgotPassword from "./pages/ForgotPassword";
import InterviewPrep from "./pages/candidateInterviewPreparation/InterviewPrep";
import JobPosting from "./pages/JobPosting";
import JobSearch from "./pages/JobSearch";
import Progress from "./pages/Progress";
import ResumeReviewCandidate from "./pages/ResumeReviewCandidate";
import ResumeReviewRecruiter from "./pages/recruiterResumeRanking/ResumeReviewRecruiter";
import SalaryInsightsRecruiter from "./pages/SalaryInsightsRecruiter";
import SalaryInsightsCoach from "./pages/SalaryInsightsCoach";
import ResumeReviewCoach from "./pages/coach-summarize-experience/SummarizeExperience";
import SalaryInsightsCandidate from "./pages/SalaryInsightsCandidate";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import EmailOrLinkedIn from "./components/EmailOrLinkedIn";
import ResetPassword from "./pages/ResetPassword";
import CandidateProfile from "./pages/CandidateProfile";
import Profile from "./pages/settings/Profile";
import Password from "./pages/settings/Password";
import SocialProfile from "./pages/settings/SocialProfile";
import Resume from "./pages/settings/Resume";
import DeleteProfile from "./pages/settings/DeleteProfile";
import ConnectToProfessionals from "./pages/connectToProfessionals/ConnectToProfessionals";
import StartChat from "./pages/connectToProfessionals/StartChat";
import ResumeFormatting from "./pages/coach-resume-formatting/ResumeFormatting";
import EducationCertification from "./pages/coach-educationCertifications/EducationCertification";
import ReleventWork from "./pages/coach-relevant-work/ReleventWork";
import ResetPasswordOtp from "./pages/ResetPasswordOtp";
import News from "./pages/News";
import Chat from "./pages/Chat";
import RecruiterInterviewPreparation from "./pages/recruter-interview-preparation/RecruiterInterviewPreparation";
import CoachInterviewPreparation from "./pages/coach-interview-preparation/CoachInterviewPreparation";
import CoachSkillAssessment from "./pages/coach-skillAssessment/CoachSkillAssessment";
import CoachVolunteerWork from "./pages/coach-volunteerWork/CoachVolunteerWork";
import Theme from "./pages/settings/Theme";
import User from "./pages/User";
import HelpAndSupport from "./pages/HelpAndSupport";
import SubscriptionPlan from "./pages/SubscriptionPlan";
import CandidateRecruiterMatching from "./pages/CandidateRecruiterMatching";
import RecruiterCandidateMatching from "./pages/RecruiterCandidateMatching";
import NotFound from "./ui/NotFound";
import CareerCoach from "./pages/AICareerCoach/AICareerCoach"

function App() {
  const [show, setShow] = useState(false);
  const [userType, setUserType] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedUserForChat, setSelectedUserForChat] = useState(null);
  const [onChatPage, setOnChatPage] = useState(false);
  const [isNewChat, setIsNewChat] = useState(false);
  //  const {socket}=useSocket()
  //   socket.connect();
  // const [planList, setPlaneList] = useState(false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
      },
    },
  });

  const sessionStoragePersister = createSyncStoragePersister({
    storage: window.sessionStorage,
  });

  persistQueryClient({
    queryClient,
    persister: sessionStoragePersister,
    maxAge: 1, // Specify the max age for cache data in milliseconds
  });

  return (
    <QueryClientProvider
      client={queryClient}
      persistOptions={{ sessionStoragePersister }}
    >
      {/* <MessageNotification/> */}
      <ReactQueryDevtools initialIsOpen={false} />
      <React.Fragment>
        <SocketContextProvider>
          {/* <SubscriptionModal show={show} setShow={setShow} /> */}
          {/* <SubscriptionPlanList show={planList} /> */}

          {/* <Suspense fallback={<CustomizedSpinner />}> */}
          <Router>
            <div className="App">
              <Routes>
                <Route exact path="/linkedin" element={<LinkedInCallback />} />
                <Route
                  path="/"
                  element={
                    <AuthLayout>
                      <SignIn setUserType={setUserType} />
                    </AuthLayout>
                  }
                />

                <Route path="/candidate">
                  <Route
                      path="career-coach"
                      element={
                        <RouteProtecter authorizedUserType="candidate">
                          <CandidateLayout isNewChat={isNewChat}>
                            <CareerCoach />
                          </CandidateLayout>
                        </RouteProtecter>
                      }
                  />
                  <Route path="setting">
                    <Route
                      path="profile"
                      element={
                        <>
                          <RouteProtecter authorizedUserType="candidate">
                            <CandidateLayout isNewChat={isNewChat}>
                              <SettingLayout>
                                <Profile />
                              </SettingLayout>
                            </CandidateLayout>
                          </RouteProtecter>
                        </>
                      }
                    />

                    <Route
                      path="password"
                      element={
                        <RouteProtecter authorizedUserType="candidate">
                          <CandidateLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Password />
                            </SettingLayout>
                          </CandidateLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="socialprofile"
                      element={
                        <RouteProtecter authorizedUserType="candidate">
                          <CandidateLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <SocialProfile />
                            </SettingLayout>
                          </CandidateLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="resume"
                      element={
                        <RouteProtecter authorizedUserType="candidate">
                          <CandidateLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Resume />
                            </SettingLayout>
                          </CandidateLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="deleteprofile"
                      element={
                        <RouteProtecter authorizedUserType="candidate">
                          <CandidateLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <DeleteProfile />
                            </SettingLayout>
                          </CandidateLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="changetheme"
                      element={
                        <RouteProtecter authorizedUserType="candidate">
                          <CandidateLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Theme />
                            </SettingLayout>
                          </CandidateLayout>
                        </RouteProtecter>
                      }
                    />
                  </Route>
                  <Route
                    path="networking"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          {/* <Networking /> */}
                          <CandidateRecruiterMatching />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="subscription-plan"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <SubscriptionPlan />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="profile"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <CandidateProfile />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="user/:id"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <User />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="interview"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <PrivateRoute />
                          <Lock />
                          <InterviewPrep />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="progress"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <Progress />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="help-and-support"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <HelpAndSupport />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    exact
                    path="resumereview"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <PrivateRoute />
                          <Lock />
                          <ResumeReviewCandidate />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="networking"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          {/* <Networking /> */}
                          <CandidateRecruiterMatching />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    index
                    exact
                    path="salary"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <PrivateRoute />
                          <Lock />
                          <SalaryInsightsCandidate />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="news"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <News />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="jobsearch"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <JobSearch />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="chat"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <Chat>
                            <NoClickChat text=" Select a chat to start messaging" />
                          </Chat>
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="chat/:id"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <Chat>
                            <UserConversation />
                          </Chat>
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="connect-to-professionals"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <Lock />
                          <ConnectToProfessionals />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="connect-to-professionals/startChat/:id"
                    element={
                      <RouteProtecter authorizedUserType="candidate">
                        <CandidateLayout isNewChat={isNewChat}>
                          <StartChat />
                        </CandidateLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route path="feedback" element={<FeedbackForm />} />
                </Route>

                <Route path="/recruiter">
                  <Route path="setting">
                    <Route
                      path="profile"
                      element={
                        <RouteProtecter authorizedUserType="recruiter">
                          <RecruiterLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Profile />
                            </SettingLayout>
                          </RecruiterLayout>
                        </RouteProtecter>
                      }
                    />

                    <Route
                      path="profile/:id"
                      element={
                        <>
                          <RouteProtecter authorizedUserType="recruiter">
                            <RecruiterLayout isNewChat={isNewChat}>
                              <SettingLayout>
                                <Profile />
                              </SettingLayout>
                            </RecruiterLayout>
                          </RouteProtecter>
                        </>
                      }
                    />
                    <Route
                      path="password"
                      element={
                        <>
                          <RouteProtecter authorizedUserType="recruiter">
                            <RecruiterLayout isNewChat={isNewChat}>
                              <SettingLayout>
                                <Password />
                              </SettingLayout>
                            </RecruiterLayout>
                          </RouteProtecter>
                        </>
                      }
                    />
                    <Route
                      path="socialprofile"
                      element={
                        <>
                          <RouteProtecter authorizedUserType="recruiter">
                            <RecruiterLayout isNewChat={isNewChat}>
                              <SettingLayout>
                                <SocialProfile />
                              </SettingLayout>
                            </RecruiterLayout>
                          </RouteProtecter>
                        </>
                      }
                    />
                    <Route
                      path="resume"
                      element={
                        <RouteProtecter authorizedUserType="recruiter">
                          <RecruiterLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Resume />
                            </SettingLayout>
                          </RecruiterLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="deleteprofile"
                      element={
                        <RouteProtecter authorizedUserType="recruiter">
                          <RecruiterLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <DeleteProfile />
                            </SettingLayout>
                          </RecruiterLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="changetheme"
                      element={
                        <RouteProtecter authorizedUserType="recruiter">
                          <RecruiterLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Theme />
                            </SettingLayout>
                          </RecruiterLayout>
                        </RouteProtecter>
                      }
                    />
                  </Route>

                  <Route
                    path="chat"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <Chat>
                            <NoClickChat text=" Select a chat to start messaging" />
                          </Chat>
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="chat/:id"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <Chat>
                            <UserConversation />
                          </Chat>
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="news"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <News />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="subscription-plan"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <SubscriptionPlan />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="networking"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          {/* <Networking /> */}
                          <RecruiterCandidateMatching />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="help-and-support"
                    element={
                      <>
                        <RouteProtecter authorizedUserType="recruiter">
                          <RecruiterLayout isNewChat={isNewChat}>
                            <HelpAndSupport />
                          </RecruiterLayout>
                        </RouteProtecter>
                      </>
                    }
                  />

                  <Route
                    path="user/:id"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <User />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />
                  {/* <Route
                    path="interview"
                    element={
                      <>
                        <RouteProtecter authorizedUserType="recruiter">
                          <RecruiterLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <SocialProfile />
                            </SettingLayout>
                          </RecruiterLayout>
                        </RouteProtecter>
                      </>
                    }
                  /> */}
                  <Route
                    path="resume"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <SettingLayout>
                            <Resume />
                          </SettingLayout>
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="deleteprofile"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <SettingLayout>
                            <DeleteProfile />
                          </SettingLayout>
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="changetheme"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <SettingLayout>
                            <Theme />
                          </SettingLayout>
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  {/* </Route> */}

                  <Route
                    path="chat"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <Chat>
                            <NoClickChat text=" Select a chat to start messaging" />
                          </Chat>
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="chat/:id"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <Chat>
                            <UserConversation />
                          </Chat>
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="news"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <News />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="subscription-plan"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <SubscriptionPlan />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="networking"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          {/* <Networking /> */}
                          <RecruiterCandidateMatching />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="help-and-support"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <HelpAndSupport />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="user/:id"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <User />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="interview"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          {/* <InterviewPrep /> */}
                          {/* <InterviewPreparation /> */}
                          {/* <InterviewPrepRecruiter /> */}
                          <RecruiterInterviewPreparation />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <CandidateProfile />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="resumeranking"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <ResumeReviewRecruiter />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="progress"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <Progress />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="salary"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <SalaryInsightsRecruiter />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="jobposting"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <JobPosting />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="connect-to-professionals"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <ConnectToProfessionals />
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="connect-to-professionals/startChat/:id"
                    element={
                      <RouteProtecter authorizedUserType="recruiter">
                        <RecruiterLayout isNewChat={isNewChat}>
                          <StartChat />
                          {/* <ConnectToRecruiter

                        messages={messages}
                        setMessages={setMessages}
                        selectedRecruiter={selectedUserForChat}
                        setSelectedRecruiter={setSelectedUserForChat}
                        setOnChatPage={setOnChatPage}
                        setIsNewChat={setIsNewChat}
                      /> */}

                          {/* <ConnectToProfessionals /> */}
                        </RecruiterLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route path="feedback" element={<FeedbackForm />} />
                </Route>

                <Route path="/coach">
                  <Route path="setting">
                    <Route
                      path="profile"
                      element={
                        <RouteProtecter authorizedUserType="coach">
                          <CoachLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Profile />
                            </SettingLayout>
                          </CoachLayout>
                        </RouteProtecter>
                      }
                    />

                    <Route
                      path="profile/:id"
                      element={
                        <RouteProtecter authorizedUserType="coach">
                          <CoachLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Profile />
                            </SettingLayout>
                          </CoachLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="password"
                      element={
                        <RouteProtecter authorizedUserType="coach">
                          <CoachLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Password />
                            </SettingLayout>
                          </CoachLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="socialprofile"
                      element={
                        <RouteProtecter authorizedUserType="coach">
                          <CoachLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <SocialProfile />
                            </SettingLayout>
                          </CoachLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="resume"
                      element={
                        <RouteProtecter authorizedUserType="coach">
                          <CoachLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Resume />
                            </SettingLayout>
                          </CoachLayout>
                        </RouteProtecter>
                      }
                    />

                    <Route
                      path="deleteprofile"
                      element={
                        <RouteProtecter authorizedUserType="coach">
                          <CoachLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <DeleteProfile />
                            </SettingLayout>
                          </CoachLayout>
                        </RouteProtecter>
                      }
                    />
                    <Route
                      path="changetheme"
                      element={
                        <RouteProtecter authorizedUserType="coach">
                          <CoachLayout isNewChat={isNewChat}>
                            <SettingLayout>
                              <Theme />
                            </SettingLayout>
                          </CoachLayout>
                        </RouteProtecter>
                      }
                    />
                  </Route>
                  <Route
                    path="news"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <News />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="subscription-plan"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <SubscriptionPlan />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />

                  {/* <Route
                  path="connect-to-professionals"
                  element={
                    <CoachLayout isNewChat={isNewChat}>
                      <ConnectToProfessionals />
                    </CoachLayout>
                  }
                /> */}

                  <Route
                    path="user/:id"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <User />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="tailored-skills"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <CoachSkillAssessment />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="help-and-support"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <HelpAndSupport />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="chat"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <Chat>
                            <NoClickChat text=" Select a chat to start messaging" />
                          </Chat>
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="chat/:id"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <Chat>
                            <UserConversation />
                          </Chat>
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="connect-to-professionals"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <ConnectToProfessionals />
                          {/* <ConnectToRecruiter
                        messages={messages}
                        setMessages={setMessages}
                        selectedRecruiter={selectedUserForChat}
                        setSelectedRecruiter={setSelectedUserForChat}
                        setOnChatPage={setOnChatPage}
                        setIsNewChat={setIsNewChat}
                      /> */}
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="connect-to-professionals/startChat/:id"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <StartChat />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="resume-formatting"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <ResumeFormatting />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="salary"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <SalaryInsightsCoach />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="concise-summary"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <ResumeReviewCoach />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <CandidateProfile />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="interview-prep"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <CoachInterviewPreparation />
                          {/* <InterviewPrepCoach /> */}
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="search-candidate"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <RecruiterCandidateMatching />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="education-certification"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <EducationCertification />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />

                  <Route
                    path="relevant-work"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <ReleventWork />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                  <Route
                    path="relevant-volunteer"
                    element={
                      <RouteProtecter authorizedUserType="coach">
                        <CoachLayout isNewChat={isNewChat}>
                          <CoachVolunteerWork />
                        </CoachLayout>
                      </RouteProtecter>
                    }
                  />
                </Route>

                <Route
                  path="/signup"
                  element={<EmailOrLinkedIn />}
                />

                <Route
                  path="/continue-with-email"
                  element={<SignUp setMainUserType={setUserType}/>}
                />

                <Route
                  path="/payment/success"
                  element={<Success setShow={setShow} />}
                />
                <Route
                  path="/payment/cansel"
                  element={<Cansel setShow={setShow} />}
                />
                <Route
                  path="/signin"
                  element={
                    <AuthLayout>
                      <SignIn setUserType={setUserType} />
                    </AuthLayout>
                  }
                />

                <Route
                  path="/forgotpassword"
                  element={
                    <AuthLayout>
                      <ForgotPassword />
                    </AuthLayout>
                  }
                />

                <Route
                  path="/reset-password-otp/:id"
                  element={
                    <AuthLayout>
                      <ResetPasswordOtp />
                    </AuthLayout>
                  }
                />
                <Route
                  path="/reset-password-confirmation/:id"
                  element={
                    <AuthLayout>
                      <ResetPassword />
                    </AuthLayout>
                  }
                />
                <Route
                  path="/candidateLayout"
                  element={
                    <RouteProtecter authorizedUserType="candidate">
                      <CandidateLayout isNewChat={isNewChat}>
                        <SalaryInsightsCandidate />
                      </CandidateLayout>
                    </RouteProtecter>
                  }
                />
                <Route
                  path="/coachLayout"
                  element={
                    <RouteProtecter authorizedUserType="coach">
                      <CoachLayout isNewChat={isNewChat}>
                        <SalaryInsightsCandidate />
                      </CoachLayout>
                    </RouteProtecter>
                  }
                />

                <Route
                  path="/emailVerification/:email"
                  element={<EmailVerification />}
                />
                <Route
                  path="/recruiterlayout"
                  element={
                    <RouteProtecter authorizedUserType="recruiter">
                      <RecruiterLayout isNewChat={isNewChat}>
                        <SalaryInsightsCandidate />
                      </RecruiterLayout>
                    </RouteProtecter>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Router>

          <ChatSocketHandler
            messages={messages}
            setMessages={setMessages}
            selectedUserForChat={selectedUserForChat}
            userType={userType}
            onChatPage={onChatPage}
            setIsNewChat={setIsNewChat}
          />
        </SocketContextProvider>
      </React.Fragment>
    </QueryClientProvider>
  );
}

export default App;
