import { createContext, useContext, useEffect, useState } from "react";
import getSocketInit from "../socket";
import { getNotification } from "../services/notificationApi";
import { useQuery } from "@tanstack/react-query";
import { useGetMe } from "../hooks/useGetMe";

const SocketContext = createContext();

function SocketContextProvider({ children }) {
  const socket = getSocketInit();
  socket.connect();
  // const [notificationData, setNotificationData] = useState([]);
  // const [hasNotification, setHasNotification] = useState(false);

  // const { data: notification, isPending: isNotification } = useQuery({
  //   queryKey: ["notification"],
  //   queryFn: getNotification,
  // });

  // const { myData, isPending } = useGetMe();
  // console.log("My data", myData);

  // useEffect(() => {
  //   if (notification) {
  //     setNotificationData(notification);

  //     notification &&
  //       Array.isArray(notification) &&
  //       notification?.length > 0 &&
  //       notification.find((notify) => {
  //         if (!notify.isClick) {
  //           console.log("Notification clicked");
  //           setHasNotification(true);
  //         }
  //       });
  //   }
  // }, [notification]);


  // useEffect(() => {
  //   const handleNotification = (data) => {
  //     console.log("Notification data", data);
  //     console.log("My data", myData);
  //     console.log(+data.userId, myData?.id);
  //     if (+data.userId == myData?.id) {
  //       setNotificationData((notify) => [...notify, data]);
  //       setHasNotification(true);
  //     }
  //   };

  //   socket.on("notofication", handleNotification);

  //   return () => {
  //     socket.off("notofication", handleNotification);
  //   };
  // }, [socket, myData?.id]);

  // if (isPending) return null;

  return (
    <SocketContext.Provider
      value={{
        socket,
        // isNotification,
        // notificationData,
        // hasNotification,
        // setHasNotification,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

function useSocket() {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketContextProvider");
  }
  return context;
}

export { SocketContextProvider, useSocket };
