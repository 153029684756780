import React from "react";
import { createChat, createConversation } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateNotification } from "../services/notificationApi";

export const useUpdateNotificationStatus = (id) => {
  console.log("useUpdateNotificationStatus");

  const queryClient = useQueryClient();
  const { mutate: changeNotificationStatus, isPending } = useMutation({
    mutationFn: () => updateNotification(),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["notification"],
      });
    },
  });

  return { changeNotificationStatus, isPending };
};
