import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import FileUpload from "./FileUpload";
import "./ChatInterface.css";
import botIcon from "../assets/icons/bot-icon.png";
import userIcon from "../assets/icons/user-icon.png";


import { OverlayTrigger, Tooltip } from "react-bootstrap";

// import { OverlayTrigger, Tooltip } from "react-bootstrap";

import CustomizedSpinner from "../ui/CustomizedSpinner";

import { LuSendHorizonal } from "react-icons/lu";


import { useGetMe } from "../hooks/useGetMe";

function ChatInterfaceWithoutUpload({
    messages = [],
    onNewMessage = null,
  
    firstMsg = "",
    newsPanelVisible,
    setChatMessages,
    showingText
  }) {
    const { myData, isPending } = useGetMe();

  
    const [message, setMessage] = useState("");
    const chatWindowRef = useRef(null);
    
    const [profilePic, setProfilePic] = useState("");

  
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    useEffect(() => {
      setProfilePic(myData?.image || userIcon);
    }, [myData]);
  
    // useEffect(() => {
    //   if (chatWindowRef.current) {
    //     const chatWindow = chatWindowRef.current;
    //     chatWindow.scrollTop = chatWindow.scrollHeight;
    //   }
  
    //   // Bootstrap tooltip activation
    //   var tooltipTriggerList = [].slice.call(
    //     document.querySelectorAll('[data-bs-toggle="tooltip"]')
    //   );
    //   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //     return new Tooltip(tooltipTriggerEl, {
    //       trigger: "hover focus", // This will show the tooltip on hover or focus
    //     });
    //   });
    // }, [messages]);
  
    const createConversation = () => {
      if (onNewMessage) {
        onNewMessage(message);
      }
      setMessage(""); // Clear the user's input.
    };
   
    const handleChange = (event) => {
      setMessage(event.target.value);
    };
  
   
  
    const handleSubmit = (event) => {
      event.preventDefault();
       
      createConversation();
    };
  
  
  
    
 
    if (isPending) return <CustomizedSpinner />;
  
    if (!myData) return;
  
    return (
      <div
        className={`/chat-interface d-flex flex-column pb-1 ${
          newsPanelVisible && "align-self-end"
        }`}
        
      >
        <div
          ref={chatWindowRef}
          className={`${
            !newsPanelVisible && "chat-window"
          } rounded-lg p-sm-3 flex-grow-1 mb-sm-3 overflow-auto overflow-auto `}
          style={{
            backgroundColor: "#eff8ff",
          }}
        >
          {messages.length == 0 && (
            <label className="first-msg-container">
              
              <p className={`first-msg w-75 ${isMobile && "fs-6"} `}>
                {showingText}
              </p>
            </label>
          )}
  
          {messages.map((msg, index) => (
            <div className="chat-message mb-3" key={index}>
              {msg.user ? (
                <>
                  <img
                  src={myData?.image || userIcon}
                  className="rounded-circle bg-black"
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "cover",
                    objectPosition: "25% 25%",
                    marginRight:`10px`,
                    //
                  }}
                  // style={{ width: "35px", marginRight: "10px" }}
                />
                  {`${msg.user}`}
                </>
              ) : typeof msg.bot === "string" ? (
                <>
                  {console.log(msg.bot)}
                  <img
                    src={botIcon}
                    style={{ width: "35px", marginRight: "10px" }}
                  />
                  {`${msg.bot}`}
                </>
              ) : (
                msg.bot
              )}
            </div>
          ))}
        </div>
  
        <div
          className="chat-input overflow-y-scroll mt-3"
          style={{
            maxHeight: "55px",
          }}
        >
          <div className="chat-input-in">
            
          </div>
          <div className="uploaded-files-container d-flex align-items-center flex-grow-1 mb-0">
         
          
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column chat-form mt-2"
        >
          <div className="d-flex my-1 justify-content-between">
            <input
              type="text"
              value={message}
              onChange={handleChange}
              placeholder="Type your message"
              className="form-control-chat-input fs-6"
            />
  
            <div className="d-flex gap-3 ">
             
  
              
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-send">Send your message</Tooltip>}
              >
                <button type="submit" className="border-0 bg-transparent">
                  {/* <img
                  src={SendIcon}
                  alt="Send Icon"
                  className="send-icon-chat-input"
                /> */}
                  <LuSendHorizonal size={28} color="#4f4e4e" />
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </form>
      </div>
    );
  };
export default ChatInterfaceWithoutUpload
