import axios from "axios";
import { baseUrl } from "./api";
import { getToken } from "../util/utilityFunction";
import { toast } from "react-toastify";

export const getMe = async () => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.get(`${baseUrl}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status >= 200 && response.status < 300) {
      const data = response.data;

      return data;
    }

    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const newsData = async () => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.get(`${baseUrl}/news`, {
      params: {
        filter: "salary news",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data && Array.isArray(response.data)) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const newsDataResume = async () => {
  try {
    const token = await getToken();
    if (!token) return null;

    // const { myData, isPending: isMyData } = useGetMe();
    // console.log(myData);

    const response = await axios.get(`${baseUrl}/news`, {
      params: {
        filter: "resume",
        pageSize: 3,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data && Array.isArray(response.data)) {
      const data = response.data;

      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const newsDataAll = async () => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.get(`${baseUrl}/news`, {
      params: {
        filter: "all",
        pageSize: 30,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data && Array.isArray(response.data)) {
      const data = response.data;

      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const interviewNewsData = async () => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.get(`${baseUrl}/news`, {
      params: {
        filter: "interview prep",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data && Array.isArray(response.data)) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const checkUserForgotPassword = async (email) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/resetPassword`, {
      email,
    });
    // console.log(response.data);
    if (response.data) {
      const data = response.data;
      // console.log(data);
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
  // console.log(email);
};

export const resendOtp = async (id) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/resendOtp`, {
      id,
    });
    // console.log(response.data);
    if (response.data) {
      const data = response.data;
      // console.log(data);
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
  // console.log(id);
};

export const getOtp = async (id) => {
  try {
    if (!id) return;
    const response = await axios.get(`${baseUrl}/auth/getOtp/${id}`);

    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const resetNewPassword = async (data) => {
  // console.log(id, password);
  try {
    if (!data.id) return;
    const response = await axios.post(`${baseUrl}/auth/resetNewPassword`, {
      id: data.id,
      password: data.password,
    });
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getAllRecruiter = async () => {
  try {
    const response = await axios.get(`${baseUrl}/recruiters`);
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
  // console.log(id, password);
};

export const getAllUsers = async () => {
  // console.log(id, password);

  try {
    const response = await axios.get(`${baseUrl}/recruiters/getAllUsers`);
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getAllcandidate = async () => {
  try {
    const response = await axios.get(`${baseUrl}/recruiters/candidate`);
    if (response.data) {
      const data = response.data.length;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getAllCoach = async () => {
  try {
    const response = await axios.get(`${baseUrl}/recruiters/getAllCoach`);
    if (response.data) {
      const data = response.data.length;
      console.log(data);
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
  // console.log(id, password);
};

export const getAllConversation = async ({ id }) => {
  try {
    const token = await getToken();
    if (!token) return null;

    // console.log(id, password);

    const response = await axios.get(
      `${baseUrl}/user-conversation/getConversation/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const createChat = async ({ chatData }) => {
  try {
    const response = await axios.post(
      `${baseUrl}/user-conversation/createChat`,
      chatData
    );
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
  // console.log(chatData);
  // console.log(id, password);
};

export const createConversation = async ({ conversationData }) => {
  try {
    const response = await axios.post(
      `${baseUrl}/user-conversation/createConversation`,
      conversationData
    );
    // console.log(response.data);
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const userChat = async (id) => {
  try {
    const token = await getToken();
    if (!token) return null;
    const response = await axios.get(
      `${baseUrl}/user-conversation/getuserChat/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const data = response.data;

      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const updateChat = async (id) => {
  try {
    const token = await getToken();
    if (!token) return null;
    const response = await axios.get(
      `${baseUrl}/user-conversation/updateChat/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    if (response.data) {
      const data = response.data;

      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const updateUserRate = async (data) => {
  try {
    // console.log(id);
    const token = await getToken();
    if (!token) return null;
    const response = await axios.post(`${baseUrl}/rate`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    if (response.data) {
      const data = response.data;

      return data;
    }
    return;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getMyRate = async () => {
  try {
    // console.log(data);
    // console.log(id);
    const token = await getToken();
    if (!token) return null;
    const response = await axios.get(`${baseUrl}/rate/myRate`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      const data = response.data;

      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getUserRate = async (id) => {
  try {
    // console.log(data);
    // console.log(id);
    const token = await getToken();
    if (!token) return null;
    const response = await axios.get(`${baseUrl}/rate/userRate/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      const data = response.data;

      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getOneUser = async ({ id }) => {
  try {
    // console.log(data);
    // console.log(id);

    const token = await getToken();
    if (!token) return null;
    const response = await axios.get(`${baseUrl}/recruiters/oneUser/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      const data = response.data;

      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getFreePlan = async () => {
  try {
    // console.log(data);
    // console.log(id);
    const token = await getToken();
    if (!token) return null;

    const response = await axios.get(
      `${baseUrl}/stripe/create-checkout-session/getFreePlan`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const data = response.data;

      return data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getProPlan = async () => {
  try {
    const token = await getToken();
    if (!token) return null;

    const res = await axios.post(
      `${baseUrl}/stripe/create-checkout-session`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }
    // return res.data;
    // if (res.status === 200) {
    //   window.location.href = res.data.url;
    // } else if (res.status === 201) {
    //   toast.error("You already paid. Please wait for page to refresh");
    //   window.location.reload();
    // } else {
    //   toast.error("Some error occurred. Please try again");
    // }
  } catch (err) {
    console.log(err);
    toast.error("Server error occurred. Please try again");
    return null;
  }
};
