import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getMe } from "../services/candidateApi";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../ui/Spinner";
import { FaFileCsv } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Rating from "react-rating";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CustomizedSpinner from "../ui/CustomizedSpinner";
import UserProfileRate from "../ui/UserProfileRate";
import ReviewCarousel from "../ui/ReviewCarousel";
import { useGetMe } from "../hooks/useGetMe";
import { useGetMyRate } from "../hooks/useGetMyRate";

const CandidateProfile = () => {
  const { myData, isPending } = useGetMe();
  const { myRate, isPending: updateMyRating } = useGetMyRate();

  if (isPending || updateMyRating) return <CustomizedSpinner />;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Your rating is {(myData.rating / myData.ratingUsers).toFixed(1)}
    </Tooltip>
  );
  return (
    <Container className="fluid z-1">
      <Row
        style={{ height: "88vh" }}
        className="m-2 justify-content-center overflow-auto"
      >
        <Row className={`rounded-3 h-25 bg-${myData.theme}`}></Row>
        <Row
          style={{ height: "100vh" }}
          className="justify-content-center h-75"
        >
          <Row className="justify-content-center">
            <Col className="position-relative col-xxl-9 col-12">
              <div
                style={{ backgroundColor: "#fff", borderRadius: "50%" }}
                className="position-absolute top-0 p-1 left-50 translate-middle-y"
              >
                <img
                  className=""
                  src={myData?.image}
                  style={{ width: "4rem", height: "4rem", borderRadius: "50%" }}
                  alt="Profile"
                />
              </div>
              <div style={{ marginTop: "3rem" }}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="fs-5 fw-bold">{myData?.name}</div>
                    <div className="fs-6" style={{ color: "#3B3D3C" }}>
                      {myData?.industry}
                    </div>
                  </div>
                  {/* <div id="#label-onrate"></div> */}

                  <div
                    style={{ color: "#787979" }}
                    className="d-flex flex-column align-items-end gap-1"
                  >
                    {/* {myData.rating && ( */}

                    {/* )} */}

                    <div className="d-md-block d-none">{myData?.email}</div>
                    <div>{myData?.userType}</div>
                    <div>{myData?.gender}</div>
                  </div>
                </div>
                <div className="d-flex flex-md-row flex-column justify-content-md-between mt-4 w-100 gap-4">
                  {/* {myData?.bio ? (
                    <div className="d-flex flex-column gap-4">
                      <div className="fs-3">Biography</div>
                       <p>{myData.bio}</p>
                    </div>
                  ) : ( */}
                  <div className="d-flex flex-column gap-4">
                    <div className="fs-3">Biography</div>

                    {myData?.bio ? (
                      <p>{myData.bio}</p>
                    ) : (
                      <p style={{ color: "#787979" }}>
                        {" "}
                        Filling out a biography will make you stand out more{" "}
                      </p>
                    )}

                    <div className="d-flex flex-column  align-items-start gap-4">
                      <div>
                        {myData?.skill && myData?.skill.length > 0 ? (
                          <div className="d-flex gap-2 flex-column align-items-start mb-2 mt-2">
                            <div className="fs-5 fw-bold">Skills</div>
                            <div className="d-flex gap-4">
                              {myData.skill.map((skill) => (
                                <div
                                  key={skill}
                                  style={{ backgroundColor: "#f5f4f4" }}
                                  className="px-3 py-1 rounded-3"
                                >
                                  {skill}
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex gap-2 flex-column align-items-start mb-2 mt-2">
                            <div className="fs-5 fw-bold">Skills</div>
                            <div
                              style={{ color: "#787979" }}
                              className="d-flex gap-4"
                            >
                              Filling out a skill will make you stand out more
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        {myData.resume && (
                          <div>
                            <div className="d-flex gap-2 flex-column  align-items-start mb-2 mt-2">
                              <div className="fs-5 fw-bold">Resume</div>
                              <div className="flex gap-2">
                                {/* <FaFileCsv size={23} /> */}

                                <Link to={myData.resume}>
                                  {myData.name}.pdf
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {myData?.facebook ||
                  myData?.instragram ||
                  myData?.linkedin ||
                  myData?.website ||
                  myData?.x ? (
                    <div
                      style={{ backgroundColor: "#f5f4f4" }}
                      className="rounded-4 w-fit p-5 d-flex flex-column align-items-start gap-3"
                    >
                      <div className="fs-4 fw-bold">Social</div>

                      {myData?.facebook && (
                        <div
                          style={{ color: "#5f5d5d" }}
                          className="fs-5 d-flex flex-column"
                        >
                          FaceBook
                          <span style={{ color: "#9fa0a0" }}>
                            {myData.facebook}
                          </span>
                        </div>
                      )}

                      {myData?.linkedin && (
                        <div
                          style={{ color: "#787979" }}
                          className="fs-5 d-flex flex-column"
                        >
                          LinkedIn
                          <span style={{ color: "#9fa0a0" }}>
                            {myData.linkedin}
                          </span>
                        </div>
                      )}

                      {myData?.instragram && (
                        <div
                          style={{ color: "#787979" }}
                          className="fs-5 d-flex flex-column"
                        >
                          Instagram
                          <span style={{ color: "#9fa0a0" }}>
                            {myData.instragram}
                          </span>
                        </div>
                      )}

                      {myData?.x && (
                        <div
                          style={{ color: "#787979" }}
                          className="fs-5 d-flex flex-column"
                        >
                          X<span style={{ color: "#9fa0a0" }}>{myData.x}</span>
                        </div>
                      )}

                      {myData?.personalwebsite && (
                        <div
                          style={{ color: "#787979" }}
                          className="fs-5 d-flex flex-column"
                        >
                          Personal Website{" "}
                          <span style={{ color: "#9fa0a0" }}>
                            {myData.personalwebsite}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{ backgroundColor: "#f5f4f4" }}
                      className="rounded-4 w-fit p-5 d-flex flex-column align-items-start gap-3"
                    >
                      <div className="fs-4 fw-bold">Social</div>
                      <div
                        style={{ color: "#5f5d5d" }}
                        className="fs-5 d-flex flex-column"
                      >
                        FaceBook
                        <span style={{ color: "#9fa0a0" }}>_</span>
                      </div>
                      <div
                        style={{ color: "#787979" }}
                        className="fs-5 d-flex flex-column"
                      >
                        LinkedIn
                        <span style={{ color: "#9fa0a0" }}>_</span>
                      </div>

                      <div
                        style={{ color: "#787979" }}
                        className="fs-5 d-flex flex-column"
                      >
                        Instagram
                        <span style={{ color: "#9fa0a0" }}>_</span>
                      </div>

                      <div
                        style={{ color: "#787979" }}
                        className="fs-5 d-flex flex-column"
                      >
                        X<span style={{ color: "#9fa0a0" }}>_</span>
                      </div>

                      <div
                        style={{ color: "#787979" }}
                        className="fs-5 d-flex flex-column"
                      >
                        Personal Website <span>_</span>
                      </div>
                    </div>
                  )}
                </div>

                {myRate && (
                  <div className="d-flex flex-md-row flex-column gap-md-2 gap-4 mt-4 mb-3">
                    {myRate.optimizedRate &&
                      myRate.optimizedRate.length > 0 && (
                        <ReviewCarousel reviews={myRate.optimizedRate} />
                      )}
                    {myRate.communication &&
                      myRate.knowledge &&
                      myRate.engagement &&
                      myRate.professionalism &&
                      myRate.fullOverallRate && (
                        <div className="d-flex flex-column gap-2">
                          <UserProfileRate
                            text="communication"
                            rating={myRate.communication}
                          />
                          <UserProfileRate
                            text="knowledge"
                            rating={myRate.knowledge}
                          />
                          <UserProfileRate
                            text="engagement"
                            rating={myRate.engagement}
                          />
                          <UserProfileRate
                            text="professionalism"
                            rating={myRate.professionalism}
                          />
                          <UserProfileRate
                            text="OverallRate"
                            rating={myRate.fullOverallRate}
                          />
                        </div>
                      )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Row>
      </Row>
    </Container>
  );
};

export default CandidateProfile;
