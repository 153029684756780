import { useNavigate } from "react-router-dom";
import sentMail from "../../assets/sentmail.png";
import AuthButton from "../AuthButton";
const Success = () => {
  const navigate = useNavigate();
  return (
    <div
      className="card "
      style={{
        // minWidth: "40%",
        backgroundColor: "white",
        paddingRight: "20px",
        paddingLeft: "20px",
        paddingTop: "40px",
        paddingBottom: "40px",
        boxShadow: "0px 30px 50px 0px rgba(0, 0, 0, 0.04)",
        border: 0,
        borderRadius: "10px",

        height: "auto",
      }}
    >
      <div className="card-body p-0 d-flex justify-content-center flex-column gap-2 align-items-center">
        <img src={sentMail} alt="sent-mail" />
        <h3>Registration Completed</h3>
        <p
          className="fs-sm text-center"
          style={{
            width: "60%",
          }}
        >
          Please click the activation link we sent to your email to activate
          your account.
        </p>
        <div
          className="text-center"
          style={{
            width: "60%",
          }}
        >
          <AuthButton
            label="Return to sign in "
            onClick={() => {
              navigate("/");
            }}
            className="btn w-100 btn-primary mb-3 w-100 rounded-4 btn-lg fs-6 fw-medium"
          />
        </div>
      </div>
    </div>
  );
};
export default Success;
