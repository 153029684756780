export const serverHost = "https://api.careergpt.site";


// export const serverHost = "http://localhost:3001";


export const baseUrl = serverHost + "/api/v1";



// export const FAST_API_PYTHON_SERVER_URI = 'http://localhost:8000'; 
export const FAST_API_PYTHON_SERVER_URI = serverHost; 






