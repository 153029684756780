import axios from "axios";
import { baseUrl } from "../services/api";
import { toast } from "react-toastify";
import { getToken } from "../util/utilityFunction";
const token = sessionStorage.getItem("authToken");

export const updateProfileData = async (data) => {
  console.log(data);
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.put(
      `${baseUrl}/candidates/updateProfileData`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("Response from updateProfileData: ", response.statusText);
    if (response.statusText === "OK") {
      // toast.success("Profile Updated Successfully");
    } else {
      toast.error("error during profile update");
    }
    return response.data;
  } catch (error) {
    // console.error("Error during updateProfileData: ", error);
    toast.error("Error during update Profile Data: ", error);
    return null;
  }
};
export const updateSocialMediaData = async (data) => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.put(
      `${baseUrl}/candidates/updateSocialData`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("Response from updateSocialmediaData: ", response.data);
    if (response.statusText === "OK") {
      toast.success("Social Profile Updated Successfully");
    } else {
      toast.error("error during social profile update");
    }

    return response.data;
  } catch (error) {
    // console.error("Error during updateSocialmediaData: ", error);
    toast.error("Error during update social Profile Data: ", error);
    return null;
  }
};

export const changePassword = async (data) => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.post(`${baseUrl}/auth/changePassword`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Response ", response.data);
    if (
      response.data === "user not found" ||
      response.data === "old password is incorrect"
    ) {
      toast.error(response.data);
    } else if (response.data === "password changed successfully") {
      toast.success(response.data);
    } else {
      toast.error("error during changing password");
    }

    return response.data;
  } catch (error) {
    // console.error("Error during changePassword: ", error);
    toast.error("error during change password");
    return null;
  }
};

// export const deleteUser = async (data) =>{
//   try {
//     const response = await axios.delete(`${baseUrl}/auth/deleteMe`, data);
//     if(response.statusText === "OK"){
//       toast.success("User deleted successfully");
//       return response.data;
//     }
//     toast.error("Error during delete user")
//     return
//   }catch(error){
//     console.error("Error during deleteUser: ", error);
//     toast.error("Error during delete user")
//     return null;
//   }
// }

export const deleteUser = async () => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.delete(`${baseUrl}/auth/deleteMe`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(response.data);
    // console.log(response.statusText);
    // console.log(response.status);
    // console.log("ashan");
    // console.log(data);
    // if (!response.data) {
    //   toast.error("error during delete user");
    //   return;
    // }

    if (response.status == 202) {
      toast.success("User deleted successfully");
      return response.data;
    }
    // if (response.statusText === "OK") {
    //   toast.success("User deleted successfully");
    // } else {
    //   toast.error("error during delete user");
    // }
    // console.log(response.data);
    // console.log(response.statusText);
    // console.log(response.status);
    // console.log(data);
    return response.data;
  } catch (error) {
    console.error("Error during deleteUser: ", error);
    toast.error("Error during delete user");
    return null;
  }
};

export const updateTheme = async (data) => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.put(
      `${baseUrl}/candidates/updateTheme`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("Response from updateTheme: ", response.data);
    if (response.statusText === "OK") {
      toast.success("Theme Updated Successfully");
    } else {
      toast.error("error during theme update");
    }

    return response.data;
  } catch (error) {
    // console.error("Error during updateTheme: ", error);
    toast.error("Error during update theme");
    return null;
  }
};

export const updateResumeData = async (data) => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.put(
      `${baseUrl}/candidates/updateResumeData`,
      {
        resume: data,
      },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("Response from updateResumeData: ", response.data);
    if (response.statusText === "OK") {
      toast.success("Resume removed Successfully");
    } else {
      toast.error("there is an error removing resume");
    }

    return response.data;
  } catch (error) {
    // console.error("Error during updateResumeData: ", error);
    toast.error("Error during update resume");
    return null;
  }
};
