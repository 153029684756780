// socket.js
import { serverHost } from "./services/api.js"
import io from 'socket.io-client';

export default function getSocketInit() {
    if (window.socket) {
        return window.socket;
    }
    const socket = io(serverHost, {
        autoConnect: true,
        auth: {
            "userId": localStorage.getItem("userId"),
            "user": JSON.parse(localStorage.getItem("userObject")),
        }
    });
    window.socket = socket;
    return socket;
}