import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import { useOneUserRating } from "../../hooks/useOneUserRating";
import Spinner from "../../ui/Spinner";

import { useGetMe } from "../../hooks/useGetMe";

function UserCardForMatching({
  name,
  role,
  profilePic,
  country,
  experience,
  userId,
  userType,
  theme,
  rating,
}) {
  const [hovering, setHovering] = useState(false);
  // console.log(country);
  // console.log()
  // const { rating: overallRating, isPending } = useOneUserRating(userId);

  // if (isPending) return <Spinner />;

  const { myData } = useGetMe();

  return (
    <div className=" col-md-6  p-1">
      <div
        style={{
          backgroundColor: "#EEFBF3" ,
        }}
        className=" d-flex d-sm-none rounded-3 p-3 col-12"
        
      >
        <div className="d-flex gap-3  w-100  align-content-center  ">
          <div>
            <Link to={`/${myData.userType}/user/${userId}`}>
              <img
                src={profilePic}
                alt="profile"
                style={{ width: "40px", height: "40px" }}
                className=" rounded-circle "
              />
            </Link>
          </div>

          <Link
            to={`/${myData.userType}/user/${userId}`}
            className={` justify-content-between w-100 text-black text-decoration-none`}
          >
            <div className="  d-flex justify-content-between  align-items-center w-100 ">
              <div className="fw-medium align-items-center d-flex">
                {name} (
                {rating && rating > 0 ? (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src="/star (1).png"
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "5px",
                      }}
                    />
                    {rating}
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src="/star (1).png"
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "5px",
                      }}
                    />
                    {0}
                  </div>
                )}
                )
              </div>

              
            </div>
            <div className=" align-items-center d-flex w-100 justify-content-between  ">
              <div
                className=" align-items-center gap-2 d-flex "
                style={{
                  color: " #3B3D3C",
                  fontSize: "14px",
                }}
              >
                {role}
                <div
                  className=" fw-light  text-end align-items-center d-flex "
                  style={{ color: " #787979    ", fontSize: "12px" }}
                >
                  {experience}
                </div>
              </div>
              
            </div>
          </Link>
          
            <div
              className={` fw-medium text-${theme}   justify-content-end d-flex align-items-center `}
            >
              <Link
                to={`/${myData.userType}/connect-to-professionals/startChat/${userId}`}
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                <div className="d-flex gap-1">
                  <div>Start </div> <div>Chat</div>
                </div>
              </Link>
            </div>
        
        </div>
      </div>
      <div 
        style={{
          backgroundColor: hovering ? "#EEFBF3" : "#F8F8F8",
        }}
        className="d-none d-sm-flex rounded-3 p-3 col-12"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className="d-flex gap-3  w-100  align-content-center  ">
          <div>
            <Link to={`/${myData.userType}/user/${userId}`}>
              <img
                src={profilePic}
                alt="profile"
                style={{ width: "40px", height: "40px" }}
                className=" rounded-circle "
              />
            </Link>
          </div>

          <Link
            to={`/${myData.userType}/user/${userId}`}
            className={` justify-content-between w-100 text-black text-decoration-none`}
          >
            <div className="  d-flex justify-content-between  align-items-center w-100 ">
              <div className="fw-medium align-items-center d-flex">
                {name} (
                {rating && rating > 0 ? (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src="/star (1).png"
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "5px",
                      }}
                    />
                    {rating}
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src="/star (1).png"
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "5px",
                      }}
                    />
                    {0}
                  </div>
                )}
                )
              </div>

              {!hovering && (
                <div
                  className=" text-end  align-items-center  d-flex"
                  style={{ color: " #787979    ", fontSize: "14px" }}
                >
                  {userType}
                </div>
              )}
            </div>
            <div className=" align-items-center d-flex w-100 justify-content-between  ">
              <div
                className=" align-items-center gap-2 d-flex "
                style={{
                  color: " #3B3D3C",
                  fontSize: "14px",
                }}
              >
                {role}
                <div
                  className=" fw-light  text-end align-items-center d-flex "
                  style={{ color: " #787979    ", fontSize: "12px" }}
                >
                  {experience}
                </div>
              </div>
              {!hovering && (
                <div
                  className=" fw-light  text-end align-items-center d-flex "
                  style={{ color: " #787979    ", fontSize: "12px" }}
                >
                  {country}
                </div>
              )}
            </div>
          </Link>
          {hovering && (
            <div
              className={` fw-medium text-${theme}   justify-content-end d-flex align-items-center `}
            >
              <Link
                to={`/${myData.userType}/connect-to-professionals/startChat/${userId}`}
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                <div className="d-flex gap-1">
                  <div>Start </div> <div>Chat</div>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserCardForMatching;
