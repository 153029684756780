import React from "react";

function Spinner() {
  return (
    <div>
      <img
        style={{
          position: "absolute",
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          scale: "0.5",
        }}
        src="/Rolling@1x-1.0s-200px-200px.svg"
      />
    </div>
  );
}

export default Spinner;
