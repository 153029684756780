import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Success({ setShow }) {
  useEffect(() => {
    setShow(false);
  }, []);
  return (
    // <Container>
    //   <Row className=" justify-content-center">
    //     <Col className=" col-5  p-5 d-flex flex-column justify-content-start align-items-end">
    //       <div className="fs-1 gap-4 w-100 text-primary d-flex flex-column justify-content-center align-items-center">
    //         <div>Payment Success !</div>
    //         <Link
    //           to="/candidate/salary"
    //           className="gap-4 d-flex"
    //           style={{
    //             textDecoration: "none",
    //           }}
    //         >
    //           <Button>Home Page</Button>
    //         </Link>
    //       </div>
    //     </Col>
    //   </Row>
    // </Container>
    <div className="">
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // scale: "0.5",
        }}
        className="fs-2 gap-4 w-100 text-primary d-flex flex-column justify-content-center align-items-center"
      >
        <div>Payment Success !</div>
        <Link
          to="/candidate/salary"
          className="gap-4 d-flex"
          style={{
            textDecoration: "none",
          }}
        >
          <Button>Home Page</Button>
        </Link>
      </div>
    </div>
  );
}

export default Success;
