import React, { useCallback, useEffect, useState } from "react";
import AuthButton from "../AuthButton.js";
import { Link } from "../common";


const AccountSection = ({ onClick, init }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");

  useEffect(() => {
    setEmail(init.email);
    setName(init.name);
  }, [init]);

  const validateForm = useCallback(() => {
    let isValid = true;
    // Name validation
    if (name.trim() === "") {
      setNameError("Name cannot be empty");
      isValid = false;
    } else {
      setNameError("");
    }

    // Email validation
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    return isValid;
  }, [name, email]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      onClick(
        {
          name: name,
          email: email,
        },
        1
      );
    }
  };

  const handleEmailChange = (email) => {
      setEmail(email);
      setEmailError("");
  }


  return (
    <div className="py-5 w-100 col-12"
    >
      
      <h3>Create your account</h3>
      {/* <p className="fs-sm">Start your 30-days free trial</p> */}
      <div className="mb-3">
        <div className="d-flex justify-content-between">
        <label
          style={{ marginBottom: "8px" }}
          className="fs-xs fw-medium"
          htmlFor="email"
        >
          Full Name
        </label>
        {nameError && <div className="text-danger fs-xs">{nameError}</div>}
        </div>
        <input
          type="text"
          id="name"
          className={` ${
            nameError ? "is-invalid" : ""
          } shadow-none w-100 p-2 rounded-4 fs-sm`}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your full name"
          onFocus={(e) => e.target.style.border = '1px solid #9D9E9D'}
            onBlur={(e) => e.target.style.border = '1px solid #DADBDA'} 
            style={nameError?{
              outline: 'none',
              border: `1px solid #F04438`
            }:{
              outline: 'none',
              border: `1px solid #DADBDA`
            }}
            
        />
        
      </div>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
        <label
          style={{ marginBottom: "8px" }}
          className="fs-xs fw-medium"
          htmlFor="email"
        >
          Email Address
        </label>
        {emailError && <div style={{color:'#F04438'}} className="fs-xs">{emailError}</div>}
        </div>
        <input
          type="email"
          id="email"
          className={` ${
            emailError ? "is-invalid" : ""
          } shadow-none p-2 w-100 rounded-4 fs-sm`}
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          placeholder="Enter your email address"
          onFocus={(e) => e.target.style.border = '1px solid #9D9E9D'}
            onBlur={(e) => e.target.style.border = '1px solid #DADBDA'} 

            style={emailError?{
              outline: 'none',
              border: `1px solid #F04438`
            }:{
              outline: 'none',
              border: `1px solid #DADBDA`
            }}
        />
        
      </div>

      <div className="text-center">
        <AuthButton
          label="Continue"
          onClick={handleSubmit}
          className="btn btn-info mb-3 w-100 rounded-4 btn-lg fs-6 fw-medium"
          style={{
            
            background: email === "" || name ===""? "#F8F8F8" : "#29B475",
            color: email === "" || name==="" ? "#000000" : "#ffffff",
            border: 'none',
          }}
        />
      </div>

      <p className="text-center mb-0">
        Aready have an account? <Link to="/signin" label={"Sign in"} />
      </p>
      {/* Copyright information */}
    </div>
  );
};

export default AccountSection;
