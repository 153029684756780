import React from "react";
import { createChat, createConversation } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateChat as updateChatApi } from "./../services/candidateApi";
import { createNotification } from "../services/notificationApi";

export const useCreateNotification = () => {
  const queryClient = useQueryClient();

  const { mutate: sendNotification,isPending } = useMutation({
    mutationFn: (data) => createNotification({ data }),
    onSuccess: (data) => {
        queryClient.invalidateQueries({
            queryKey: ["notification"],
          });
    //   toast.success("email sent successfully");
    },
  });

  return { sendNotification, isPending };
};
