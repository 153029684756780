import React from "react";

import FileUpload from "../components/FileUpload";
import FocusBarCoach from "../components/FocusBarCoach";
import Style from "./../css/candidateNavBar.module.css";
import "./../css/RecruiterLayout.css";

import UserNavbar from "../ui/UserNavbar";
// import CandidateNavBar from "../ui/CandidateNavBar";
const CoachLayout = ({ children, isNewChat = false }) => {
  const uploadButton = <FileUpload className="mt-2 d-flex" />;
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const [isTemparySidebarOpen, setIsTemparySidebarOpen] = React.useState(true);

  return (
    <>
      {/* <Header /> */}
      {/* <div className="header-text-container">
        <IoShieldCheckmarkOutline style={{ color: "white" }} />
        <p className="mb-0">CareerGPT was just updated!</p>
      </div> */}
      <div
        className=""
        style={{
          height: "100vh",
          paddingTop: "2vh",
          backgroundColor: "#f5f4f4",
        }}
      >
        <div>
          <div className="d-flex ">
            <div
              style={{
                // border: "1px solid #ff0000",
                backgroundColor: "#f5f4f4",
              }}
              className={` d-md-block d-none ${
                isSidebarOpen && isTemparySidebarOpen
                  ? "col-12 col-md-1 col-lg-3 col-xxl-2"
                  : "col-12 col-md-1 col-lg-1"
              }  `}
            >
              <FocusBarCoach
                isNewChat={isNewChat}
                isSidebarOpen={isSidebarOpen}
                isTemparySidebarOpen={isTemparySidebarOpen}
              />
            </div>

            <div
              style={{
                backgroundColor: "#fff",
                height: "96vh",
                borderRadius: "20px",
              }}
              className={`${
                isSidebarOpen && isTemparySidebarOpen
                  ? "col-12 col-md-11 col-lg-9 col-xxl-10"
                  : "col-12 col-md-11 col-lg-11"
              }  d-flex  flex-column position-relative main-layout`}
            >
              <UserNavbar
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isTemparySidebarOpen={isTemparySidebarOpen}
                setIsTemparySidebarOpen={setIsTemparySidebarOpen}
              />
              <div className="" style={{ height: "90vh", overflow: "scroll" }}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoachLayout;
