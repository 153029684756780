import React, { useEffect, useRef } from "react";

import MessageComponent from "../../pages/connectToProfessionals/MessageComponent";
import { useGetMe } from "../../hooks/useGetMe";
import { useGivenRate } from "../../hooks/useGivenRate";
import { useParams } from "react-router-dom";

import RateComponent from "./Rating";
import { useOneChat } from "../../hooks/useOneChat";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import getSocketInit from "../../socket";
const Conversation = ({
  conversation,
  isRating,
  cvUploading,
  setIsRating,
  recruiterData,
}) => {
  // console.log("Conversation", userChatData);
  const socket = getSocketInit();
  socket.connect();
  const scrollableContainerRef = useRef(null);

  const params = useParams();
  const id = params.id;

  // const { userChatData, isPending: isUserChat } = useOneChat(id);
  const formatDate = ({ date }) => {
    const dateTime = new Date(date);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1; // Month starts from 0, so add 1
    const day = dateTime.getDate();

    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    return formattedDate;
  };

  const formatTime = ({ date }) => {
    const dateTime = new Date(date);
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
    return formattedTime;
  };

  // const handleRating = (rate) => {
  //   console.log(rate);
  //   if (rate > 0) {
  //     const rating = {
  //       userId: recruiterData.recruiterData.id,
  //       rating: rate,
  //     };
  //     uploadRating(
  //       { rating },
  //       {
  //         onSuccess: () => {
  //           setIsRating(false);
  //         },
  //       }
  //     );
  //   }
  // };

  const { uploadRating, isPending: isUploadRating } = useGivenRate(id);

  const { myData, isPending } = useGetMe();

  const scrollToBottom = () => {
    if (scrollableContainerRef.current) {
      const scrollHeight = scrollableContainerRef.current.scrollHeight;
      const height = scrollableContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      scrollableContainerRef.current.scrollTop =
        maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  // useEffect(() => {
  //   console.log("Setting up socket listeners");
  //   socket.on("connect", () => {
  //     console.log("Connected to server");
  //   });

  //   socket.on("typing", (data) => {
  //     console.log("Received typing event:", data);
  //     setTypingMessage(`${data.user} is typing...`);
  //   });

  //   socket.on("stop typing", (data) => {
  //     console.log("Received stop typing event:", data);
  //     setTypingMessage("");
  //   });

  //   return () => {
  //     console.log("Cleaning up socket listeners");
  //     socket.off("typing");
  //     socket.off("stop typing");
  //   };
  // }, [socket]);

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  // if(isPending || isUserChat){
  //   return <CustomizedSpinner />;
  // }

  return (
    <div
      className="overflow-y-scroll container-fluid align-self-end "
      style={{ maxHeight: "60vh" }}
      ref={scrollableContainerRef}
    >
      {conversation
        ? conversation.map((data) => (
            <MessageComponent
              admin={!data.fromId ? true : false}
              key={data.id}
              profilePic={
                myData.id == data.fromId
                  ? myData.image
                  : recruiterData.recruiterData.image
              }
              name={
                myData.id == data.fromId
                  ? myData.name
                  : recruiterData.recruiterData.name
              }
              messageDate={formatDate({ date: data.createdAt })}
              messageTime={formatTime({ date: data.createdAt })}
              messageData={data.text}
            />
          ))
        : "No chat"}

      <RateComponent
        name={recruiterData.recruiterData.name}
        setIsRating={setIsRating}
        width={100}
        isRating={isRating}
      />

      {cvUploading && (
        <div className="d-flex justify-content-center">
          <img
            src="/Spinner@1x-1.0s-200px-200px.svg"
            style={{
              width: "7rem",
              height: "7rem",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Conversation;
