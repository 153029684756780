import React from "react";
import Spinner from "./Spinner";

const CustomizedSpinner = () => {
  return (
    <div className=" position-absolute top-30 start-40 ">
      <Spinner />
    </div>
  );
};

export default CustomizedSpinner;
