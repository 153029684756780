import React, { useState,useEffect } from "react";
import ChatInterface from "../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import Trends from "../components/Trends";
import "../css/salary.css";
import { useQuery } from "@tanstack/react-query";
import { newsData } from "../services/candidateApi";
import CustomizedSpinner from "../ui/CustomizedSpinner";

const token = sessionStorage.getItem("authToken");

const SalaryInsightsCoach = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [salaryInsights, setSalaryInsights] = useState([]);
  const [initialAdviceContext, setInitialAdviceContext] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const [uploadVisible, setUploadVisible] = useState();

  const closeNewsPanel = () => {
    setNewsPanelVisible(false);
    if (!uploadVisible) {
    const newMessages = [
      ...chatMessages,
      {
        bot: "Please select your resume in PDF form!",
      },
    ];
    setChatMessages(newMessages);
    hideNewsPanel();
    setUploadVisible(true);
  }
  };


  const { data: salaryNews, isLoading } = useQuery({
    queryKey: ["news", "salary news"],
    queryFn: newsData,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  if (isLoading) return <CustomizedSpinner />;

  const handleSalaryInsight = async (roles) => {
    const token = sessionStorage.getItem("authToken");
    if (!roles || roles.length < 2) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("cvs", files[i]);
    }

    setChatMessages([
      ...chatMessages,
      { bot: "analyzing your salary insights..." },
    ]);
    setLoading(true);
   
    try {
      const res = await axios.post(
        `${baseUrl}/files/salary-insights/coach/?roles=${roles}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const insights = res.data.salary_insights;
      console.log("Salary insights:", insights[roles]);
      setSalaryInsights(insights);
      setInitialAdviceContext(insights);
      setConversationHistory([
        ...conversationHistory,
        { user: roles, bot: insights[roles] },
      ]);

      const botReply = (
        <div className="job-info">
          {insights && (
            <>
              {Object.entries(insights).map(([title, description]) => (
                <div key={title}>
                  <h4>{title}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description
                        .replace(/\n/g, "<br/>")
                        .replace(/\*{2}(.*?)\*{2}/g, "<strong>$1</strong>"),
                    }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      );

      setChatMessages([...chatMessages, { user: roles }, { bot: botReply }]);
      setLoading(false);
    } catch (err) {
      console.error("Error processing request:", err);
      setChatMessages([
        ...chatMessages,
        {
          bot: "We encountered an error while processing your request. Please check your connection and try again later",
        },
      ]);
      setLoading(false);
    }
  };
  

  const handleFollowUpQuestion = async (question) => {
    if (!initialAdviceContext) {
      setChatMessages([
        ...chatMessages,
        { bot: "Please provide your initial roles for salary insights first." },
      ]);
      return;
    }

    setChatMessages([
      ...chatMessages,

      { bot: "analyzing your follow-up question..." },

    ]);
    setLoading(true);

    // Prepare the request body
    const requestBody = {
      context: initialAdviceContext,
      question: question,
      chat_history: conversationHistory,
    };

    // Log the request body for debugging
    console.log("Sending follow-up request:", requestBody);

    try {
      const res = await axios.post(
        `${baseUrl}/files/salary-insights/candidate/follow-up`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const followUpResponse = res.data.follow_up_response;
      setConversationHistory([
        ...conversationHistory,
        { user: question, bot: followUpResponse },
      ]);
      setChatMessages([
        ...chatMessages,
        { user: question },
        { bot: followUpResponse },
      ]);
      setLoading(false);
    } catch (err) {
      console.error(
        "Error processing follow-up question:",
        err.response?.data || err.message
      );
      setChatMessages([
        ...chatMessages,
        {
          bot: "There was an error processing your follow-up question. Please try again later.",
        },
      ]);
      setLoading(false);
    }
  };

  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };

  return (
    <div
      className="  d-sm-flex justify-content-sm-center  pt-4 pt-xl-3 px-4 w-100  align-content-center   "
      style={{
        // minHeight: "83vh",
        // height: "87vh",
      }}
    >
      {/* overflow-y-scroll  */}
      <div
        className="  px-sm-5 gap-3 col-12  d-grid align-content-between  "
        style={{
          height: isMobile ? "83.8vh" : "85vh",
          overflowY: "auto",
        }}
      >
        <div
          className={`trends-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={isMobile  ? { height: "100%", overflowY: "scroll" } : null}
        >
          <div className="trendsPageTitle d-flex flex-row justify-content-between">
            <h5 className="trend-page-title d-md-block d-none">
              Job Market News (from NewsAPI.org)
            </h5>
            <h5 className="trend-page-title-min d-md-none d-block">
              Job Market News
            </h5>
            <a onClick={closeNewsPanel} className="salary-news-title-close-btn">
              Close
            </a>
          </div>
          <Trends data={salaryNews} />
        </div>

        <div
          className="chat-container    "
          style={{
            flex: "2 1 0",
            marginBottom: "0px",
            height: newsPanelVisible ? "40%" : "100%",
          }}
        >
          <ChatInterface
          hideNewsPanel={hideNewsPanel}
          uploadVisible={uploadVisible}
          setUploadVisible={setUploadVisible}
          newsPanelVisible={isMobile && newsPanelVisible} 
        
            messages={chatMessages}
            onNewMessage={(message) => {
              if (!initialAdviceContext) {
                handleSalaryInsight(message);
              } else {
                handleFollowUpQuestion(message);
              }
            }}
            onFilesUploaded={(uploadedFiles) => {
              const newMessages = [
                ...chatMessages,
                {
                  bot: "Thank you for uploading! Now, please specify the role you're interested in for salary insights (job title will suffice) and wait for the magic to happen!",
                },
              ];
              setChatMessages(newMessages);
              setFiles([...files, ...uploadedFiles]);
              hideNewsPanel();
              setUploadVisible(true);
            }}
            setChatMessages={setChatMessages}
            firstMsg={
              <Typewriter
                words={["Please select your resume in PDF form!"]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SalaryInsightsCoach;
