import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getAllUsers, getAllcandidate, getMe } from "../services/candidateApi";

export const useGetAllUsers = () => {
  const { data: allUsers, isPending } = useQuery({
    queryKey: ["allUsers"],
    queryFn: getAllUsers,
  });
  return { allUsers, isPending };
};
