import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./FocusBarCandidate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SidebarBtn from "../ui/SidebarBtn";
import logo from "./../assets/logo.png";

const renderTooltip = (props, text) => (
  <Tooltip id="button-tooltip" {...props}>
    {text}
  </Tooltip>
);

const FocusBarCandidate = ({
  isNewChat = false,
  isSidebarOpen,
  isTemporySidebarOpen,
}) => {
  const isMobile = window.innerWidth <= 768; // Set your mobile breakpoint here
  const tooltipPlacement = isMobile ? "bottom" : "right";
  const location = useLocation();

  return (
    <div className=" sidebar-FocusBarCandidate mx-xl-2 mx-lg-1 ">
      <div
        className={`h-100 align-items-md-center ${
          isSidebarOpen && isTemporySidebarOpen
            ? " align-items-start align-items-lg-start align-items-md-center "
            : "align-items-center"
        } d-flex flex-column  `}
      >
        <div className="position-relative h-100 w-100">
          <div className="d-flex flex-column gap-2  align-items-center position-absolute top-0">
            <div className="-content-center ">
              <div className="d-block d-lg-block d-md-none">
                {isSidebarOpen && isTemporySidebarOpen ? (
                  <Link to="/candidate/connect-to-professionals">
                    <img
                      className="mb-2 "
                      src={logo}
                      alt="logo"
                      style={{ height: "28px" }}
                    />
                  </Link>
                ) : (
                  <Link to="/candidate/connect-to-professionals">
                    <img
                      className="mb-2"
                      src="/CareerGPT_Brand_Mark_Green.png"
                      alt="logo"
                      style={{ height: "28px" }}
                    />
                  </Link>
                )}
              </div>

              <div className="d-lg-none d-md-block d-none">
                <Link to="/candidate/career-coach">
                  <img
                    className="mb-2"
                    src="/CareerGPT_Brand_Mark_Green.png"
                    alt="logo"
                    style={{ height: "28px" }}
                  />
                </Link>
              </div>
            </div>
            <SidebarBtn
              renderTooltipTest="Try Out Our AI-Powered Career Coach."
              // renderTooltipTest="Coming Soon"
              to="/candidate/career-coach"
              // to="#"
              text="AI-Powered Career Coach"
              iconeFolder="candidateSidebar"
              iconeName="goal.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            />
            <SidebarBtn
              renderTooltipTest="Directly connect with hundreds of recruiters and coaches on the CareerGPT platform."
              // renderTooltipTest="Coming Soon"
              to="/candidate/connect-to-professionals"
              // to="#"
              text="Connect to Professionals"
              iconeFolder="candidateSidebar"
              iconeName="connetToProf.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            />
             <SidebarBtn
              renderTooltipTest="Tell us your desired job title, location, and upload your resume. We'll analyze job market data and your skills to provide a personalized salary estimate."
              to="/candidate/salary"
              text="Salary Sensei"
              iconeFolder="candidateSidebar"
              iconeName="invoice-accounting-documet-chart.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            />
            {/* <SidebarBtn
              renderTooltipTest="Match with a recruiter/coach who perfectly aligns with your background using our unique vectorization algorithm."
              to="/candidate/networking"
              text="Recruiter Matching"
              iconeFolder="candidateSidebar"
              iconeName="searchRec.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            /> */}
            <SidebarBtn
              renderTooltipTest="Strengthen your resume though AI resume analysis and keyword gap detection."
              to="/candidate/resumereview"
              text="Resume Review"
              iconeFolder="candidateSidebar"
              iconeName="resum-review.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            />
            <SidebarBtn
              renderTooltipTest="Find a job opportunity that matches your experience and requirements."
              to="/candidate/jobsearch"
              text="AI Job Search "
              iconeFolder="candidateSidebar"
              iconeName="jobSearch.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            />

            {/* <SidebarBtn
              renderTooltipTest="Upload your resume and let our AI coach analyze your skills and experience to help you anticipate what the interviewer might ask."
              to="/candidate/interview"
              iconeFolder="candidateSidebar"
              text="Interview Preparation"
              iconeName="interviewPre.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            /> */}
          </div>
          <div className="position-absolute bottom-0 ">
            <SidebarBtn
              to="/candidate/setting/profile"
              text="Settings"
              iconeFolder="candidateSidebar"
              iconeName="settings.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            />
            <SidebarBtn
              to="/candidate/help-and-support"
              text="Help & Support"
              iconeFolder="candidateSidebar"
              iconeName="help.png"
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemporySidebarOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FocusBarCandidate;
