import React from "react";
// import { createChat, createConversation } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUserRate } from "../services/candidateApi";

// import { updateChat as updateChatApi } from "./../services/candidateApi";

export const useUpdateRating = () => {

  const { mutate: updateRate, isPending } = useMutation({
    mutationFn: (data) => updateUserRate(data),
    // onSuccess: () => {
      // queryClient.invalidateQueries({
      //   queryKey: ["userChat", id],
      // });
    // },
  });

  return { updateRate, isPending };
};
