import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { interviewNewsData } from "../../services/candidateApi";
import Trends from "../../components/Trends";
import Spinner from "../../ui/Spinner";
import ChatInterface from "../../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../../services/api";
import { Typewriter } from "react-simple-typewriter";
import CustomizedSpinner from "../../ui/CustomizedSpinner";

const EducationCertification = () => {
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const [chatMessages, setChatMessages] = useState([]);
  const [awaitingNextAction, setAwaitingNextAction] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("authToken");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [uploadVisible, setUploadVisible] = useState();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleYesNoResponse = (userResponse) => {
    if (userResponse.toLowerCase() === "yes") {
      setShowFileUpload(false);
      setChatMessages([
        ...chatMessages,
        { bot: "Please upload another resume." },
      ]);
      setFiles([]);
      setAwaitingNextAction(false);
      setTimeout(() => setShowFileUpload(true), 100);
    } else if (userResponse.toLowerCase() === "no") {
      setChatMessages([
        ...chatMessages,
        {
          bot: "Thank you for using our find education certification  feature!",
        },
      ]);
      setAwaitingNextAction(false);
    } else {
      setChatMessages([
        ...chatMessages,
        { bot: "Please respond with 'yes' or 'no'." },
      ]);
    }
  };

  const { data: interviewNews, isPending: gettingInterviewNews } = useQuery({
    queryKey: ["news", "salary news"],
    queryFn: interviewNewsData,
  });
  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };
  if (gettingInterviewNews) return <CustomizedSpinner />;
  const handleSalaryInsight = async (roles) => {
    const token = sessionStorage.getItem("authToken");
    if (awaitingNextAction) {
      handleYesNoResponse(roles);
      return;
    }

    if (!roles || roles.length < 2) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("cvs", files[i]);
    }

    setChatMessages([
      ...chatMessages,
      { bot: "Extracting education and certifications..." },
    ]);
    setLoading(true);

    try {
      //set the api here
      const res = await axios.post(
        `${baseUrl}/files/interview-prep/coach?roles=${roles} `,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res)

      const interviewTips = res.data.guidance;

      const botReply = (
        <div className="job-info">
          {interviewTips && (
            <>
              {Object.entries(interviewTips).map(([title, description]) => (
                <div key={title}>
                  <h4>{title}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description
                        .replace(/\n/g, "<br/>")
                        .replace(/\*{2}(.*?)\*{2}/g, "<strong>$1</strong>"),
                    }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      );

      setChatMessages([
        ...chatMessages,
        { user: roles },
        { bot: botReply },
        {
          bot: "Would you like to get interview Tips for another role or position? (yes or no)",
        },
      ]);
      setAwaitingNextAction(true);
      setFiles([]);
      setLoading(false);
    } catch (err) {
      console.error("Error processing request:", err);
      setChatMessages([
        ...chatMessages,
        {
          bot: "We encountered an error while processing your request. Please check your connection and try again later",
        },
      ]);
      setLoading(false);
    }
  };
  return (
    <div
      className="  d-sm-flex justify-content-sm-center  pt-4 pt-xl-3 px-4 w-100  align-content-center   "
      style={
        {
          // minHeight: "83vh",
          // height: "87vh",
        }
      }
    >
      {/* overflow-y-scroll  */}
      <div
        className="  px-sm-5 gap-3 col-12    "
        style={{
          height: isMobile ? "83.8vh" : "85vh", //here
          overflowY: "auto", //here
        }}
      >
        <div
          className={`trends-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={isMobile ? { height: "60%", overflowY: "scroll" } : null}
        >
          <div className="trendsPageTitle d-flex flex-row justify-content-between">
            <h5 className="trend-page-title d-md-block d-none">
              Job Market News (from NewsAPI.org)
            </h5>
            <h5 className="trend-page-title-min d-md-none d-block">
              Job Market News
            </h5>
            <a onClick={hideNewsPanel} className="salary-news-title-close-btn">
              Close
            </a>
          </div>
          <Trends data={interviewNews} />
        </div>

        <div
          className="/chat-container  d-grid  "
          style={{
            flex: "2 1 0",
            marginBottom: "0px",
            height: newsPanelVisible ? "40%" : "100%",
          }}
        >
          {/* {files.length >= 1 ? <>test</> : <></>} */}

          <ChatInterface
            setChatMessages={setChatMessages}
            newsPanelVisible={isMobile && newsPanelVisible}
            messages={chatMessages}
            onNewMessage={handleSalaryInsight}
            onFilesUploaded={(uploadedFiles) => {
              const newMessages = [
                ...chatMessages,
                {
                  bot: "Thank you for uploading! Now, please specify the role you're interested in for get education and certifications (job title will suffice) and wait for the magic to happen!",
                },
              ];
              setChatMessages(newMessages);
              setFiles([...files, ...uploadedFiles]);
              hideNewsPanel();
            }}
            firstMsg={
              <Typewriter
                words={["Please select your resume in PDF form!"]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          />

          {/* {console.log(files)} */}
        </div>
      </div>
    </div>
  );
};

export default EducationCertification;
