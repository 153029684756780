import React from "react";
import { useNavigate } from "react-router-dom";
import LinkedInAuth from "./LinkedInAuth"; // Import LinkedInAuth component
import logo from "./../assets/logo.png";
import linkedInLogo from "./../assets/linkedin.png"; // Import LinkedIn logo
import "./../css/EmailOrLinkedIn.css"; // Import custom CSS
import { Link } from "react-router-dom";

const EmailOrLinkedIn = () => {
  const navigate = useNavigate();

  const handleEmailClick = () => {
    navigate("/continue-with-email"); // Navigate to email sign-up form
  };

  return (
    <div className="email-or-linkedin">
      <div className="logo-container" onClick={() => navigate("/")}>
        <img src={logo} alt="CareerGPT Logo" className="logo" />
      </div>
      <div className="auth-container">
        <LinkedInAuth logo={linkedInLogo} />
        <div className="separator">OR</div>
        <button
          className="btn btn-email"
          onClick={handleEmailClick}
          style={{ backgroundColor: "#5fcf80", color: "white" }}
        >
          Continue with Email
        </button>
        <div className="login-link">
          Already have an account?{" "}
          <Link to="/" style={{ color: "#5fcf80" }}>
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmailOrLinkedIn;
