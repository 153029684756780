import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../services/api.js";
import logo from "./../assets/logo.png";
import "./../css/SignUp.css";

import AccountSection from "../components/SignupContainer/Account.js";
import PasswordSection from "../components/SignupContainer/Password.js";
import PersonalDetailsSection from "../components/SignupContainer/PersonalDetails.js";
import CustomCarousel from "../components/SignupContainer/Carousel.js";
import Success from "../components/SignupContainer/Success.js";

import { useMutation } from "@tanstack/react-query";
import { createNotification } from "../services/notificationApi.js";
import { toast } from "react-toastify";
import { useCreateNotification } from "../hooks/useCreateNotification.js";
const SignUp = ({ setMainUserType }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const [userInfo, setUserInfo] = useState({
    name: "",
    gender: "",
    email: "",
    userType: "",
    password: "",
    industry: "",
    country: "",
    // image:
    //   "https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sentMail, setSentMail] = useState(false);

  const { sendNotification, isPending } = useCreateNotification();

  const handleClick = useCallback(
    (value, nextPage) => {
      setUserInfo((prev) => ({
        ...prev,
        ...value,
      }));
      setCurrentPage(nextPage);
    },
    [setUserInfo, setCurrentPage]
  );

  const handleSignup = (data) => {
    setUserInfo((prev) => ({ ...prev, ...data }));
    setLoading(true);

    const image =
      data.gender == "Male"
        ? "https://robohash.org/set_set5/JohnDoe.png"
        : "https://eventnow.s3.amazonaws.com/avatar%5B1%5D.png";

    try {
      axios
        .post(`${baseUrl}/auth/register`, { ...userInfo, ...data, image })
        .then((response) => {
          if (response.data === "user Already excist with this email") {
            toast.error("User Already exists with this email");
            setLoading(false);
            return;
          }

          const { token, user } = response.data;

          // Store token in session storage
          sessionStorage.setItem("authToken", token);

          // Store user data in local storage
          localStorage.setItem("userType", user.userType);
          localStorage.setItem("userId", user.id);
          localStorage.setItem("userName", user.name);
          localStorage.setItem("userObject", JSON.stringify(response.data));

          setMainUserType(user.userType);
          setSentMail(true);

          const data = {
            userId: user.id,
            description: "Your account has been created successfully!",
            topic: "Welcome to CareerGPT",
          };
          sendNotification(data);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <div
      className=" overflow-y-auto overflow-x-hidden vh-100 "
      style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}
    >
      <div className="row gx-3 py-3  h-100">
        <div className="col-lg-12   h-100 ">
          <div
            className=" d-flex  flex-column   "
            style={{
              height: "100%",
            }}
          >
            <div className="logo mt-4  mx-auto mb-4" onClick={onClick}>
              <img src={logo} alt="logo" style={{ height: "28px" }} />
            </div>

            <div className=" d-flex  flex-grow-1 flex-column justify-content-center align-items-center">
              {sentMail ? (
                <Success />
              ) : (
                <>
                  <div
                    className="rounded-3 col-12 col-sm-auto  shadow-lg "
                    style={{
                      backgroundColor: "white",

                      height: "auto",
                    }}
                  >
                    <div className="w-100  px-5">
                      {currentPage === 0 && (
                        <AccountSection
                          onClick={handleClick}
                          setActivecurrentPage
                          init={{
                            email: userInfo.email,
                            name: userInfo.name,
                          }}
                        />
                      )}
                      {currentPage === 1 && (
                        <PasswordSection
                          setCurrentPage={setCurrentPage}
                          passwordInit={userInfo.password}
                          onClick={handleClick}
                        />
                      )}
                      {currentPage === 2 && (
                        <PersonalDetailsSection
                          setCurrentPage={setCurrentPage}
                          onClick={handleSignup}
                          loading={loading}
                          init={{
                            country: userInfo.country,
                            industry: userInfo.industry,
                            userType: userInfo.userType,
                            gender: userInfo.gender,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <CustomCarousel
                    length={3}
                    active={currentPage}
                    setActive={setCurrentPage}
                  />
                </>
              )}
            </div>
            <div
              className="mx-auto"
              style={{
                fontSize: "10px",
                color: "#333",
                marginTop: "15px",
              }}
            >
              <p>© {new Date().getFullYear()} CareerGPT. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;