import React, { useState, useEffect } from "react";
import ChatInterface from "../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import Trends from "../components/Trends";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "../css/ResumeReview.css";
import { useQuery } from "@tanstack/react-query";
import { newsDataResume } from "../services/candidateApi";
import Spinner from "../ui/Spinner";
import CustomizedSpinner from "../ui/CustomizedSpinner";
import SubscriptionOver from "../components/SubscriptionOver";
import Lock from "../ui/Lock";

const ResumeReviewCandidate = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTrialOver, setIsTrialOver] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [awaitingNextAction, setAwaitingNextAction] = useState(false);
  // const [jobNews, setJobNews] = useState([]);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const token = sessionStorage.getItem("authToken");
  const [uploadVisible, setUploadVisible] = useState();



  const { data: jobNews, isLoading } = useQuery({
    queryKey: ["news", "resume"],
    queryFn: newsDataResume,
  });

  if (isLoading) return <CustomizedSpinner />;

  // useEffect(() => {
  //   const fetchJobNews = async () => {
  //     try {
  //       const response = await axios.get(`${baseUrl}/news`, {
  //         params: {
  //           filter: "salary news",
  //         },
  //         withCredentials: true,
  //       });
  //       if (response.data && Array.isArray(response.data)) {
  //         // console.log("Job news fetched:", response.data);
  //         setJobNews(response.data);
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch job news.", error);
  //     }
  //   };

  //   fetchJobNews();

  //   const query = new URLSearchParams(window.location.search);

  //   if (query.get("success")) {
  //     alert(
  //       "Order placed! You can now continue using the system. Please refresh the page if new content is not showing."
  //     );
  //   }

  //   if (query.get("canceled")) {
  //     alert("Order canceled -- please consider paying when you're ready.");
  //   }
  // }, []);

  const handleConversation = async (jobDescription) => {
    const token = sessionStorage.getItem("authToken");
    if (awaitingNextAction) {
      handleYesNoResponse(jobDescription);
      return;
    }

    if (!jobDescription || jobDescription.length < 2) {
      return;
    }

    jobDescription = jobDescription.replace(/•|–|—|\*/g, "");

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("cvs", files[i]);
    }

    setChatMessages([...chatMessages, { bot: "Processing your request..." }]);
    setLoading(true);

    try {
      const res = await axios.post(
        `${baseUrl}/files/resume/candidate/?description=${jobDescription}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(res);

      const {
        matching_keywords,
        matched_via_similar_terms,
        missing_keywords,
        similar_terms,
        percentage_match,
        suggestions,
      } = res.data[0];

      const chatbotResponse =
        res.data && res.data[1] && res.data[1].chatbot_response
          ? res.data[1].chatbot_response[0]
          : undefined;

      let similarTermsArray = [];
      if (
        matched_via_similar_terms &&
        typeof matched_via_similar_terms === "object"
      ) {
        similarTermsArray = Object.entries(matched_via_similar_terms).map(
          ([key, value]) => `${key}: ${value.join(", ")}`
        );
      }

      let similarTermsDisplay = "Not provided";
      if (similar_terms && typeof similar_terms === "object") {
        const flattenedSimilarTerms = Object.values(similar_terms).flat();
        similarTermsDisplay = flattenedSimilarTerms.join(", ");
      }

      const matchingKeywordsDisplay =
        matching_keywords && matching_keywords.length
          ? matching_keywords.join(", ")
          : "None";
      const missingKeywordsDisplay =
        missing_keywords && missing_keywords.length
          ? missing_keywords.join(", ")
          : "None";

      const botReply = (
        <div>
          <p>
            Matching keywords: <strong>{matchingKeywordsDisplay}</strong>
          </p>
          <p>
            Missing keywords: <strong>{missingKeywordsDisplay}</strong>
          </p>
          <p>
            <strong>Resume Suggestions:</strong>
            {/* {JSON.stringify(suggestions)} */}
          </p>
          {/* <p>{chatbotResponse}</p> */}

          <p
            dangerouslySetInnerHTML={{
              __html: chatbotResponse
                .replace(/\n/g, "<br/>")
                .replace(/\*{2}(.*?)\*{2}/g, "<strong>$1</strong>"),
            }}
          />

          {/* {console.log(chatbotResponse.replace(/\n/g, "\n"))} */}
        </div>
      );

      // console.log(botReply.replace("**", ""))

      setChatMessages([
        ...chatMessages,
        { user: jobDescription },
        { bot: botReply },
        {
          bot: "Would you like to analyze another resume against a different job description? (yes or no)",
        },
      ]);
      setAwaitingNextAction(true);
      setFiles([]);
      setLoading(false);
    } catch (err) {
      console.error("Error processing request:", err);
      setChatMessages([
        ...chatMessages,
        {
          bot: "We encountered an error while processing your request. Please check your connection and try again later",
        },
      ]);
      setLoading(false);
    }
  };

  const handleYesNoResponse = (userResponse) => {
    if (userResponse.toLowerCase() === "yes") {
      setShowFileUpload(false);
      setChatMessages([
        ...chatMessages,
        { bot: "Please upload another resume." },
      ]);
      setFiles([]);
      setAwaitingNextAction(false);
      setTimeout(() => setShowFileUpload(true), 100);
    } else if (userResponse.toLowerCase() === "no") {
      setChatMessages([
        ...chatMessages,
        { bot: "Thank you for your time, hope you found this service useful!" },
      ]);
      setAwaitingNextAction(false);
    } else {
      setChatMessages([
        ...chatMessages,
        { bot: "Please respond with 'yes' or 'no'." },
      ]);
    }
  };

  const handlePayClick = async () => {
    const payBtn = document.querySelector(".payment-btn");
    payBtn.textContent = "Processing";
    payBtn.setAttribute("disabled", "disabled");
    try {
      const res = await axios.post(
        `${baseUrl}/stripe/create-checkout-session`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        window.location.href = res.data.url;
      } else if (res.status === 201) {
        alert("You already paid. Please wait for page to refresh");
        window.location.reload();
      } else {
        alert("Some error occurred. Please try again");
      }
    } catch (err) {
      console.log(err);
      alert("Server error occurred. Please try again");
      payBtn.textContent = "Pay $1.99";
      payBtn.removeAttribute("disabled");
    }
  };

  const closeNewsPanel = () => {
    setNewsPanelVisible(false);
    if (!uploadVisible) {
    const newMessages = [
      ...chatMessages,
      {
        bot: "Please select your resume in PDF form!",
      },
    ];
    setChatMessages(newMessages);
    hideNewsPanel();
    setUploadVisible(true);
  }
  };

  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };

  // return (
  //   <div className="w-50">
  //     <SubscriptionOver
  //       show={isTrialOver}
  //       onClose={() => setIsTrialOver(false)}
  //     />
  //   </div>
  // );

  return (
    <div
      className="  d-sm-flex justify-content-sm-center  pt-4 pt-xl-3 px-4 w-100  align-content-center   "
      style={
        {
          // minHeight: "83vh",
          // height: "87vh",
        }
      }
    >
      {/* overflow-y-scroll  */}
      <div
        className="  px-sm-5 gap-3 col-12 d-grid align-content-between    "
        style={{
          height: isMobile ? "83.8vh" : "85vh", //here
          overflowY: "auto", //here
        }}
      >
        <div
          className={`trends-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={isMobile  ? { height: "100%", overflowY: "scroll" } : null}
        >
          <div className="trendsPageTitle d-flex flex-row justify-content-between">
            <h5 className="trend-page-title d-md-block d-none">
              Resume reviews News (from NewsAPI.org)
            </h5>
            <h5 className="trend-page-title-min d-md-none d-block">
              Resume reviews News
            </h5>
            <a onClick={closeNewsPanel} className="resume-news-title-close-btn">
              Close
            </a>
          </div>
          <Trends data={jobNews} />
        </div>

        {/* <div
        className="trends-container"
        style={{ flex: "1 1 0", marginLeft: "10px" }}
      >
        <h5>Job Market News (from NewsAPI.org)</h5>
        <Trends data={jobNews} />
      </div> */}

        <div
          className="/chat-container d-grid "
          style={{
            flex: "2 1 0",
            marginBottom: "0px",
            height: newsPanelVisible ? "40%" : "100%",
          }}
        >
          <ChatInterface
            uploadVisible={uploadVisible}
            setUploadVisible={setUploadVisible}
            setChatMessages={setChatMessages}
            newsPanelVisible={isMobile && newsPanelVisible}
            messages={chatMessages}
            onNewMessage={handleConversation}
            onFilesUploaded={(uploadedFiles) => {
              const newMessages = [
                ...chatMessages,
                {
                  bot: "Thank you for uploading! Now, please provide the job description and wait for the magic to happen!",
                },
              ];
              setChatMessages(newMessages);
              setFiles([...files, ...uploadedFiles]);
              hideNewsPanel();
              setUploadVisible(true);
            }}
            firstMsg={
              <Typewriter
                words={["Please select your resume in PDF form!"]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ResumeReviewCandidate;
