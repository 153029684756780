// Progress.js

import React from "react";
import ChatInterface from "../components/ChatInterface";
import Trends from "../components/Trends";
import { Typewriter } from "react-simple-typewriter";

const Progress = () => {
  const progressData = [
    // Array of progress data objects
    // Replace this with actual data
    {
      title: "Progress 1",
      description: "Description of Progress 1",
      source: "Source of Progress 1",
    },
    {
      title: "Progress 2",
      description: "Description of Progress 2",
      source: "Source of Progress 2",
    },
    // Add more progress data objects as needed
  ];

  return (
    <div
      className="home-container"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        className="chat-container"
        style={{ flex: "2 1 0", marginRight: "10px" }}
      >
        <ChatInterface
          firstMsg={
            <Typewriter
              words={["I'll help you set up some goals!"]}
              cursor
              cursorStyle="_"
              deleteSpeed={-1}
              typeSpeed={30}
            />
          }
        />
      </div>
      <div
        className="trends-container"
        style={{ flex: "1 1 0", marginLeft: "10px" }}
      >
        <h5>Your Progress Insights</h5>
        <Trends data={progressData} />
      </div>
    </div>
  );
};

export default Progress;
