import React, { useEffect, useState } from "react";
import FileUpload from "../../components/FileUpload";
import UploadIconLarge from "../../assets/icons/UploadIcon/arrowUpIcon.png";
import UploadIcon from "../../assets/icons/Upload-Icon-small.png";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import { useQuery,useMutation,useQueryClient } from "@tanstack/react-query";
import { getMe } from "../../services/candidateApi";
import { updateResumeData } from "../../services/apiSetting";
import { baseUrl } from "../../services/api";

import { toast } from "react-toastify";

function Resume() {
  const token = sessionStorage.getItem("authToken");
  // console.log(files);

  // const [files, setFiles] = useState(myData.resume ? [myData.resume] : []);
  const [files, setFiles] = useState([]);
  

  // async function handleCloseBtn() {
    
  // }
  const queryClient = useQueryClient();

  const { mutate: handleCloseBtn, isPending: removingResume } = useMutation({
    mutationFn: () => updateResumeData(null),
    onSuccess: (data) => {
      setFiles([]);
      queryClient.invalidateQueries({
        queryKey: ["getMe"],
      });
    },
    onError: (error) => {
      toast.error("Error during removing resume : ", error);
    },
  });

  const { data: myData, isPending: isMyData } = useQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
  });

  if (isMyData) {
    return <CustomizedSpinner />;
  }

  // useEffect(() => {
  //   if (myData.resume) {
  //     console.log(myData.resume);
  //     setFiles((data) => [...data, myData.resume]);
  //   }
  // }, []);

  const onFilesUploaded = true;

  return (
    <div className="chat-input">
      <div className="chat-input-in ">
        {files.length > 0 || myData.resume ? (
          <div
            style={{
              backgroundColor: "#e9e8eb",
            }}
            className="rounded-3 pt-3 justify-content-start px-4  d-flex gap-3 align-items-start"
          >
            <p className="resume-name fs-6">{`${myData.name}.pdf`}</p>
            <button
              onClick={handleCloseBtn}
              style={{
                backgroundColor: "#e9e8eb",
              }}
              className=" border-0  text-danger rounded-3 fs-6"
            >
              {removingResume ? (
                <img
                  src="/Rolling@1x-1.1s-200px-200px.svg"
                  alt="loading.."
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                ></img>
              ) : (
                "❌"
              )}
            </button>
          </div>
        ) : (
          <label htmlFor="formFile" className="first-msg-container">
            <img
              src={UploadIconLarge}
              alt="Upload Icon"
              className="upload-icon"
            />
            <p className="first-msg">Please select your resume in PDF form!</p>
          </label>
        )}

        <FileUpload
        uploadType="resumeTos3"
          id={myData.id}
          key={files.length}
          onUploadComplete={(uploadedFiles) => {
            // console.log(uploadedFiles);
            if (onFilesUploaded) {
              setFiles(uploadedFiles);
            }
            setFiles([...files, ...uploadedFiles]);
            // console.log([...files, ...uploadedFiles]);
            toast.success("Resume uploaded successfully");
          }}
        />
      </div>
    </div>
  );
}

export default Resume;
