import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Rating from "react-rating";

const UserProfileRate = ({ text, rating }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Your rating is {rating}
    </Tooltip>
  );
  return (
    <div>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <div className="d-flex gap-4 justify-content-between align-items-center">
          <div>{text}</div>
          <Rating
            emptySymbol={
              <img src="/star.png" style={{ width: "20px", height: "20px" }} />
            }
            fullSymbol={
              <img
                src="/star (1).png"
                style={{ width: "20px", height: "20px" }}
              />
            }
            // placeholderRating={2}
            initialRating={rating}
            readonly={true}
            // onChange={(rate) => {
            //   console.log(rate);
            //   setRate(rate);
            // }}
            fractions={2}
            className="d-flex gap-3"
          />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default UserProfileRate;
