import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getMe } from "../services/candidateApi";
import { getNotification } from "../services/notificationApi";

export const useGetNotification = () => {
    const { data: notification, isPending } = useQuery({
        queryKey: ["notification"],
        queryFn: getNotification,
      });

  return { notification, isPending };
};
