import React from "react";
import { createChat } from "../services/candidateApi";
import { useMutation } from "@tanstack/react-query";

export const useCreateChat = () => {
  const { mutate: uploadData, isPending } = useMutation({
    mutationFn: ({ chatData }) => createChat({ chatData }),
  });

  return { uploadData, isPending };
};
