import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
// import UserCardSmall from "./UserCardSmall";
// import ActiveUserCard from "./ActiveUserCard";
import { useQuery } from "@tanstack/react-query";
import { getAllRecruiter } from "../../services/candidateApi";
import Spinner from "../../ui/Spinner";
import ActiveUserCard from "../../pages/connectToProfessionals/ActiveUserCard";
import UserCardSmall from "../../pages/connectToProfessionals/UserCardSmall";
import OneChat from "./OneChat";
import { getUserChat } from "../../services/userApi";
import getSocketInit from "../../socket";
import { useUserChat } from "../../hooks/useUseChat";

function UserChat() {
  const socket = getSocketInit();
  socket.connect();

  const { userChat, isPending } = useUserChat();

  useEffect(() => {
    const handleCreateChat = (toId, data) => {
      console.log("Chat created for user:", toId, data);
      // setMessages(prevMessages => [...prevMessages, data]);
    };

    socket.on("create-chat", handleCreateChat);

    return () => {
      socket.off("create-chat", handleCreateChat);
      socket.disconnect();
    };
  }, [socket]);

  if (isPending) {
    return (
      <div className="d-flex justify-content-center">
        <img
          src="/Spinner@1x-1.0s-200px-200px.svg"
          style={{
            width: "7rem",
            height: "7rem",
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
      }}
      className=" overflow-hidden d-sm-flex justify-content-sm-center  mt-md-3 mt-sm-5   w-100   "
    >
      <div className="mx-3 d-flex  w-100 flex-column justify-content-start">
        <div className=" fw-medium fs-5"> All Message</div>
        <div
          style={{
            maxHeight: "80%",
          }}
          className="  flex-column overflow-y-auto  d-sm-flex "
        >
          {/* {userChat.length > 0 ? ( */}
          {userChat?.map((chat) => (
            <OneChat
              key={chat.id}
              name={chat.userName}
              profilePic={chat.userImg}
              userId={chat.userId}
              lstMsg={chat.lstMsg}
              lastMsgUserName={chat.lastMsgUserName}
              lastMsgCreateAt={chat.lastMsgCreateAt}
              userType={chat.userType}
            />
          ))}
          {/* ) : (
            <div>No chat</div>
          )} */}
        </div>
      </div>

      {/* <div className=" col-md-11 h-100  col-sm-10  col-xl-10 col-xxl-9  gap-3  d-grid ">
        <div className="  row fw-medium h-25 fs-5 bg-danger">All Message</div>

        <div
          style={{
            height: "80%",
          }}
          className="   bg-primary h-75 flex-column overflow-y-auto  d-sm-flex d-none"
        >
        
          {userChat?.map((chat) => (
            <OneChat
              name={chat.userName}
              profilePic={chat.userImg}
              userId={chat.id}
              lstMsg={chat.lstMsg}
              lastMsgUserName={chat.lastMsgUserName}
            />
          ))}
          
        </div>
        <div className=" p-2 d-flex d-sm-none overflow-x-scroll  ">
          <UserCardSmall
            name="Arlene McCoy"
            role="Nursing Assistant"
            location="Fort Smith"
            experience="5Y exp."
            profilePic="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
            userId="2001"
          />

          <UserCardSmall
            name="Arlene McCoy"
            role="Nursing Assistant"
            location="Fort Smith"
            experience="5Y exp."
            profilePic="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
            userId="2001"
          />
          <UserCardSmall
            name="Arlene McCoy"
            role="Nursing Assistant"
            location="Fort Smith"
            experience="5Y exp."
            profilePic="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
            userId="2001"
          />
          <UserCardSmall
            name="Arlene McCoy"
            role="Nursing Assistant"
            location="Fort Smith"
            experience="5Y exp."
            profilePic="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
            userId="2001"
          />
        </div>
      </div> */}
    </div>
  );
}

export default UserChat;
