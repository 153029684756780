import React from "react";
import "./Trends.css";

const Trends = ({ data, title }) => {
  const trendsData = data
    ? data
    : [
        // Fallback array in case data isn't provided
        {
          title: "Trend 1",
          description: "Description of Trend 1",
          url: "Source of Trend 1",
          source: { name: "Fallback Source 1" }, // Example source name
        },
        {
          title: "Trend 2",
          description: "Description of Trend 2",
          url: "Source of Trend 2",
          source: { name: "Fallback Source 2" }, // Example source name
        },
      ];

  return (
    <div className="p-0 justify-content-between w-100 trends-style ">
      {/* <h2 className="trends-title">{title}</h2> */}
      <div className="container  w-100 ">
        <div className="row ">
          {trendsData.map((trend, index) => (
            <>
              {index == 0 ? (
                <a href={trend.url} target="_blank" rel="noopener noreferrer">
                  <div className="trend p-3 mb-3 col-12" key={index}>
                    <h5 className="trend-title">{trend.title}</h5>
                    <p className="trend-description">{trend.description}</p>
                    {/* <p className="trend-source">
                                        Source: {trend.source.name}
                                      </p> */}
                  </div>
                </a>
              ) : index == 1 ? (
                <>
                  <a
                    href={trend.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-3 col-md-6 col-12 p-0 left-news-card"
                    key={index}
                  >
                    <div className="trend p-3 me-2">
                      <a
                        href={trend.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h5 className="trend-title">{trend.title}</h5>
                      </a>
                      <p className="trend-description">{trend.description}</p>
                    </div>
                  </a>
                </>
              ) : (
                <a
                  href={trend.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-3 col-md-6 col-12 p-0 right-news-card"
                  key={index}
                >
                  <div className="trend p-3 ms-1">
                    <a
                      href={trend.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h5 className="trend-title">{trend.title}</h5>
                    </a>
                    <p className="trend-description">{trend.description}</p>
                  </div>
                </a>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

Trends.defaultProps = {
  data: [],
};

export default Trends;
