import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getNotification } from "../services/notificationApi";
import NotificationBtn from "./NotificationBtn";
import Spinner from "./Spinner";
import { useOutsideClick } from "../hooks/useOutSideClick";
import Style from "./../css/candidateNavBar.module.css";

const MobileNotification = ({
  setIsNotificationSmallOpen,
  isNotificationSmallOpen,
}) => {
  const { data: notification, isPending } = useQuery({
    queryKey: ["notification"],
    queryFn: getNotification,
  });

  // const ref = useRef();

  const closeNotificationSmall = () => setIsNotificationSmallOpen(false);
  const mobileNotificationRef = useOutsideClick(
    closeNotificationSmall,
    true,
    isNotificationSmallOpen
  );
  const notificationRef = useOutsideClick(closeNotificationSmall);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  function togaleNavbarBtn() {
    setIsNotificationSmallOpen((isMenuOpen) => !isMenuOpen);
  }

  if (isPending) {
    return;
  }

  if (!notification) {
    return;
  }

  return (
    <div className="">
      <div
        ref={mobileNotificationRef}
        style={{
          backgroundColor: "#f5f4f4",
          width: "350px",
          animation: isNotificationSmallOpen
            ? `${Style.slideInFromRight} 0.5s forwards`
            : `${Style.slideOutToRight} 0.5s forwards`,
        }}
        className={
          "z-3 position-fixed end-0   top-0  d-sm-none d-block h-100  px-3  "
        }
      >
        <div className="d-flex justify-content-end pt-4">
          <button
            style={{
              backgroundColor: "#f5f4f4",
              color: "#F04438",
            }}
            className="border-0 mb-4"
            onClick={togaleNavbarBtn}
          >
            close
          </button>
        </div>
        <div className="h-100 border-2 position-relative ">
          <div
            style={{
              justifyContent: "start",
            }}
            className="align-items-start  d-flex flex-column position-absolute top-0 "
          >
            <div className="fw-bold mb-2 ">
              Notification{" "}
              <span className="text-primary">
                ({notification?.length ? notification.length : 0})
              </span>
            </div>

            <div
              style={{
                maxHeight: "300px",
              }}
              className=" overflow-auto"
            >
              {notification &&
                notification.map((notificatio) => (
                  <NotificationBtn
                    key={notificatio.id}
                    topic={notificatio.topic}
                    description={notificatio.description}
                    createdAt={notificatio.createdAt}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          width: "400px",
        }}
        className="position-absolute top-10 d-none d-sm-block  z-20 end-0 "
        ref={notificationRef}
      >
        <div
          style={{
            backgroundColor: "#f3f4f6",
          }}
          className="p-3 shadow-sm overflow-hidden h-100  w-sm-750 w-100 rounded-2 "
        >
          <div className="fw-bold mb-2 ">
            Notification{" "}
            <span className="text-primary">
              ({notification?.length ? notification.length : 0})
            </span>
          </div>

          <div
            style={{
              maxHeight: "300px",
            }}
            className=" overflow-auto"
          >
            {notification &&
              notification.map((notificatio) => (
                <NotificationBtn
                  key={notificatio.id}
                  topic={notificatio.topic}
                  description={notificatio.description}
                  createdAt={notificatio.createdAt}
                />
              ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default MobileNotification;
