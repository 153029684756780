import { useEffect, useRef } from "react";

export function useOutsideClick(handler, listenCapturing = true, status = "") {
  const ref = useRef();

  useEffect(() => {
    // if (isOpen) return;
    function handleClick(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        console.log("Outside clicked");
        console.log(status);
        handler();
      }
    }

    document.addEventListener("click", handleClick, listenCapturing);

    return () =>
      document.removeEventListener("click", handleClick, listenCapturing);
  }, [handler, listenCapturing]);

  return ref;
}
