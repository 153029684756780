import React from "react";
import { createChat, createConversation } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateChat as updateChatApi } from "./../services/candidateApi";

export const useUpdateChat = ({ id, userChatDataId }) => {
  const queryClient = useQueryClient();

  const { mutate: updateChat, isPending } = useMutation({
    mutationFn: () => updateChatApi(userChatDataId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["userChat", id],
      });
    },
  });

  return { updateChat, isPending };
};
