import React, { useState, useEffect } from "react";
import "./../css/ReviewCarousel.css";
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

// const reviews = [
//   "Review 1: This is the first review.",
//   "Review 2: This is the second review.",
//   "Review 3: This is the third review.",
//   "Review 4: This is the fourth review.",
// ];

const ReviewCarousel = ({ reviews }) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [transitionClass, setTransitionClass] = useState("");

  const nextReview = () => {
    setTransitionClass("leave");
    setTimeout(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
      setTransitionClass("enter");
    }, 500);
  };

  const prevReview = () => {
    setTransitionClass("leave");
    setTimeout(() => {
      setCurrentReviewIndex(
        (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length
      );
      setTransitionClass("enter");
    }, 500);
  };

  useEffect(() => {
    if (transitionClass === "enter") {
      setTimeout(() => setTransitionClass(""), 500);
    }
  }, [transitionClass]);

  return (
    <div className="review-carousel container text-center">
      <div className="row mb-3 ">
        <div className="d-flex gap-4 justify-content-center align-items-center">
          <div className=" d-flex align-items-center justify-content-center">
            <button className="btn " onClick={prevReview}>
              <FaAngleDoubleLeft />
            </button>
          </div>
          <div className="fs-5 fw-bold">
            <div>
              Reviews{" "}
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                ({currentReviewIndex + 1}/{reviews.length})
              </span>{" "}
            </div>
          </div>
          <div className=" d-flex align-items-center justify-content-center">
            <button className="btn " onClick={nextReview}>
              <FaAngleDoubleRight />
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={`review-content ${transitionClass}`}>
            <div className="d-flex w-100  flex-column align-items-start justify-content-start">
              {/* <div className="d-flex  flex-column align-items-start"> */}
              <div className="d-flex w-75 justify-content-between align-items-end">
                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: "450",
                  }}
                  // className="fw-bold"
                  className="d-flex"
                >
                  {reviews[currentReviewIndex].raterName} (
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src="/star (1).png"
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "5px",
                      }}
                    />
                    <div
                      style={{
                        fontSize: "17px",
                        fontWeight: "450",
                      }}
                    >
                      {reviews[currentReviewIndex].fullOverallRate}
                    </div>
                  </div>
                  )
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6c757d",
                    // fontWeight: "450",
                  }}
                  className="d-md-inline d-none"
                >
                  {reviews[currentReviewIndex].date}
                </div>
              </div>

              <div>{reviews[currentReviewIndex].text}</div>
              {/* </div> */}
            </div>

            {/* {reviews[currentReviewIndex]} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCarousel;
