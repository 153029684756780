import React from "react";
import { formatTimeAgo } from "../util/utilityFunction";

const NotificationBtn = ({ topic, description, createdAt }) => {
  return (
    <div className="d-flex mt-3 gap-3 align-items-start">
      <div>
        <img
          src="/app-notification.png"
          alt="notification icon"
          width="24px"
          height="24px"
        />
      </div>
      <div
        style={{
          fontSize: "0.9rem",
        }}
        className="d-flex flex-column align-items-start"
      >
        <div className="fw-bold ">{topic}</div>
        <div
          style={{
            color: "#3B3D3C",
          }}
        >
          {description}
        </div>
        <div
          style={{
            color: "#858585",
          }}
        >
          {formatTimeAgo(createdAt)}
        </div>
      </div>
    </div>
  );
};

export default NotificationBtn;
