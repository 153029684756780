import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./../assets/logo.png";
// import imagebg from "../../assets/signinbg.png";
import imagebg1 from "./../assets/BGimage1.png";
import imagebg2 from "./../assets/BGimage2.png";
import imagebg3 from "./../assets/BGimage3.png";

// import imageRating from "../../src/image.png";
import Carousel from "react-bootstrap/Carousel";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const [isXL, setIsXL] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsXL(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container-fluid overflow-hidden bg-white" style={{ height: "100vh" }}>
      <div className="row  py-1 h-100">
        <div className="col-lg-4 col-sm-12 h-100">
          <div
            className="container-fluid bg-white bg-black d-flex  flex-column  "
            style={{
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <div className="flex-grow-0 logo mt-3" onClick={onClick}>
              <img src={logo} alt="logo" style={{ height: "28px" }} />
            </div>
            <div className=" flex-grow-1 container d-flex mt-2   flex-row justify-content-center align-items-center">
              {children}
            </div>
            <div
              style={{
                fontSize: "10px",
                color: "#333",
                marginTop: "15px",
              }}
              className="flex-grow-3"
            >
              <p>© {new Date().getFullYear()} CareerGPT. All rights reserved</p>
            </div>
          </div>
        </div>
        <div className="col-8 h-100 pb-2 d-none d-lg-block overflow-hidden">
          <div className="bg-info rounded-4 justify-content-end row h-100 m-1 p-5 overflow-hidden">
            <div className="overflow-hidden flex align-content-center ">
              {/* <img
                src={imagebg}
                alt="robot"
                className="rounded-start-4  "
                style={{ marginLeft: "100px", width: "95%" }}
              /> */}

              <Carousel
                className="overflow-hidden rounded-4 center"
                prevIcon={<IoIosArrowDropleftCircle color="black" size={35} />}
                nextIcon={<IoIosArrowDroprightCircle color="black" size={35} />}
                variant="dark"
                interval={3000}
              >
                <Carousel.Item className="">
                  <img
                    src={imagebg1}
                    alt="robot"
                    className="rounded-start-4  "
                    style={{ width: "100%" }}
                  />
                </Carousel.Item>
                <Carousel.Item className="">
                  <img
                    src={imagebg2}
                    alt="robot"
                    className="rounded-start-4  "
                    style={{ width: "100%" }}
                  />
                </Carousel.Item>
                <Carousel.Item className="">
                  <img
                    src={imagebg3}
                    alt="robot"
                    className="rounded-start-4  "
                    style={{ width: "100%" }}
                  />
                  {/* <Carousel.Caption className="">
          <div className='fs-1 text-dark' style={{
          
            fontWeight: "750",
            marginBottom: "45%"
          }}>-Salary Sensei-</div>
        
        </Carousel.Caption> */}
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthLayout;
