import Carousel from 'react-bootstrap/Carousel';
import imagebg1 from "../assets/mobileBG1.png";
import imagebg2 from "../assets/mobileBG2.png";
import imagebg3 from "../assets/mobileBG3.png";

function MobileCarousel() {
    return (
        <div className=" mt-5 h-25 w-100 d-block d-sm-none ">
      <Carousel>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={imagebg1}
          alt="Second slide"
        />
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={imagebg2}
          alt="Second slide"
        />
        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={imagebg3}
          alt="Second slide"
        />
        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
    </div>
    )
}

export default MobileCarousel
