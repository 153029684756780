import React, { useState, useEffect } from "react";
import ChatInterface from "../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import Trends from "../components/Trends";
import "../css/salary.css";
import { set } from "immutable";
import { useQuery } from "@tanstack/react-query";
import { newsData } from "../services/candidateApi";
import Spinner from "../ui/Spinner";
import CustomizedSpinner from "../ui/CustomizedSpinner";
const token = sessionStorage.getItem("authToken");

const JobPosting = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadVisible, setUploadVisible] = useState();
  const [files, setFiles] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [awaitingNextAction, setAwaitingNextAction] = useState(false);
  // const [salaryNews, setSalaryNews] = useState([]);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);

  const { data: salaryNews, isLoading } = useQuery({
    queryKey: ["news", "salary news"],
    queryFn: newsData,
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) return <CustomizedSpinner />;

  const handleJopPosting = async (roles) => {
    const token = sessionStorage.getItem("authToken");

    if (awaitingNextAction) {
      handleYesNoResponse(roles);
      return;
    }

    if (!roles || roles.length < 2) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("cvs", files[i]);
    }

    setChatMessages([
      ...chatMessages,

      { bot: "Preparing your job description..." },
    ]);
    setLoading(true);

    try {
      const res = await axios.post(
        `${baseUrl}/files/job/recruiter/?roles=${roles}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // const insights = res.data.salary_insights;
      console.log(res.data);
      const data = res.data;

   

      const botReply = (
        <div>
      {data.roles.map(role => (
        <div key={role}>
          <h2>{role.charAt(0).toUpperCase() + role.slice(1)}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: data.job_descriptions[role].replace(/\n/g, '<br>')
              .replace(/\*{2}(.*?)\*{2}/g, "<strong>$1</strong>")
             }}
          />
        </div>
      ))}
    </div>
      );

      setChatMessages([
        ...chatMessages,
        { user: roles },
        { bot:botReply },
        {
          bot: "Would you like to get insights for another role or position? (yes or no)",
        },
      ]);
      setAwaitingNextAction(true);
      setFiles([]);
      setLoading(false);
    } catch (err) {
      console.error("Error processing request:", err);
      setChatMessages([
        ...chatMessages,
        {
          bot: "We encountered an error while processing your request. Please check your connection and try again later",
        },
      ]);
      setLoading(false);
    }
  };

  const handleYesNoResponse = (userResponse) => {
    if (userResponse.toLowerCase() === "yes") {
      setShowFileUpload(false);
      setChatMessages([
        ...chatMessages,
        { bot: "Please upload another resume." },
      ]);
      setFiles([]);
      setAwaitingNextAction(false);
      setTimeout(() => setShowFileUpload(true), 100);
    } else if (userResponse.toLowerCase() === "no") {
      setChatMessages([
        ...chatMessages,
        { bot: "Thank you for using our salary insights feature!" },
      ]);
      setAwaitingNextAction(false);
    } else {
      setChatMessages([
        ...chatMessages,
        { bot: "Please respond with 'yes' or 'no'." },
      ]);
    }
  };

  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };

  return (
    <div
      className="  d-sm-flex justify-content-sm-center  pt-4 pt-xl-3 px-4 w-100  align-content-center   "
      style={
        {
          // minHeight: "83vh",
          // height: "87vh",
        }
      }
    >
      {/* overflow-y-scroll  */}
      <div
        className="  px-sm-5 gap-3 col-12  d-grid align-content-between  "
        style={{
          height: isMobile ? "83.8vh" : "85vh", //here
          overflowY: "auto", //here
        }}
      >
        <div
          className={`trends-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={isMobile ? { height: "100%", overflowY: "scroll" } : null}
        >
          <div className="trendsPageTitle d-flex flex-row justify-content-between">
            <h5 className="trend-page-title d-md-block d-none">
              Job Market News (from NewsAPI.org)
            </h5>
            <h5 className="trend-page-title-min d-md-none d-block">
              Job Market News
            </h5>
            <a onClick={hideNewsPanel} className="salary-news-title-close-btn">
              Close
            </a>
          </div>
          <Trends data={salaryNews} />
        </div>

        <div
          className="/chat-container  d-grid   "
          style={{
            flex: "2 1 0",
            marginBottom: "0px",
            height: newsPanelVisible ? "40%" : "100%",
          }}
        >
          <ChatInterface
            hideNewsPanel={hideNewsPanel}
            uploadVisible={uploadVisible}
            newsPanelVisible={isMobile && newsPanelVisible}
            messages={chatMessages}
            setUploadVisible={setUploadVisible}
            setChatMessages={setChatMessages}
            onNewMessage={handleJopPosting}
            onFilesUploaded={(uploadedFiles) => {
              const newMessages = [
                ...chatMessages,
                {
                  bot: "Thank you for uploading! Now, please specify the role you're interested in for include to the job description (job title will suffice) and wait for the magic to happen!",
                },
              ];
              setChatMessages(newMessages);
              setFiles([...files, ...uploadedFiles]);
              hideNewsPanel();
              setUploadVisible(true);
            }}
            firstMsg={
              <Typewriter
                words={["Please select your resume in PDF form!"]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default JobPosting;
