import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthButton from "./AuthButton.js";
import "./Header.css";
import { baseUrl } from "../services/api.js";
import { FaBars } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const token = sessionStorage.getItem("authToken");

const renderTooltip = (props, text) => (
  <Tooltip id="button-tooltip" {...props}>
    {text}
  </Tooltip>
);
const Header = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [userType, setUserType] = useState(null); // Add this line
  const [openSidebar, setOpenSidebar] = useState(false);
  const isMobile = window.innerWidth <= 768; // Set your mobile breakpoint here
  const tooltipPlacement = isMobile ? "bottom" : "right";
  useEffect(() => {
    axios
      .get(`${baseUrl}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCurrentUser(response.data.name);

        // Assuming the response has a userType property
        setUserType(response.data.userType); // Add this line
        localStorage.setItem("userType", response.data.userType);
        localStorage.setItem("userId", response.data.id);
        localStorage.setItem("userName", response.data.name);
        localStorage.setItem("isPaid", response.data.isPaid);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  }, []);

  const handleLogout = () => {
    axios
      .post(`${baseUrl}/auth/logout`, [], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        localStorage.removeItem("userType");
        localStorage.removeItem("userId");
        localStorage.removeItem("userName");
        localStorage.removeItem("userObject");
        localStorage.removeItem("isPaid");
        if (window.socket) {
          window.socket.disconnect();
        }
        navigate("/signin");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  return (
    <>
      <nav
        className="navbar-style"
        id="header-nav"
        style={{ minHeight: "7vh" }}
      >
        <div>
          <Link
            to={
              userType === "recruiter" ? "/recruiterlayout" : "/candidatelayout"
            }
            className="navbar-brand"
          >
            <img src="/roboticon.jpg" alt="Robot Icon" />
            CareerGPT: Using AI to Expedite the Job Search Process
          </Link>
        </div>
        <div className="ml-auto d-flex align-items-center">
          {currentUser && (
            <span className="welcome-message">Welcome, {currentUser}</span>
          )}
          <AuthButton
            label="Logout"
            className="logout-btn"
            onClick={handleLogout}
          />
        </div>
      </nav>
      <div className="navbar-style-mobile">
        <div>
          <Link
            to={
              userType === "recruiter" ? "/recruiterlayout" : "/candidatelayout"
            }
            className="navbar-brand flex justify-content-start align-items-center"
          >
            <img src="/roboticon.jpg" alt="Robot Icon" />
            CareerGPT
          </Link>
        </div>
        <div>
          {openSidebar ? (
            <RxCross2
              onClick={() => setOpenSidebar(false)}
              style={{ height: "25px", width: "25px", color: "gray" }}
            />
          ) : (
            <FaBars
              onClick={() => setOpenSidebar(true)}
              style={{ height: "25px", width: "25px", color: "gray" }}
            />
          )}
        </div>
        {openSidebar && (
          <div className="mobileSidebar">
            <div className="col-12" onClick={() => setOpenSidebar(false)}>
              <OverlayTrigger
                placement={tooltipPlacement}
                overlay={(props) =>
                  renderTooltip(
                    props,
                    "Uses Google PaLM to generate personalized salary guidance, taking into account the latest market trends, your years of experience, and market data in your location among other factors."
                  )
                }
              >
                <Link to="/candidate/salary" className="focus-bar-item">
                  Salary Information
                </Link>
              </OverlayTrigger>
            </div>
            <div className="col-12" onClick={() => setOpenSidebar(false)}>
              <OverlayTrigger
                placement={tooltipPlacement}
                overlay={(props) =>
                  renderTooltip(
                    props,
                    "Uses Google PaLM's model to generate personalized resume suggestions, taking into account the latest market data to generate relevant and accurate insights."
                  )
                }
              >
                <Link to="/candidate/resumereview" className="focus-bar-item">
                  Resume Review
                </Link>
              </OverlayTrigger>
            </div>
            <div className="col-12" onClick={() => setOpenSidebar(false)}>
              <OverlayTrigger
                placement={tooltipPlacement}
                overlay={(props) =>
                  renderTooltip(props, "Let's see who's online!")
                }
              >
                <Link
                  to="/candidate/connect-to-recruiter"
                  className="focus-bar-item"
                >
                  Connect to Other Like-Minded Professionals
                  (Candidates/Recruiters)!
                </Link>
              </OverlayTrigger>
            </div>
            <div className="col-12" onClick={() => setOpenSidebar(false)}>
              <OverlayTrigger
                placement={tooltipPlacement}
                overlay={(props) => renderTooltip(props, "Coming Soon!")}
              >
                <Link to="/candidate/networking" className="focus-bar-item">
                  Recruiter/Hiring Manager Search Assistance
                </Link>
              </OverlayTrigger>
            </div>
            <div className="col-12" onClick={() => setOpenSidebar(false)}>
              <OverlayTrigger
                placement={tooltipPlacement}
                overlay={(props) => renderTooltip(props, "Coming Soon!")}
              >
                <Link to="/candidate/jobsearch" className="focus-bar-item">
                  Job Search Assistance
                </Link>
              </OverlayTrigger>
            </div>
            <div className="col-12" onClick={() => setOpenSidebar(false)}>
              <OverlayTrigger
                placement={tooltipPlacement}
                overlay={(props) => renderTooltip(props, "Coming Soon!")}
              >
                <Link to="/candidate/interview" className="focus-bar-item">
                  Interview Preparation
                </Link>
              </OverlayTrigger>
            </div>
            <div className="col-12" onClick={() => setOpenSidebar(false)}>
              <OverlayTrigger
                placement={tooltipPlacement}
                overlay={(props) => renderTooltip(props, "Coming Soon!")}
              >
                <Link to="/candidate/progress" className="focus-bar-item">
                  Goal Setting/Progress
                </Link>
              </OverlayTrigger>
            </div>
            <div className="col-12" onClick={() => setOpenSidebar(false)}>
              <OverlayTrigger
                placement={tooltipPlacement}
                overlay={(props) =>
                  renderTooltip(props, "Give us your valuable feedback!")
                }
              >
                <Link to="/candidate/feedback" className="focus-bar-item">
                  Submit Feedback
                </Link>
              </OverlayTrigger>
            </div>
            <div className="ps-3 col-12">
              <AuthButton
                label="Logout"
                className="logout-btn"
                onClick={handleLogout}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
