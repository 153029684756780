import React from "react";
import Header from "../components/Header";
import FocusBarRecruiter from "../components/FocusBarRecruiter";
import FileUpload from "../components/FileUpload";
import home from "../assets/home.jpg";
import "./../css/RecruiterLayout.css";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import UserNavbar from "../ui/UserNavbar";
const RecruiterLayout = ({ children, isNewChat = false }) => {
  const uploadButton = <FileUpload className="mt-2 d-flex" />;
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const [isTemparySidebarOpen, setIsTemparySidebarOpen] = React.useState(true);

  return (
    // <>
    //   <Header />
    //   <div className="header-text-container">
    //     <IoShieldCheckmarkOutline style={{ color: "white" }} />
    //     <p className="mb-0">CareerGPT was just updated!</p>
    //   </div>
    //   <div className="bg-white" style={{ height: "89vh", paddingTop: "2vh" }}>
    //     <div>
    //       <div className="row rightMargin">
    //         <div className="col-12 col-md-2 col-lg-2">
    //           <FocusBarRecruiter isNewChat={isNewChat} />
    //         </div>
    //         <div className="col-12 col-md-10 d-flex flex-column position-relative main-layout">
    //           {children ? (
    //             children
    //           ) : (
    //             <div className="home-section">
    //               <div
    //                 style={{ backgroundImage: `url(${home})` }}
    //                 className="home-banner"
    //               ></div>
    //               <div className="row">
    //                 <div className="col-12 col-lg-4">
    //                   <div className="card-style">
    //                     <FaUsers style={{ height: "50px", width: "50px" }} />
    //                     <h3>Online Users: 100</h3>
    //                   </div>
    //                 </div>
    //                 <div className="col-12 col-lg-4">
    //                   <div className="card-style">
    //                     <FaUsers style={{ height: "50px", width: "50px" }} />
    //                     <h3>Total Recruiters: 100</h3>
    //                   </div>
    //                 </div>
    //                 <div className="col-12 col-lg-4">
    //                   <div className="card-style">
    //                     <FaUsers style={{ height: "50px", width: "50px" }} />
    //                     <h3>Total Candidates: 100</h3>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <div
      className=""
      style={{
        height: "100vh",
        paddingTop: "2vh",
        backgroundColor: "#f5f4f4",
      }}
    >
      <div>
        <div className="d-flex ">
          <div
            style={{
              // border: "1px solid #ff0000",
              backgroundColor: "#f5f4f4",
            }}
            className={` d-md-block d-none ${
              isSidebarOpen && isTemparySidebarOpen
                ? "col-12 col-md-1 col-lg-3 col-xxl-2"
                : "col-12 col-md-1 col-lg-1"
            }  `}
          >
            <FocusBarRecruiter
              isNewChat={isNewChat}
              isSidebarOpen={isSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
            />
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              height: "96vh",
              borderRadius: "20px",
            }}
            className={`${
              isSidebarOpen && isTemparySidebarOpen
                ? "col-12 col-md-11 col-lg-9 col-xxl-10"
                : "col-12 col-md-11 col-lg-11"
            }  d-flex  flex-column position-relative main-layout`}
          >
            <UserNavbar
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              isTemparySidebarOpen={isTemparySidebarOpen}
              setIsTemparySidebarOpen={setIsTemparySidebarOpen}
            />
            <div className="" style={{ height: "100vh", overflow: "scroll" }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterLayout;
