import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import getSocketInit from "../../socket";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import NoClickChat from "./NoClickChat";
import SenderDetails from "./SenderDetails";
import Conversation from "./Conversation";
import TypeBox from "./TypeBox";
import { useOneChat } from "../../hooks/useOneChat";
import { useGetMe } from "../../hooks/useGetMe";
import { useUserConversation } from "../../hooks/useUserConversation";
import RateComponent from "./Rating";
import { toast } from "react-toastify";
import { useCreateNotification } from "../../hooks/useCreateNotification";
import { useSocket } from "../../context/SocketContext";
// import { CHATLIMIT } from "../../App";
// import { useSocket } from "../../../context/Socket.context";

// const CHATLIMIT = 3;

export default function UserConversation() {
  const [cvUploading, setCvUploading] = useState(false);
  const { socket } = useSocket();

  // socket.connect();

  // const {socket}=useSocket()
  // socket.connect();

  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);

  const params = useParams();
  const id = params.id;
  const [isRating, setIsRating] = useState(false);

  const { myData, isPending } = useGetMe();
  const { userChatData, isPending: isUserChat } = useOneChat(id);
  const { recruiterData, isPending: loadingRecruiterData } =
    useUserConversation(id);

  const { sendNotification } = useCreateNotification();

  useEffect(() => {
    const handleCreateConversation = (toId, data) => {
      if (toId == myData?.id) {
        console.log("Conversation created for user:", toId, data);
        setConversation((conversation) => [...conversation, data]);
      }
    };

    socket.on("create-conversation", handleCreateConversation);

    return () => {
      socket.off("create-conversation", handleCreateConversation);
    };
  }, [socket]);

  useEffect(() => {
    if (recruiterData) {
      setConversation(recruiterData.conversationData?.details || []);
    }
  }, [recruiterData]);

  // useEffect(() => {
  //   if (userChatData.firstRate && !userChatData.secondRate || !userChatData.firstRate && userChatData.secondRate) {
  //     setIsRating(true)
  //   }
  // }, [userChatData ]);

  if (loadingRecruiterData || isPending || isUserChat) {
    return <CustomizedSpinner />;
  }

  if (recruiterData.recruiterData.length == 0) {
    return <NoClickChat text="This conversation is no longer available" />;
  }

  // const CHATLIMIT = myData.subscriptionPlan == "pro" ? 50 : 3;

  return (
    <div
      className=" mt-md-3 mt-sm-5  d-sm-flex d-grid justify-content-sm-between     px-4 w-100 overflow-hidden   "
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        className="  d-grid   "
      >
        <div className="w-100">
          <div
            style={{
              height: "auto",
            }}
          >
            <SenderDetails
              recruiterData={recruiterData}
              userChatData={userChatData}
            />
          </div>
          <Conversation
            conversation={conversation}
            isRating={isRating}
            cvUploading={cvUploading}
            setIsRating={setIsRating}
            recruiterData={recruiterData}
          />
        </div>
        {userChatData ? (
          !userChatData?.ratingTime && (
            <TypeBox
              userChatData={userChatData}
              isRating={isRating}
              // handleInput={handleInput}
              // handleSendMessage={handleSendMessage}
              // handleFileChange={handleFileChange}
              // handleChatCloseBtn={handleChatCloseBtn}
              message={message}
              id={id}
              setCvUploading={setCvUploading}
              setConversation={setConversation}
              setMessage={setMessage}
              setIsRating={setIsRating}
            />
          )
        ) : (
          <TypeBox
            userChatData={userChatData}
            isRating={isRating}
            id={id}
            setCvUploading={setCvUploading}
            // handleInput={handleInput}
            // handleSendMessage={handleSendMessage}
            // handleFileChange={handleFileChange}
            // handleChatCloseBtn={handleChatCloseBtn}
            message={message}
            setConversation={setConversation}
            setMessage={setMessage}
            setIsRating={setIsRating}
          />
        )}
      </div>
    </div>
  );
}
