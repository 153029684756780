import React, { useEffect } from "react";
import { useGetMe } from "../hooks/useGetMe";
import { toast } from "react-toastify";
import getSocketInit from "../socket";

const MessageNotification = () => {
  const socket = getSocketInit();
  socket.connect();

  const { myData, isPending } = useGetMe();

  useEffect(() => {
    const handleCreateConversation = (toId, data) => {
      if (toId == myData?.id) {
        // setConversation((conversation) => [...conversation, data]);
        // console.log("Convers"+ data);
        toast.success(data.text);
      }
    };

    socket.on("create-conversation", handleCreateConversation);

    return () => {
      socket.off("create-conversation", handleCreateConversation);
    };
  }, [socket]);
  return <div></div>;
};

export default MessageNotification;
