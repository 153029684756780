import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getMe } from "../services/candidateApi";
import { getUserChat } from "../services/userApi";

export const useUserChat = () => {
  const { data: userChat, isPending } = useQuery({
    queryKey: ["userChat"],
    queryFn: getUserChat,
  });
  return { userChat, isPending };
};
