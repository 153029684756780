import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./bootstrap-custom.scss";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import ErrortTemp from "./ui/ErrortTemp";
import ErrorFallBack from "./ui/ErrorFallBack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallBack}
      onReset={() => window.location.replace("/")}
    >
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();
