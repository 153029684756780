import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getAllCoach, getAllcandidate, getMe } from "../services/candidateApi";

export const useGetAllCoach = () => {
  const { data: allCoach, isPending } = useQuery({
    queryKey: ["allCoach"],
    queryFn: getAllCoach,
  });

  return { allCoach, isPending };
};
