import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { deleteUser } from "../../services/apiSetting";
import { Navigate, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { useState } from "react";
import { Spinner } from "react-bootstrap";

function DeleteProfile() {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { mutate: deleteMe, isPending: deletingProfile } = useMutation({
    mutationFn: () => deleteUser(),
    onSuccess: () => {
      navigate("/signin");

      // toast.success("password changed successfully");
    },
  });

  const deleteProfile = () => {
    deleteMe();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className=" align-items-center d-flex r">
        <div className="row">
          <div className="col-12 align-content-end ">
            <p>You can permanently delete your account by clicking the button below. </p>
            {deletingProfile ? (
              <button
                // onClick={deleteProfile}
                className="bg-danger text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0"
              >
                <img
                  src="/Rolling@1x-1.1s-200px-200px.svg"
                  alt="loading.."
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                ></img>
              </button>
            ) : (
              <button
                onClick={handleShow}
                className=" text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0"
                style={{ backgroundColor: "#dc3545" }}
              >
                <div className="mx-1">Delete</div>
              </button>
            )}
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Account Confirmation </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to permanently delete your account? This cannot be undone and you will lose all your data.</Modal.Body>
        {deletingProfile?<div className="d-flex justify-content-center pb-4 pt-2"><Spinner/></div>:
        <Modal.Footer className="d-flex gap-3">
          <button
            className=" text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0"
            style={{ backgroundColor: "#dc3545" }}
            onClick={deleteProfile}
          >
            Confirm
          </button>
          <button
            className=" text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0"
            style={{ backgroundColor: "#198754" }}
            onClick={handleClose}
          >
            Cancel
          </button>
        </Modal.Footer>}
      </Modal>
    </div>
  );
}

export default DeleteProfile;
