import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../services/candidateApi";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../ui/Spinner";
import { formatTimeAgo } from "../../util/utilityFunction";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import { FaCheck } from "react-icons/fa6";

function OneMobileChat({
  name,
  lastMsgCreateAt,
  profilePic,
  setId,
  userId,
  lstMsg,
  lastMsgUserName,
}) {
  // console.log(lastMsgCreateAt);
  // const [hovering, setHovering] = useState(false);

  const navigation = useNavigate();

  const { data: myData, isPending } = useQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
  });

  if (isPending) {
    return <CustomizedSpinner />;
  }

  const redirectChat = () => {
    setId(userId);
  };

  const truncateText = (text) => {
    return text.length > 17 ? text.substring(0, 17) + "..." : text;
  };

  return (
    <button
      onClick={redirectChat}
      className="w-100 border-0 border-white px-0 p-0 mt-2 "
    >
      <div
        style={{
          backgroundColor: "#F8F8F8",
        }}
        className="w-100 d-flex rounded-3 p-3 col-12"
        // onMouseEnter={() => setHovering(true)}
        // onMouseLeave={() => setHovering(false)}
      >
        <div className="d-flex gap-3  w-100  align-content-center  ">
          <div>
            <img
              src={profilePic}
              alt="profile"
              style={{ width: "40px", height: "40px" }}
              className=" rounded-circle "
            />
          </div>

          <div className={` justify-content-between w-100`}>
            <div className="  d-flex justify-content-between  align-items-center w-100 ">
              <div
                style={{
                  fontSize: "14px",
                }}
                className="fw-medium align-items-center d-flex"
              >
                {name}
              </div>

              {/* {!hovering && ( */}
              <div
                className=" text-end  align-items-center  d-flex"
                style={{ color: " #787979    ", fontSize: "12px" }}
              >
                {formatTimeAgo(lastMsgCreateAt)}
              </div>
              {/* )} */}
            </div>
            <div className=" align-items-center d-flex w-100 justify-content-between  ">
              <div
                className=" align-items-center d-flex "
                style={{
                  color: " #3B3D3C",
                  fontSize: "14px",
                }}
              >
                {lastMsgUserName == "admin" ? (
                  "admin:"
                ) : myData.name == lastMsgUserName ? (
                  <FaCheck />
                ) : (
                  ""
                )}
                <span className="ms-1">{truncateText(lstMsg)}</span>
              </div>

              {/* <button
                className="bg-primary rounded-4 fw-light border-0  text-end align-items-center d-flex "
                style={{ color: " #fff    ", fontSize: "12px" }}
              >
                New
              </button> */}
            </div>
          </div>
          {/* {hovering && (
            <div className=" fw-medium text-primary   justify-content-end d-flex align-items-center  ">
              <Link
                to={`startChat/${userId}`}
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                <div className="d-flex gap-1">
                  <div>Start </div> <div>Chat</div>
                </div>
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </button>
  );
}

export default OneMobileChat;
