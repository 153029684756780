import React, { useState } from "react";
import AuthButton from "../components/AuthButton.js";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { checkUserForgotPassword } from "../services/candidateApi.js";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  // const { mutation, isPending } = useMutation({

  //   mutationFn: ({ email }) => checkUserForgotPassword(email),
  //   onSuccess: (data) => {
  //     toast.success("Password changed successfully");
  //     navigate(`/reset-password-otp/${data.id}`);
  //   },
  //   onError: (error) => {
  //     toast.error("Error during changing password : ", error);
  //   },
  // });

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateForm = () => {
    let isValid = true;

    // Email validation
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const data = await checkUserForgotPassword(email);

      if (!data) return setEmailError("This email has not been registered");
      // console.log(data);
      navigate(`/reset-password-otp/${data.id}`);
    }
  };
  const handleChangeEmail = (event) => {
    setEmail(event);
    setEmailError("");
  };

  const handleSignIn = () => {
    navigate("/signin");
  };

  return (
    <div className=" p-sm-5 m-sm-5 m-lg-0 p-lg-0 p-xl-5 m-xl-3">
      <h3 className="mb-3">Forgot Password</h3>
      {/* <div>{}</div> */}
      <p className="fs-xs">
        No worries! Enter the email associated with your account and we'll send
        you a 4 digit PIN to reset your password.
      </p>
      <div className="mb-3">
        <div className="d-flex justify-content-between pt-2">
          <label
            style={{ marginBottom: "8px" }}
            className="fs-xs fw-medium"
            htmlFor="email"
          >
            Email Address
          </label>
        </div>
        {emailError && (
          <div style={{ color: "#F04438" }} className="fs-xs">
            {emailError}
          </div>
        )}
        <input
          type="email"
          id="email"
          className="form-control shadow-none form-control-lg rounded-4 fs-sm"
          value={email}
          onChange={(e) => handleChangeEmail(e.target.value)}
          placeholder="Enter your email"
          onFocus={(e) => (e.target.style.border = "1px solid #9D9E9D")}
          onBlur={(e) => (e.target.style.border = "1px solid #DADBDA")}
          style={
            emailError
              ? {
                  outline: "none",
                  border: `1px solid #F04438`,
                }
              : {
                  outline: "none",
                  border: `1px solid #DADBDA`,
                }
          }
        />
      </div>
      <div className="text-center">
        <AuthButton
          label="Continue"
          onClick={handleSubmit}
          className={`mb-3 p-2 w-100 rounded-4 btn-lg fs-6 fw-medium`}
          style={{
            background: email === "" ? "#F8F8F8" : "#29B475",
            color: email === "" ? "#000000" : "#ffffff",
            border: "none",
          }}
        />
      </div>
      <div
        style={{
          fontSize: "14px",
          // color: "#333",
          textAlign: "center",
        }}
        className=""
      >
        If you want to{" "}
        <button
          onClick={handleSignIn}
          className="text-primary border-0 p-0 bg-white"
        >
          {" "}
          sign in{" "}
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
