import { useQuery } from "@tanstack/react-query";
import { getMe } from "../../services/candidateApi";
import SettingSideBarButton from "./SettingSideBarButton";
import Spinner from "../../ui/Spinner";
import CustomizedSpinner from "../../ui/CustomizedSpinner";

function SettingSideBar() {
  // const userType = localStorage.getItem("userType");

  const { data: myData, isPending } = useQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
  });

  if (isPending) return <CustomizedSpinner />;

  return (
    <div className="gap-3 d-grid ">
      <SettingSideBarButton
        text="Profile"
        to={`/${myData.userType}/setting/profile`}
      />
      <SettingSideBarButton
        text="Password"
        to={`/${myData.userType}/setting/password`}
      />
      <SettingSideBarButton
        text="Social Profile"
        to={`/${myData.userType}/setting/socialprofile`}
      />
      <SettingSideBarButton
        text="Resume"
        to={`/${myData.userType}/setting/resume`}
      />

      <SettingSideBarButton
        text="Theme"
        to={`/${myData.userType}/setting/changetheme`}
      />
      <SettingSideBarButton
        text="Delete Profile"
        to={`/${myData.userType}/setting/deleteprofile`}
      />
    </div>
  );
}

export default SettingSideBar;
