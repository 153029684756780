import axios from "axios";
import { baseUrl } from "./api";
import { getToken } from "../util/utilityFunction";
const token = sessionStorage.getItem("authToken");
export const newsData = async () => {
  const token = await getToken();
  if (!token) return null;

  const response = await axios.get(`${baseUrl}/news`, {
    params: {
      filter: "salary news",
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.data && Array.isArray(response.data)) {
    const data = response.data;
    return data;
  }
  return null;
};

export const getAllCandidate = async () => {
  const response = await axios.get(`${baseUrl}/candidates`);
  if (response.data) {
    const data = response.data;
    return data;
  }
  return null;
};
