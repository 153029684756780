import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import Trends from "../components/Trends";
import "../css/salary.css";
import { useQuery } from "@tanstack/react-query";
import { newsData } from "../services/candidateApi";
import LinkedInProfileCard from "./connectToProfessionals/LinkedInProfilesCard";
import ChatInterfaceForNetworking from "../components/ChatInterfaceForNetworking";
import { useGetMe } from "../hooks/useGetMe";
import UserCardForMatching from "./connectToProfessionals/UserCardForMatching";

function CandidateRecruiterMatching() {
  const { myData, isPending: gettingMe } = useGetMe();

  const [chatMessages, setChatMessages] = useState([
    {
      bot: "Please enter the industry related to the job title you wish to be hired for (e.g., Technology, Healthcare, Finance).",
    },
  ]);
  const [viewAllUsers, setViewAllUsers] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const [uploadVisible, setUploadVisible] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchRecruiterIndustry, setSearchRecruiterJobTitle] = useState("");
  const [searchRecruiterCountry, setSearchRecruiterCountry] = useState("");
  // const [searchQuery, setSearchQuery] = useState("");

  const closeNewsPanel = () => {
    setNewsPanelVisible(false);
    // if (!uploadVisible) {
    //   setChatMessages([
    //     ...chatMessages,
    //     {
    //       bot: "Please select your resume in PDF form!",
    //     },
    //   ]);
    //   setUploadVisible(true);
    // }

  };

  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };

  const { data: salaryNews } = useQuery({
    queryKey: ["news", "salary news"],
    queryFn: newsData,
  });

  const handleSearchingJobRecruiter = async (message) => {
    const token = sessionStorage.getItem("authToken");
    const bio = myData?.bio ? myData.bio : myData.industry;
    console.log("Inside the handleSearchingJobRecruiter function");

    setChatMessages((prevMessages) => [
      ...prevMessages,
      { user: message },
      { bot: "Fetching recruiters with our vectorization algorithm..." },
    ]);

    setLoading(true);

    try {
      const res1 = await axios.get(
        `${baseUrl}/search/searchRecruiter?country=${searchRecruiterCountry}&industry=${searchRecruiterIndustry}&bio=${bio}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const botReply1 = (
        <div>
          <div className="d-flex justify-content-between mt-2">
            <div className="fw-medium">Best Match Recruiters</div>
            {res1.data && res1.data.length > 4 && (
              <button
                className={`border-0 bg-body fw-medium text-${myData.theme} p-0`}
                style={{ fontSize: "14px" }}
                onClick={() => setViewAllUsers(!viewAllUsers)}
              >
                {viewAllUsers ? "Close" : "View All"}
              </button>
            )}
          </div>
          <div className="row flex-row p-2 mb-3">
            {res1.data && res1.data.length ? (
              (viewAllUsers ? res1.data : res1.data.slice(0, 4)).map(
                (allUsers) => {
                  if (allUsers.id !== myData.id) {
                    return (
                      <UserCardForMatching
                        rating={allUsers.rating}
                        userType={allUsers.userType}
                        userId={allUsers.id}
                        key={allUsers.id}
                        name={allUsers.name}
                        role={allUsers.industry}
                        country={allUsers.country}
                        experience={
                          allUsers.experience
                            ? `${allUsers.experience}Y exp.`
                            : ""
                        }
                        profilePic={allUsers.image}
                        theme={myData.theme}
                      />
                    );
                  }
                  return null;
                }
              )
            ) : (
              <div className="col-12">No matching professionals found.</div>
            )}
          </div>
        </div>
      );

      setChatMessages((prevMessages) => [
        ...prevMessages,
        { bot: botReply1 },
        {
          bot: "Fetching LinkedIn profiles based on your personalized query...",
        },
      ]);

      const res2 = await axios.get(
        `${baseUrl}/search/linkedin/recruiterProfiles?query=${encodeURIComponent(
          message
        )}&searchType=Recruiter`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const botReply2 = (
        <div>
          <div className="d-flex justify-content-between mt-2">
            <div className="fw-medium">LinkedIn Recruiter Profiles</div>
            {res2.data && res2.data.length > 4 && (
              <button
                className={`border-0 bg-body fw-medium text-${myData.theme} p-0`}
                style={{ fontSize: "14px" }}
                onClick={() => setViewAllUsers(!viewAllUsers)}
              >
                {viewAllUsers ? "Close" : "View All"}
              </button>
            )}
          </div>
          <div className="row flex-row p-2 mb-3">
            {res2.data && res2.data.length ? (
              (viewAllUsers ? res2.data : res2.data.slice(0, 4)).map(
                (profile) => (
                  <LinkedInProfileCard
                    key={profile.id}
                    fullName={profile.fullName}
                    profilePicture={profile.profilePicture}
                    profileUrl={profile.profileUrl}
                    userType={profile.userType}
                    theme={myData.theme}
                  />
                )
              )
            ) : (
              <div className="col-12">No LinkedIn recruiters found.</div>
            )}
          </div>
        </div>
      );

      setChatMessages((prevMessages) => [
        ...prevMessages,
        { bot: botReply2 },
        {
          bot: "Do you want to search more recruiters according to another industry and country? (yes/no)",
        },
      ]);
    } catch (error) {
      console.error(error);
      setChatMessages([
        ...chatMessages,
        { bot: "Sorry, we could not search for recruiters for you." },
      ]);
      setStep(0);
    } finally {
      setLoading(false);
    }
  };

  const recruiterMatching = async (input) => {
    const message = input.toLowerCase();
    switch (step) {
      case 0:
        if (!message) {
          setChatMessages([
            ...chatMessages,
            {
              bot: "Please enter the industry related to the job title you wish to be hired for (e.g., Technology, Healthcare, Finance).",
            },
          ]);
          return;
        }
        setSearchRecruiterJobTitle(message);
        setChatMessages([
          ...chatMessages,
          { user: message },
          { bot: "Please enter the country you wish to be hired in" },
        ]);
        setStep(1);
        break;
      case 1:
        if (!message) {
          setChatMessages([
            ...chatMessages,
            {
              bot: "Please enter the country you wish to be hired in",
            },
          ]);
          return;
        }
        setSearchRecruiterCountry(message);
        setChatMessages([
          ...chatMessages,
          { user: message },
          {
            bot: "Please enter a personalized query to search LinkedIn profiles.",
          },
        ]);
        setStep(2);
        break;
      case 2:
        if (!message) {
          setChatMessages([
            ...chatMessages,
            {
              bot: "Please enter a personalized query to search LinkedIn profiles.",
            },
          ]);
          return;
        }
        await handleSearchingJobRecruiter(message);
        setStep(3);
        break;
      case 3:
        if (message.toLowerCase() === "yes") {
          setStep(0);
          setChatMessages([
            ...chatMessages,
            {
              bot: "Please enter the industry related to the job title you wish to be hired for (e.g., Technology, Healthcare, Finance).",
            },
          ]);
        } else {
          setChatMessages([
            ...chatMessages,
            {
              bot: "Thank you for using our service",
            },
          ]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="d-sm-flex justify-content-sm-center pt-4 pt-xl-3 px-4 w-100 align-content-center"
      style={{ height: "85vh", overflowY: "auto" }}
    >
      <div
        className="px-sm-5 gap-3 col-12 d-grid align-content-between"
        style={{
          height: isMobile ? "83.8vh" : "85vh",
          overflowY: "auto",
        }}
      >
        <div
          className={`trends-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={isMobile ? { height: "100%", overflowY: "scroll" } : null}
        >
          <div className="trendsPageTitle d-flex flex-row justify-content-between">
            <h5 className="trend-page-title d-md-block d-none">
              Job Market News (from NewsAPI.org)
            </h5>
            <h5 className="trend-page-title-min d-md-none d-block">
              Job Market News
            </h5>
            <a onClick={closeNewsPanel} className="salary-news-title-close-btn">
              Close
            </a>
          </div>
          <Trends data={salaryNews} />
        </div>

        <div
          className="chat-container"
          style={{
            flex: "2 1 0",
            marginBottom: "0px",
            height: newsPanelVisible ? "40%" : "100%",
          }}
        >
          <ChatInterfaceForNetworking
            messages={chatMessages}
            hideNewsPanel={hideNewsPanel}
            newsPanelVisible={isMobile && newsPanelVisible}
            uploadVisible={uploadVisible}
            setChatMessages={setChatMessages}
            onNewMessage={(message) => {
              recruiterMatching(message);
              setNewsPanelVisible(false);
            }}
            // firstMsg={
            //   <Typewriter
            //     words={["Please select your resume in PDF form!"]}
            //     cursor
            //     cursorStyle="_"
            //     deleteSpeed={-1}
            //     typeSpeed={30}
            //   />
            // }
          />
        </div>
      </div>
    </div>
  );
}

export default CandidateRecruiterMatching;
