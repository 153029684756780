import React, { useState } from "react";
import AuthButton from "../components/AuthButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import OtpInput from "react-otp-input";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getOtp, resendOtp } from "../services/candidateApi";
import { toast } from "react-toastify";
const ResetPasswordOtp = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const { data: otpData, isPending: loadingOTP } = useQuery({
    queryKey: ["otp", id],
    queryFn: () => getOtp(id),
  });

  const queryClient = useQueryClient();

  const { mutate: resendOTP, isPending } = useMutation({
    mutationFn: ({ id }) => resendOtp(id),
    onSuccess: (data) => {
      toast.success("Resend the otp successfully ");
      queryClient.invalidateQueries({
        queryKey: ["otp", id],
      });
      return;
    },
    onError: (error) => {
      toast.error("Error during changing password : ", error);
    },
  });

  function isExpired(dateTimeString) {
    const now = new Date();
    const dateTime = new Date(dateTimeString);

    return dateTime <= now;
  }

  const handleResetOtp = () => {
    // console.log(otpData);
    if (otp.length != 6) {
      // console.log("otp is valid");
      setError("enter valid otp code");
      return;
    }

    if (isExpired(otpData.otpExpireDate)) {
      setError("otp is expired");
      return;
    }

    if (otpData.otp === otp) {
      navigate(`/reset-password-confirmation/${id}`);
    } else {
      setError("otp is wrong");
      setOtp("");
    }
    // console.log(otpData);
  };

  async function handleResendOtp() {
    resendOTP({ id });
  }
  return (
    <div className="p-lg-3 ">
      <h3>Enter Your OTP Code</h3>
      <p className="fs-xs">
        Please enter the OTP code sent to your email address
      </p>

      <div style={{ color: "#F04438" }} className=" fs-xs">
        {error && error}
      </div>

      <div
        style={{
          width: "100%",
        }}
        className="d-flex justify-content-center align-items-center mt-3 mb-3"
      >
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          placeholder="_"
          shouldAutoFocus={true}
          inputStyle={{
            width: "2rem",
          }}
        />
      </div>

      <div className="text-center">
        {/* <Link to="/reset-password-confirmation"> */}
        <AuthButton
          label="Resend OTP"
          onClick={handleResendOtp}
          className={`mb-3 p-2 w-100 bg-danger rounded-4 btn-lg fs-6 fw-medium`}
          style={{
            color: "#ffffff",
            border: "none",
          }}
        />
      </div>

      <div className="text-center">
        {/* <Link to="/reset-password-confirmation"> */}
        <AuthButton
          label="Confirm"
          onClick={handleResetOtp}
          className={`mb-3 p-2 w-100 rounded-4 btn-lg fs-6 fw-medium`}
          style={{
            background: otp.length != 6 ? "#F8F8F8" : "#29B475",
            color: otp.length != 6 ? "#000000" : "#ffffff",
            border: "none",
          }}
          // style={{
          //   background:
          //     password === "" || confirmPassword === ""
          //       ? "#F8F8F8"
          //       : "#29B475",
          //   color:
          //     password === "" || confirmPassword === ""
          //       ? "#000000"
          //       : "#ffffff",
          //   border: "none",
          // }}
        />
        {/* </Link> */}
      </div>
    </div>
  );
};
export default ResetPasswordOtp;
