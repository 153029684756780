import React, { useState, useEffect } from "react";
import ChatInterface from "../../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../../services/api";
import { Typewriter } from "react-simple-typewriter";
import { Tooltip, OverlayTrigger, Container } from "react-bootstrap";
import Trends from "../../components/Trends";
import { useQuery } from "@tanstack/react-query";
import { interviewNewsData } from "../../services/candidateApi";
import GaugeChart from "react-gauge-chart";

const ResumeReviewRecruiter = () => {
  
  const [chatMessages, setChatMessages] = useState([]);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [awaitingNextAction, setAwaitingNextAction] = useState(false);
  const [jobNews, setJobNews] = useState([]); // New state for job news
  const [uploadVisible, setUploadVisible] = useState();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { data: interviewNews, isLoading } = useQuery({
    queryKey: ["news", "salary news"],
    queryFn: interviewNewsData,
  });
  const closeNewsPanel = () => {
    setNewsPanelVisible(false);
    if (!uploadVisible) {
    const newMessages = [
      ...chatMessages,
      {
        bot: "Please select your resume in PDF form!",
      },
    ];
    setChatMessages(newMessages);
    hideNewsPanel();
    setUploadVisible(true);
  }
  };

  const parseResponse = (response) => {
    const lines = response.split('\n');
    const elements = [];
  
     lines.forEach((line, index) => {
    if (line.startsWith('##')) {
      elements.push(<h2 key={index} className="mt-4">{line.replace('## ', '')}</h2>);
    } else if (line.startsWith('* **')) {
      elements.push(<li key={index} className="list-unstyled"><strong>{line.replace('* **', '').replace('**', '')}</strong></li>);
    } else if (line.startsWith('**')) {
      elements.push(<p key={index} className="mt-3"><strong>{line.replace(/\*\*/g, '')}</strong></p>);
    } else if (line.startsWith('*')) {
      elements.push(<li key={index} className="list-unstyled">{line.replace('* ', '')}</li>);
    } else if (line.trim()) {
      elements.push(<p key={index}>{line}</p>);
    }
  });
  
    return elements;
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Cosine similarity measures the cosine of the angle between two vectors in
      a multidimensional space. It's used here to gauge how similar the content
      of the CV is to the provided keyword.
    </Tooltip>
  );

  // useEffect(() => {
  //   const fetchJobNews = async () => {
  //     try {
  //       const response = await axios.get(`${baseUrl}/news`, {
  //         params: {
  //           filter: "interview prep",
  //         },
  //         withCredentials: true,
  //       });
  //       if (response.data && Array.isArray(response.data)) {
  //         setJobNews(response.data);
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch job news.", error);
  //     }
  //   };

  //   fetchJobNews();
  // }, []);

  const handleConversation = async (userMessage) => {
    if (awaitingNextAction) {
      handleYesNoResponse(userMessage);
      return;
    }

    if (!userMessage || userMessage.length < 2) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("cvs", files[i]);
    }

    setChatMessages([...chatMessages, { bot: "Processing your request..." }]);
    setLoading(true);

    //     const { data: salaryNews, isLoading } = useQuery({
    //   queryKey: ["news", "salary news"],
    //   queryFn: newsData,
    // });

    try {
      const res = await axios.post(
        `${baseUrl}/files/resume/recruiter/?description=${userMessage}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      console.log(res)
      const parsedResponse = parseResponse(res.data);
      const botReply = (
        <Container>
          {parsedResponse}
        </Container> 
      );

      setChatMessages([
        ...chatMessages,
        { user: userMessage },
        { bot: botReply },
        { bot: "Would you like to make another request? (yes or no)" },
      ]);
      setAwaitingNextAction(true);
      setFiles([]);
      setLoading(false);
    } catch (err) {
      console.error("Error processing files:", err);
      setChatMessages([
        ...chatMessages,
        {
          bot: "We encountered an error while processing your request. Please check your connection and try again later",
        },
      ]);
      setLoading(false);
    }
  };

  const handleYesNoResponse = (userResponse) => {
    if (userResponse.toLowerCase() === "yes") {
      setShowFileUpload(false);
      setChatMessages([
        ...chatMessages,
        { bot: "Please upload more files to rank." },
      ]);
      setFiles([]);
      setAwaitingNextAction(false);
      setTimeout(() => setShowFileUpload(true), 100);
    } else if (userResponse.toLowerCase() === "no") {
      setChatMessages([
        ...chatMessages,
        { bot: "Thank you for your time, hope you found this service useful!" },
      ]);
      setAwaitingNextAction(false);
    } else {
      setChatMessages([
        ...chatMessages,
        { bot: "Please respond with 'yes' or 'no'." },
      ]);
    }
  };

  return (
    <div
      className="  d-sm-flex justify-content-sm-center  pt-4 pt-xl-3 px-4 w-100  align-content-center   "
      style={
        {
          // minHeight: "83vh",
          // height: "87vh",
        }
      }
    >
      {/* overflow-y-scroll  */}
      <div
        className="  px-sm-5 gap-3 col-12   d-grid align-content-between     "
        style={{
          height: isMobile ? "83.8vh" : "85vh", //here
          overflowY: "auto", //here
        }}
      >
        <div
          className={`trends-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={isMobile  ? { height: "100%", overflowY: "scroll" } : null}
        >
          <div className=" trendsPageTitle d-flex flex-row justify-content-between ">
            <h5 className="trend-page-title d-md-block d-none">Job Market News (from NewsAPI.org)</h5>
            <h5 className="trend-page-title-min d-md-none d-block">
              Job Market News
            </h5>
            <a
              onClick={closeNewsPanel}
              className="salary-news-title-close-btn"
            >
              Close
            </a>
          </div>
          <Trends data={interviewNews} />
        </div>

        <div
          className="chat-container  "
          style={{
            flex: "2 1 0",
            marginBottom: "0px",
            height: newsPanelVisible ? "40%" : "100%",
          }}
        >
          <ChatInterface
          isPlural={true}
          hideNewsPanel={hideNewsPanel}
          uploadVisible={uploadVisible}
          setUploadVisible={setUploadVisible}
            setChatMessages={setChatMessages}
            newsPanelVisible={isMobile && newsPanelVisible}
            messages={chatMessages}
            onNewMessage={handleConversation}
            onFilesUploaded={(uploadedFiles) => {
              const newMessages = [
                ...chatMessages,
                {
                  bot: "Thank you! Your resume has been successfully uploaded. You can continue uploading more resumes or enter criteria to proceed.",
                },
              ];
              setChatMessages(newMessages);
              setFiles([...files, ...uploadedFiles]);
              setUploadVisible(true)
              hideNewsPanel();
            }}
            firstMsg={
              <Typewriter
                words={[
                  "Please select the resumes that you would like to rank in PDF form!",
                ]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ResumeReviewRecruiter;
