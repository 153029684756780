import React, { useState } from "react";
import ChatInterface from "../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import Trends from "../components/Trends";
import "../css/salary.css";
import { useQuery } from "@tanstack/react-query";
import { newsData } from "../services/candidateApi";
import CustomizedSpinner from "../ui/CustomizedSpinner";
import ChatInterfaceWithoutUpload from "../components/ChatInterfaceWithoutUpload";
import SupportAndFeedback from "./SupportAndFeedback";

const token = sessionStorage.getItem("authToken");

const HelpAndSupport = () => {

  // const [chatMessages, setChatMessages] = useState([
  //   {
  //     bot: "How can we help you today?",
  //   },
  // ]);
  // const [loading, setLoading] = useState(false);
  // const [files, setFiles] = useState([]);
  // const [salaryInsights, setSalaryInsights] = useState([]);
  // const [initialAdviceContext, setInitialAdviceContext] = useState(null);
  // const [conversationHistory, setConversationHistory] = useState([]);

  // const { data: salaryNews, isLoading } = useQuery({
  //   queryKey: ["news", "salary news"],
  //   queryFn: newsData,
  // });

  // if (isLoading) return <CustomizedSpinner />;



  // const handleHelpAndSupport = async (message) => {
  //   setChatMessages([
  //     ...chatMessages,
  //     { user: message },
  //     { bot: "this bot feature is underdevelopment..." },
  //   ]);
  // };


  return (
    <div
      className="d-sm-flex justify-content-sm-center pt-5 pt-xl-3 px-4 w-100 align-content-center"
      style={{ height: "87vh" }}
    >
      <div className="px-sm-5 gap-3 col-12 d-grid overflow-y-scroll">
        <div
          className="chat-container  justify-content-center d-flex flex-column align-items-center"
          style={{ flex: "2 1 0", marginBottom: "0px" }}
        >
          <SupportAndFeedback/>
          {/* <ChatInterfaceWithoutUpload
            messages={chatMessages}
            onNewMessage={(message) => {
              handleHelpAndSupport(message);
            }}
            setChatMessages={setChatMessages}
            firstMsg={
              <Typewriter
                words={["Please select your resume in PDF form!"]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          /> */}
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;