import React, { useState } from "react";
import Rating from "react-rating";
import OneRating from "./OneRating";
import { Form } from "react-bootstrap";
import { useGetMe } from "../../hooks/useGetMe";
import { useParams } from "react-router-dom";
import { useUpdateRating } from "../../hooks/useUpdateRating";
import { toast } from "react-toastify";
import { useOneChat } from "../../hooks/useOneChat";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import { useQueryClient } from "@tanstack/react-query";

const RateComponent = ({ name, setIsRating, width, isRating }) => {
  const updatingProfileData = false;
  const [communication, setCommunication] = React.useState(0);
  const [knowledge, setKnowledge] = React.useState(0);
  const [engagement, setEngagement] = React.useState(0);
  const [professionalism, setProfessionalism] = React.useState(0);
  const [text, setText] = React.useState("");
  const params = useParams();
  const id = params.id;
  const { updateRate, isPending } = useUpdateRating();
  const { myData, isPending: myDataLoading } = useGetMe();

  const queryClient = useQueryClient();

  const { userChatData, isPending: isUserChat } = useOneChat(id);

  if (isPending || myDataLoading || isUserChat) {
    return <CustomizedSpinner />;
  }

  const handleSubmit = () => {
    const data = {
      communication,
      knowledge,
      engagement,
      professionalism,
      raterId: myData.id,
      raterName: myData.name,
      rateeId: +id,
      text,
      chatId: userChatData.id,
    };

    updateRate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["userChat", id],
        });
        setIsRating(false);
        setCommunication(0);
        setKnowledge(0);
        setEngagement(0);
        setProfessionalism(0);
        setText("");
        toast.success("Rating submitted successfully");
      },
    });
  };

  if (
    isRating ||
    (userChatData
      ? userChatData?.ratingTime && userChatData.firstRate != myData.id
      : false)
  ) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div
          style={{
            backgroundColor: "#F8F8F8",
          }}
          className={`w-${width} p-2 shadow-md rounded-2 d-flex flex-column justify-content-center align-items-center gap-2`}
        >
          <div className="fw-bold">
            How would you evaluate your recent interaction with {name}?
          </div>
          <div className="d-flex flex-column align-items-start gap-3 col-sm-7 col-12 ">
            <OneRating
              handleVal={communication}
              id={1}
              topic="communication"
              handleFn={setCommunication}
            />
            <OneRating
              handleVal={knowledge}
              id={2}
              topic="knowledge"
              handleFn={setKnowledge}
            />
            <OneRating
              handleVal={engagement}
              id={3}
              topic="engagement"
              handleFn={setEngagement}
            />
            <OneRating
              handleVal={professionalism}
              id={4}
              topic="professionalism"
              handleFn={setProfessionalism}
            />

            <div className="d-flex justify-content-between w-100 align-items-center ">
              <div className="">Add Review</div>
              <div className="">
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      size="sm"
                      type="text"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      // onKeyPress={() => {}}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>

            <div className="d-flex justify-content-between w-100 ">
              <div className=""></div>
              <div className=" d-flex  justify-content-end ">
                {isPending ? (
                  <button
                    disabled={isPending}
                    className={`bg-primary text-white fs-sm border-0 rounded-3 py-1 px-3 col-sm-auto  col-12    `}
                  >
                    <img
                      src="/Rolling@1x-1.1s-200px-200px.svg"
                      alt="loading.."
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    ></img>
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className={`bg-primary text-white fs-sm border-0 rounded-3 py-1 px-3 col-sm-auto  col-12    `}
                  >
                    submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  }
};

export default RateComponent;
