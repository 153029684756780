import React from "react";
import AuthButton from "../components/AuthButton";
import { useGetMe } from "../hooks/useGetMe";
import { Link } from "react-router-dom";
import CustomizedSpinner from "./CustomizedSpinner";

// import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const { myData, isPending } = useGetMe();

  if (isPending) {
    return (
      <div>
        <CustomizedSpinner />
      </div>
    );
  }
  // const navigate = useNavigate();
  return (
    <div
      className=" overflow-y-auto overflow-x-hidden vh-100 "
      style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}
    >
      <div className="row gx-3 py-3  h-100">
        <div className="col-lg-12   h-100 ">
          <div
            className=" d-flex  flex-column   "
            style={{
              height: "100%",
            }}
          >
            <div className="logo mt-4  mx-auto mb-4">
              <img src={"/logo.png"} alt="logo" style={{ height: "28px" }} />
            </div>

            <div className=" d-flex  flex-grow-1 flex-column justify-content-center align-items-center">
              <div
                className="card "
                style={{
                  // minWidth: "40%",
                  backgroundColor: "white",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  boxShadow: "0px 30px 50px 0px rgba(0, 0, 0, 0.04)",
                  border: 0,
                  borderRadius: "10px",

                  height: "auto",
                }}
              >
                <div className="card-body p-sm-5 d-flex justify-content-center flex-column gap-2 align-items-center">
                  <img
                    src={"/errorPageImg.png"}
                    alt="emailverifiedSuccess"
                    className="w-25 opacity-75"
                  />
                  <h3>404 Not Found</h3>
                  <p
                    className="fs-sm text-center"
                    style={{
                      width: "60%",
                    }}
                  >
                    Please click Back to Homepage button.
                  </p>
                  <div
                    className="text-center"
                    style={{
                      width: "60%",
                    }}
                  >
                    <Link to={`/${myData.userType}/salary`}>
                      <AuthButton
                        label="Back to Homepage"
                        className="btn w-100 btn-primary mb-3 w-100 rounded-4 btn-lg fs-6 fw-medium"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mx-auto"
              style={{
                fontSize: "10px",
                color: "#333",
                marginTop: "15px",
              }}
            >
              <p>© {new Date().getFullYear()} CareerGPT. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

// import React from "react";

// const ErrorFallBack = ({ error, resetErrorBoundary }) => {
//   return (
//     <div className=" position-relative">
//       <div className="mt-5 d-flex flex-column justify-content-center align-items-center position-absolute top-40 start-40">
//         <h1 className="text-primary">Something went wrong</h1>
//         <p
//           style={{
//             color: "red",
//           }}
//         >
//           {error.message}
//         </p>
//         <button className=" rounded-3 text-danger" onClick={resetErrorBoundary}>
//           Try again
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ErrorFallBack;
