import { useQuery } from "@tanstack/react-query";

import { userChat } from "../services/candidateApi";

export const useOneChat = (id) => {
  const { data: userChatData, isPending } = useQuery({
    queryKey: ["userChat", id],
    queryFn: () => userChat(id),
  });

  return { userChatData, isPending };
};
