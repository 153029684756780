import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../services/api";
import UploadIcon from "../assets/icons/UploadIcon/arrowUpIcon.png";
import "./FileUpload.css";
import { getMe } from "../services/candidateApi";
import { useQuery } from "@tanstack/react-query";
import CustomizedSpinner from "../ui/CustomizedSpinner";
import { uploadFileToS3 } from "../services/uploadToS3";
import { toast } from "react-toastify";

import { useGetMe } from "../hooks/useGetMe";


const FileUpload = ({
  onUploadComplete = null,
  uploadStatus,
  id,
  uploadType,
}) => {
  const token = sessionStorage.getItem("authToken");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...Array.from(event.target.files)]);
    setIsFileUploaded(false);
  };

  const { myData, isPending: isMyData } = useGetMe();

  if (isMyData) return null;

  const handleUploadClick = async () => {
    if (!selectedFiles.length) {
      return;
    }
    const nonPdfFiles = selectedFiles.filter(file => file.type !== "application/pdf");
  if (nonPdfFiles.length > 0) {
    toast.error("You can upload only PDF files.");
    return;
  }

    setIsUploading(true);
    setUploadProgress(0);

    if (uploadType === "resumeTos3") {
      const file = selectedFiles[0];

      const fileType = file.name.split(".").pop();

      const newFile = new File([file], `${id}.${fileType}`, {
        type: file.type,
        lastModified: file.lastModified,
      });

      const url = await uploadFileToS3(
        newFile,
        `${myData.id}`,
        "resume",
        newFile.name
      );

      await axios.put(
        `${baseUrl}/candidates/updateResumeData`,
        {
          resume: url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      for (let file of selectedFiles) {
        try {
          const formData = new FormData();
          formData.append("file", file);

          const uploadURL = `${baseUrl}/files/upload-via-file`;

          await axios.post(uploadURL, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(
                (percentCompleted / 100 / selectedFiles.length) * 100
              );
            },
          });
        } catch (error) {
          console.error(`Error during upload for file ${file.name}: `, error);
        }
      }
    }

    setIsFileUploaded(true);

    if (onUploadComplete) {
      onUploadComplete(selectedFiles);
    }

    setIsUploading(false);

    setTimeout(() => {
      setSelectedFiles([]);
      setIsFileUploaded(false);
    }, 2000);
  };

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div style={{ marginLeft: "10px" }} className="">
      <div>
        <div className="d-flex align-items-center">
          {selectedFiles.length > 0 ? (
            <></>
          ) : (
            <>
              <input
                style={{ display: "none" }}
                type="file"
                accept=".pdf"
                id="formFile"
                multiple
                onChange={handleFileChange}
              />
            </>
          )}

          <div className="file-upload-container d-flex align-items-center flex-grow-1 mb-0">
            {selectedFiles.length > 0 && (
              <>
                <div className="d-flex flex-grow-1 align-items-center p-1">
                  <ul className="d-inline-block mb-0 flex-grow-1">
                    {selectedFiles.map((file, index) => (
                      <li
                        key={index}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{file.name}</span>
                          <button
                            className="btn btn-sm btn-primary mr-2"
                            onClick={() => handleRemoveFile(index)}
                            style={{
                              color: "white",
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          >
                            ❌
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            {selectedFiles.length > 0 ? (
              <button
                className={`upload-btn-chat ${isUploading ? "disabled" : ""}`}
                onClick={handleUploadClick}
              >
                <b>{isUploading ? "Uploading..." : "Upload"}</b>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        {isUploading && (
          <div className="progress" style={{ height: "5px", marginTop: "0px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${uploadProgress}%` }}
              aria-valuenow={uploadProgress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {/* {uploadProgress.toFixed(2)}% */}
            </div>
          </div>
        )}
        {isFileUploaded && (
          <div className="alert alert-success mt-2" role="alert">
            Files uploaded successfully!
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
