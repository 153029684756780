import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getMe, userChat } from "../services/candidateApi";
import { getOneRecruiter } from "../services/recruiterApi";

export const useUserConversation = (id) => {
  const { data: recruiterData, isPending } = useQuery({
    queryKey: ["recruiter/conversation", id],
    queryFn: () => getOneRecruiter({ id }),
  });

  return { recruiterData, isPending };
};
