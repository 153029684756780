import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../../css/UserCardSmall.css'; // Import the CSS file

function UserCardSmall({
  name,
  role,
  profilePic,
  country,
  experience,
  userId,
  theme,
  rating,
  userType,
}) {
  const [hovering, setHovering] = useState(false);

  return (
    <div className="col-md-6 p-1">
      <div
        className={`user-card d-flex rounded-3 p-3 col-12 ${hovering ? 'hover' : ''}`}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className="d-flex gap-3 w-100 align-content-center">
          <div>
            <Link to={`/${userType}/user/${userId}`}>
              <img
                src={profilePic}
                alt="profile"
                style={{ width: "40px", height: "40px" }}
                className="rounded-circle"
              />
            </Link>
          </div>
          <div className="justify-content-between w-100">
            <Link
              to={`/${userType}/user/${userId}`}
              className="justify-content-between w-100 text-black text-decoration-none"
            >
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="fw-medium align-items-center d-flex">
                  {name} (
                  {rating && rating > 0 ? (
                    <div className="d-flex align-items-center gap-1">
                      <img
                        src="/star (1).png"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "5px",
                        }}
                      />
                      {rating}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center gap-1">
                      <img
                        src="/star (1).png"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "5px",
                        }}
                      />
                      {0}
                    </div>
                  )}
                  )
                </div>
                {!hovering && (
                  <div
                    className="text-end align-items-center d-flex"
                    style={{ color: " #787979", fontSize: "14px" }}
                  >
                    {userType}
                  </div>
                )}
              </div>
              <div className="align-items-center d-flex w-100 justify-content-between">
                <div
                  className="align-items-center gap-2 d-flex"
                  style={{
                    color: " #3B3D3C",
                    fontSize: "14px",
                  }}
                >
                  {role}
                  <div
                    className="fw-light text-end align-items-center d-flex"
                    style={{ color: " #787979", fontSize: "12px" }}
                  >
                    {experience}
                  </div>
                </div>
                {!hovering && (
                  <div
                    className="fw-light text-end align-items-center d-flex"
                    style={{ color: " #787979", fontSize: "12px" }}
                  >
                    {country}
                  </div>
                )}
              </div>
            </Link>
          </div>
          {hovering && (
            <div
              className={`fw-medium text-${theme} justify-content-end d-flex align-items-center`}
            >
              <Link
                to={`startChat/${userId}`}
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                Start Chat
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserCardSmall;
