import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import AuthButton from "../AuthButton.js";
import { Link } from "../common";
import countryList from "react-select-country-list";

const RadioItem = ({ userType, setUserType, label, value }) => {
  return (
    <div
      className={`rounded-4 px-3 mb-1`}
      style={{
        height: "40px",
        display: "flex",
        alignItems: "center",

        // justifyContent: "center",
        backgroundColor: userType === value ? "#EEFBF3" : "#F8F8F8",
      }}
    >
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="userType"
          value={value}
          checked={userType === value}
          onChange={() => setUserType(value)}
        />
        <label
          className={`form-check-label fs-sm${
            userType === value ? "fw-medium" : ""
          } ml-2`}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

const PersonalDetailsSection = ({ onClick, init, loading, setCurrentPage }) => {
  const [userType, setUserType] = useState("");
  const [userTypeError, setUserTypeError] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryError, setIndustryError] = useState("");
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [countryError, setCountryError] = useState(""); // Add country error state
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(countryList().getData());
  }, []);
  useEffect(() => {
    setUserType(init.userType);
    setIndustry(init.industry);
    setCountry(init.country);
    setGender(init.gender);
  }, [init]);

  const validateForm = useCallback(() => {
    let isValid = true;
    // User type validation
    if (!userType) {
      setUserTypeError("Please select a user type");
      isValid = false;
    } else {
      setUserTypeError("");
    }

    // Industry validation
    if (!industry) {
      setIndustryError("Please select an industry");
      isValid = false;
    } else {
      setIndustryError("");
    }

    // Consent validation
    if (!consent) {
      setConsentError("You must consent to share your data before proceeding");
      isValid = false;
    } else {
      setConsentError("");
    }

    //country validation
    if (!country) {
      setCountryError("Please select a country");
      isValid = false;
    } else {
      setCountryError("");
    }

    if (!gender) {
      setGenderError("Please select a gender");
      isValid = false;
    } else {
      setGenderError("");
    }

    return isValid;
  }, [consent, country, industry, userType]);

  const userTypes = [
    { label: "Candidate", value: "candidate" },
    { label: "Recruiter/Hiring Manager", value: "recruiter" },
    { label: "Career Coach", value: "coach" },
  ];

  const industryOptions = [
    { label: "Select", value: "" },
    { label: "Information Technology", value: "IT" },
    { label: "Finance", value: "Finance" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Education", value: "Education" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Retail", value: "Retail" },
    { label: "Automotive", value: "Automotive" },
    { label: "Construction", value: "Construction" },
    { label: "Aerospace", value: "Aerospace" },
    { label: "Telecommunications", value: "Telecommunications" },
    { label: "Energy", value: "Energy" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
    { label: "Hospitality", value: "Hospitality" },
    { label: "Government", value: "Government" },
    { label: "Non-Profit", value: "Non-Profit" },
    { label: "Other", value: "Other" },
  ];

  const genderOption = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const disabled = useMemo(() => {
    return !userType || !industry || !consent || !country;
  }, [consent, country, industry, userType]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (validateForm()) {
        onClick({
          industry,
          country,
          userType,
          gender,
        });
      }
    },
    [country, industry, onClick, userType, gender, validateForm]
  );

  return (
    <div className=" fs-sm   ">
      <button className="border-0 bg-white" onClick={() => setCurrentPage(1)}>
        <IoArrowBack className="my-2" size={20} />
      </button>
      <h3 className="">Enter your personal details</h3>
      {/* <p className="fs-xs">Start your 30-days free trial</p> */}
      <div className="mb-2">
        <div className="d-flex justify-content-between">
          <label className="font-weight-bold mb-2">Select User Type:</label>
          {userTypeError && (
            <div className="text-danger fs-xs">{userTypeError}</div>
          )}
        </div>
        {userTypes.map((item, index) => {
          return (
            <RadioItem
              key={index}
              userType={userType}
              setUserType={setUserType}
              {...item}
            />
          );
        })}
      </div>

      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label className="font-weight-bold mb-2">Select Industry:</label>
          {industryError && (
            <div className="text-danger fs-xs">{industryError}</div>
          )}
        </div>
        <select
          className="form-select form-select-md fs-sm"
          value={industry}
          onChange={(e) => setIndustry(e.target.value)}
        >
          {industryOptions.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label className="font-weight-bold mb-2">Select Country:</label>
          {countryError && (
            <div className="text-danger fs-xs">{countryError}</div>
          )}
        </div>
        <select
          className="form-select fs-sm"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          <option value="">Select Country</option>
          {options.map((country) => (
            <option key={country.label} value={country.label}>
              {country.label}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label className="font-weight-bold mb-2">Select Gender:</label>
          {genderError && (
            <div className="text-danger fs-xs">{genderError}</div>
          )}
        </div>
        <select
          className="form-select fs-sm"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        >
          <option value="">Select Gender</option>
          {genderOption.map((gender) => (
            <option key={gender.label} value={gender.label}>
              {gender.label}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-3">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="consent"
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
          />
          <label
            className="form-check-label font-weight-bold ml-2 fs-sm"
            htmlFor="consent"
          >
            I consent to share my data with this platform
          </label>
        </div>
        {consentError && <div className="text-danger">{consentError}</div>}
      </div>
      <div className="text-center">
        <AuthButton
          onClick={handleSubmit}
          label={loading ? "Loading..." : "Continue"}
          // type="submit"
          className="btn btn-info mb-1 w-100 rounded-4 btn-lg fs-6 fw-medium"
          disabled={loading}
          style={{
            background: disabled ? "#F8F8F8" : "#29B475",
            color: disabled ? "#000000" : "#ffffff",
            border: "none",
          }}
        />
      </div>
      <p className="text-center">
        Already a user? <Link to="/signin" label={"Sign in"} />
      </p>
    </div>
  );
};
export default PersonalDetailsSection;
