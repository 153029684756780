import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
// import UserCardSmall from "./UserCardSmall";
// import ActiveUserCard from "./ActiveUserCard";
import { useQuery } from "@tanstack/react-query";
import { getAllRecruiter } from "../../services/candidateApi";
import Spinner from "../../ui/Spinner";
import ActiveUserCard from "../../pages/connectToProfessionals/ActiveUserCard";
import UserCardSmall from "../../pages/connectToProfessionals/UserCardSmall";
import OneChat from "./OneChat";
import { getUserChat } from "../../services/userApi";
import getSocketInit from "../../socket";
import OneMobileChat from "./OneMobileChat";
import CustomizedSpinner from "../../ui/CustomizedSpinner";

function UserMobileChat({ setId }) {
  const socket = getSocketInit();
  socket.connect();

  // const [searchKeyword, setSearchKeyword] = useState("");
  // const handleSearch = () => {
  //   console.log(searchKeyword);
  // };

  const { data: userChat, isPending: loadingUserChat } = useQuery({
    queryKey: ["userChat"],
    queryFn: getUserChat,
  });

  useEffect(() => {
    const handleCreateChat = (toId, data) => {
      // setMessages(prevMessages => [...prevMessages, data]);
    };

    socket.on("create-chat", handleCreateChat);

    return () => {
      socket.off("create-chat", handleCreateChat);
      socket.disconnect();
    };
  }, [socket]);

  if (loadingUserChat) {
    return <CustomizedSpinner />;
  }

  return (
    <div
      style={{
        height: "100%",
        // width: "100%",
      }}
      className=" overflow-hidden d-sm-flex justify-content-sm-center  mt-md-3 mt-sm-5   w-100   "
    >
      <div className="mx-3 d-flex mt-2  w-100 flex-column justify-content-start">
        <div className=" fw-medium fs-5 mt-3 "> All Message</div>
        <div
          style={{
            maxHeight: "80%",
            width: "85%",
            // backgroundColor: "#000",
          }}
          className="mt-2 flex-column overflow-y-auto  d-sm-flex "
        >
          {userChat && userChat.length > 0 ? (
            userChat?.map((chat) => (
              <OneMobileChat
                key={chat.id}
                name={chat.userName}
                profilePic={chat.userImg}
                userId={chat.userId}
                lstMsg={chat.lstMsg}
                lastMsgUserName={chat.lastMsgUserName}
                lastMsgCreateAt={chat.lastMsgCreateAt}
                setId={setId}
              />
            ))
          ) : (
            <div>No chat</div>
          )}
        </div>
      </div>

      {/* <div className=" col-md-11 h-100  col-sm-10  col-xl-10 col-xxl-9  gap-3  d-grid ">
        <div className="  row fw-medium h-25 fs-5 bg-danger">All Message</div>

        <div
          style={{
            height: "80%",
          }}
          className="   bg-primary h-75 flex-column overflow-y-auto  d-sm-flex d-none"
        >
        
          {userChat?.map((chat) => (
            <OneChat
              name={chat.userName}
              profilePic={chat.userImg}
              userId={chat.id}
              lstMsg={chat.lstMsg}
              lastMsgUserName={chat.lastMsgUserName}
            />
          ))}
          
        </div>
        <div className=" p-2 d-flex d-sm-none overflow-x-scroll  ">
          <UserCardSmall
            name="Arlene McCoy"
            role="Nursing Assistant"
            location="Fort Smith"
            experience="5Y exp."
            profilePic="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
            userId="2001"
          />

          <UserCardSmall
            name="Arlene McCoy"
            role="Nursing Assistant"
            location="Fort Smith"
            experience="5Y exp."
            profilePic="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
            userId="2001"
          />
          <UserCardSmall
            name="Arlene McCoy"
            role="Nursing Assistant"
            location="Fort Smith"
            experience="5Y exp."
            profilePic="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
            userId="2001"
          />
          <UserCardSmall
            name="Arlene McCoy"
            role="Nursing Assistant"
            location="Fort Smith"
            experience="5Y exp."
            profilePic="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png"
            userId="2001"
          />
        </div>
      </div> */}
    </div>
  );
}

export default UserMobileChat;
