import { Link as Href } from "react-router-dom";
const Link = ({ to, label }) => {
  return (
    <Href
      to={to}
      className="signup-link text-primary fw-medium text-decoration-none"
    >
      {label}
    </Href>
  );
};
export default Link;
