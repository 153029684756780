import React from "react";

const AuthButton = ({
  isLoading,
  label,
  className,
  onClick,
  disabled = false,
  type = "button",
  style,
}) => {
  return isLoading ? (
    <button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      <img
        src="/Rolling@1x-1.1s-200px-200px.svg"
        alt="loading.."
        style={{
          width: "20px",
          height: "20px",
        }}
      />
    </button>
  ) : (
    <button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {label}
    </button>
  );
};

export default AuthButton;
