import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setUserRating } from "../services/userApi";
import { toast } from "react-toastify";

export const useGivenRate = (id) => {
  const queryClient = useQueryClient();
  const { mutate: uploadRating, isPending } = useMutation({
    mutationFn: ({ rating }) => setUserRating({ rating }),
    onSuccess: () => {
      toast.success("Rating submitted successfully");

      queryClient.invalidateQueries({
        queryKey: ["recruiter", id],
      });
    },
  });

  return { uploadRating, isPending };
};
