import axios from "axios";
import { baseUrl } from "./api";
import { getToken } from "../util/utilityFunction";

export const getNotification = async () => {
  try {
    const token = await getToken();
    if (!token) return null;

    const response = await axios.get(`${baseUrl}/notification`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createNotification = async ({ data }) => {
  try {
    const response = await axios.post(`${baseUrl}/notification`, data);
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateNotification = async () => {
  console.log("updateNotification");
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = "/favicon.png";
  document.getElementsByTagName("head")[0].appendChild(link);

  const token = await getToken();
  if (!token) return null;
  try {
    const response = await axios.post(
      `${baseUrl}/recruiters/updateStatus`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data) {
      const data = response.data;
      return data;
    }
    return null;
    console.log("updateNotification");
  } catch (error) {
    console.log(error);
    return null;
  }
};
