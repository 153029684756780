import React, { useState } from "react";
import { Link } from "react-router-dom";
function MessageComponent({
  profilePic,
  name,
  messageDate,
  messageTime,
  messageData,
  admin,
}) {
  // console.log(profilePic, name, messageDate, messageTime, messageData);
  // console.log(name);

  const getFileName = (url) => {
    const urlParts = url.split("/");
    return urlParts[urlParts.length - 1];
  };

  return (
    <div className="py-3 ps-2 ">
      <div className="d-flex gap-3  w-100  align-content-center  ">
        {admin ? (
          <img
            src="/CareerGPT_Brand_Mark_Green.png"
            alt="profile"
            style={{
              width: "40px",
              height: "30px",
            }}
            className=" rounded-circle "
          />
        ) : (
          <img
            src={profilePic}
            alt="profile"
            style={{
              width: "40px",
              height: "40px",
            }}
            className=" rounded-circle "
          />
        )}

        <div className=" justify-content-between w-100">
          <div className="  d-flex justify-content-between  align-items-center w-100 ">
            <div className="fw-medium align-items-center d-flex">
              {admin ? "admin" : name}{" "}
            </div>

            {!admin && (
              <div
                className=" text-end  align-items-center  d-flex"
                style={{ color: " #787979    ", fontSize: "12px" }}
              >
                {`${messageDate}  ${messageTime}`}
              </div>
            )}
          </div>
          <div
            className="fw-light"
            style={{ color: "#3B3D3C", fontSize: "14px" }}
          >
            {messageData.startsWith("https") ? (
              <Link
                to={messageData}
                // style={{ textDecoration: "none", color: "#3B3D3C" }}
              >
                {getFileName(messageData)}
              </Link>
            ) : (
              messageData
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageComponent;
