import React, { useState, useEffect } from "react";
import ChatInterface from "../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import Trends from "../components/Trends";
import "../css/salary.css";
import { useQuery } from "@tanstack/react-query";
import { newsData } from "../services/candidateApi";
import CustomizedSpinner from "../ui/CustomizedSpinner";
import ChatInterfaceForNetworking from "../components/ChatInterfaceForNetworking";
import { useGetMe } from "../hooks/useGetMe";
import UserCardForMatching from "./connectToProfessionals/UserCardForMatching";
import LinkedInProfileCard from "./connectToProfessionals/LinkedInProfilesCard";

function RecruiterCandidateMatching() {
  const { myData, isPending: gettingMe } = useGetMe();
  const toggleViewAll = () => {
    setViewAllUsers(!viewAllUsers);
  };
  const toggleViewLinkedInProfiles = () => {
    setViewLinkedInProfiles(!viewLinkedInProfiles);
  };
  const { data: salaryNews, isLoading } = useQuery({
    queryKey: ["news", "salary news"],
    queryFn: newsData,
  });
  const [chatMessages, setChatMessages] = useState([
    {
      bot: "What would you like to search for? Candidates or Recruiters?",
    },
  ]);
  const [viewAllUsers, setViewAllUsers] = useState(false);
  const [viewLinkedInProfiles, setViewLinkedInProfiles] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const closeNewsPanel = () => {
    setNewsPanelVisible(false);
    if (!uploadVisible) {
      const newMessages = [
        ...chatMessages,
        {
          bot: "Please select your resume in PDF form!",
        },
      ];
      setChatMessages(newMessages);
      hideNewsPanel();
      setUploadVisible(true);
    }
  };

  const [conversationHistory, setConversationHistory] = useState([]);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const [uploadVisible, setUploadVisible] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchCandidateIndustry, setSearchRecruiterIndustry] = useState("");
  const [searchType, setSearchType] = useState(""); // New state for determining if searching for recruiters or candidates
  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };

  const handleSearchingCandidate = async (input) => {
    const token = sessionStorage.getItem("authToken");
    const message = input.toLowerCase();

    setChatMessages([
      ...chatMessages,
      { user: message },
      { bot: "searching for candidates..." },
    ]);

    setLoading(true);

    try {
      const res = await axios.get(
        `${baseUrl}/search/searchCandidate?country=${message}&industry=${searchCandidateIndustry}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const searchResult = res.data;
      console.log(searchResult);

      const botReply = (
        <div>
          <div className="d-flex  justify-content-between mt-2 ">
            <div className=" fw-medium ">Best Match Candidates</div>
            {searchResult && searchResult.length > 4 && (
              <button
                className={`border-0 bg-body fw-medium text-${myData.theme} p-0`}
                style={{ fontSize: "14px" }}
                onClick={toggleViewAll}
              >
                {viewAllUsers ? "Close" : "View All"}
              </button>
            )}
          </div>

          <div className="row flex-row p-2 mb-3">
            {searchResult && searchResult.length ? (
              (viewAllUsers ? searchResult : searchResult.slice(0, 4)).map(
                (allUsers) => {
                  if (allUsers.id !== myData.id) {
                    return (
                      <UserCardForMatching
                        rating={allUsers.rating}
                        userType={allUsers.userType}
                        userId={allUsers.id}
                        key={allUsers.id}
                        name={allUsers.name}
                        role={allUsers.industry}
                        country={allUsers.country}
                        experience={
                          allUsers.experience
                            ? `${allUsers.experience}Y exp.`
                            : ""
                        }
                        profilePic={allUsers.image}
                        theme={myData.theme}
                      />
                    );
                  }
                  return null;
                }
              )
            ) : (
              <div className="col-12">No matching Candidates found.</div>
            )}
          </div>
        </div>
      );
      setChatMessages([
        ...chatMessages,
        { user: message },
        { bot: botReply },
        {
          bot: "Do you want to search more candidates according to another industry and country? (yes/no)",
        },
      ]);
    } catch (error) {
      console.log(error);
      setChatMessages([
        ...chatMessages,
        { bot: "Sorry, we could not search for Candidates for you" },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchingRecruiter = async (input) => {
    const token = sessionStorage.getItem("authToken");
    const message = input.toLowerCase();

    setChatMessages([
      ...chatMessages,
      { user: message },
      { bot: "searching for recruiters..." },
    ]);

    setLoading(true);

    try {
      const res = await axios.get(
        `${baseUrl}/search/searchRecruiter?country=${message}&industry=${searchCandidateIndustry}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const linkedInProfiles = await axios.get(
        `${baseUrl}/search/linkedin/recruitersProfiles?country=${message}&industry=${searchCandidateIndustry}&searchType=recruiter`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const linkedInProfilesData = linkedInProfiles.data.profiles;
      const searchResult = res.data;

      console.log(searchResult);
      console.log(linkedInProfilesData);

      const botReply = (
        <div>
          <div className="d-flex justify-content-between mt-2 ">
            <div className="fw-medium">Best Match Recruiters</div>
            {searchResult && searchResult.length > 4 && (
              <button
                className={`border-0 bg-body fw-medium text-${myData.theme} p-0`}
                style={{ fontSize: "14px" }}
                onClick={toggleViewAll}
              >
                {viewAllUsers ? "Close" : "View All"}
              </button>
            )}
          </div>

          <div className="row flex-row p-2 mb-3">
            {searchResult && searchResult.length ? (
              (viewAllUsers ? searchResult : searchResult.slice(0, 4)).map(
                (allUsers) => {
                  if (allUsers.id !== myData.id) {
                    return (
                      <UserCardForMatching
                        rating={allUsers.rating}
                        userType={allUsers.userType}
                        userId={allUsers.id}
                        key={allUsers.id}
                        name={allUsers.name}
                        role={allUsers.industry}
                        country={allUsers.country}
                        experience={
                          allUsers.experience
                            ? `${allUsers.experience}Y exp.`
                            : ""
                        }
                        profilePic={allUsers.image}
                        theme={myData.theme}
                      />
                    );
                  }
                  return null;
                }
              )
            ) : (
              <div className="col-12">No matching Recruiters found.</div>
            )}
          </div>

          <div className="d-flex justify-content-between mt-2 ">
            <div className="fw-medium">LinkedIn Profiles</div>
            {linkedInProfilesData && linkedInProfilesData.length > 4 && (
              <button
                className={`border-0 bg-body fw-medium text-${myData.theme} p-0`}
                style={{ fontSize: "14px" }}
                onClick={toggleViewLinkedInProfiles}
              >
                {viewLinkedInProfiles ? "Close" : "View All"}
              </button>
            )}
          </div>

          <div className="row flex-row p-2 mb-3">
            {linkedInProfilesData && linkedInProfilesData.length ? (
              (viewLinkedInProfiles
                ? linkedInProfilesData
                : linkedInProfilesData.slice(0, 4)
              ).map((profile, index) => (
                <LinkedInProfileCard
                  key={index}
                  fullName={profile.full_name}
                  linkedinProfileUrl={profile.linkedin_profile_url}
                  profilePic={profile.profile_pictures[2].url} // using medium-sized image
                  theme={myData.theme}
                />
              ))
            ) : (
              <div className="col-12">No matching LinkedIn Profiles found.</div>
            )}
          </div>
        </div>
      );

      setChatMessages([
        ...chatMessages,
        { user: message },
        { bot: botReply },
        {
          bot: "Do you want to search more recruiters according to another industry and country? (yes/no)",
        },
      ]);
    } catch (error) {
      console.log(error);
      setChatMessages([
        ...chatMessages,
        { bot: "Sorry, we could not search for Recruiters for you." },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const recruiterMatching = async (input) => {
    const message = input.toLowerCase();
    switch (step) {
      case 0:
        setChatMessages([
          ...chatMessages,
          {
            bot: "What would you like to search for? Candidates or Recruiters?",
          },
        ]);
        setStep(1);
        break;
      case 1:
        if (message === "candidates" || message === "recruiters") {
          setSearchType(message);
          setChatMessages([
            ...chatMessages,
            { user: message },
            {
              bot: `Please enter the industry related to the job title of the ${message} you are searching for (e.g., Technology, Healthcare, Finance).`,
            },
          ]);
          setStep(2);
        } else {
          setChatMessages([
            ...chatMessages,
            {
              bot: "Please enter either 'candidates' or 'recruiters'.",
            },
          ]);
        }
        break;
      case 2:
        if (!message) {
          setChatMessages([
            ...chatMessages,
            {
              bot: `Please enter the industry related to the job title of the ${searchType} you are searching for (e.g., Technology, Healthcare, Finance).`,
            },
          ]);
          return;
        }
        setSearchRecruiterIndustry(message);
        setChatMessages([
          ...chatMessages,
          { user: message },
          {
            bot: `Please enter the country you are searching for ${searchType}`,
          },
        ]);
        setStep(3);
        break;
      case 3:
        if (!message) {
          setChatMessages([
            ...chatMessages,
            {
              bot: `Please enter the country you are searching for ${searchType}`,
            },
          ]);
          return;
        }

        // Use the appropriate function based on user input
        if (searchType === "candidates") {
          handleSearchingCandidate(message);
        } else if (searchType === "recruiters") {
          handleSearchingRecruiter(message);
        }

        setStep(4);
        break;
      case 4:
        if (message.toLowerCase() === "yes") {
          setStep(2);
          setChatMessages([
            ...chatMessages,
            {
              bot: `Please enter the industry related to the job title of the ${searchType} you are searching for (e.g., Technology, Healthcare, Finance).`,
            },
          ]);
        } else {
          setChatMessages([
            ...chatMessages,
            {
              bot: "Thank you for using our service",
            },
          ]);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const updateMedia = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <>
      <div className="container-fluid salary--analysis mb-5 mt-3">
        <div className="row ">
          <div className="col-lg-7">
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <ChatInterfaceForNetworking
                    chatMessages={chatMessages}
                    onSendMessage={recruiterMatching}
                    loading={loading}
                    isMobile={isMobile}
                    files={files}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 salary-news" hidden={newsPanelVisible}>
            <Trends
              closePanel={closeNewsPanel}
              isLoading={isLoading}
              salaryNews={salaryNews}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruiterCandidateMatching;
