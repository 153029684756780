import React from "react";
import PropTypes from "prop-types";
import styles from "./../css/SubscriptionPlan.module.css";

import { useGetMe } from "../hooks/useGetMe";
import Star from "../ui/Star";

const SubscriptionPlanComponent = ({
  planType,
  price,
  details,
  isFree,
  icone,
  children,
}) => {
  const { myData, isPending } = useGetMe();

  if (isPending) return;

  const isCurrentPlan = myData.subscriptionPlan === planType.toLowerCase();

  return (
    <div className={`card ${styles.planCard} w-100`}>
      {isCurrentPlan && (
        <div className={` ${styles.currentStatus} w-100`}>

          <Star squer={isFree ? "squareFree" : "squarePro"} />

        </div>
      )}
      <div className={styles.planHeader}>
        <span className={styles.planType}>{planType}</span>
        <h2 className={styles.price}>{price}</h2>
      </div>

      <div className="d-flex  justify-content-center">
        <div className="ms-2 d-flex flex-column justify-content-center  gap-2 justify-content-center">
          {details.map((detail, index) => (
            <div key={index} className="d-flex gap-3  align-items-center   ">
              <img src={icone} style={{ width: "18px", height: "18px" }} />
              <div>{detail}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="card-body d-flex justify-content-center">{children}</div>
    </div>
  );
};

SubscriptionPlanComponent.propTypes = {
  planType: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string.isRequired,
  isFree: PropTypes.bool.isRequired,
};

export default SubscriptionPlanComponent;
