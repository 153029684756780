import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import SettingSideBar from "../pages/settings/SettingSideBar";
import SettingSideBarButton from "../pages/settings/SettingSideBarButton";

import { getMe } from "../services/candidateApi";
import CustomizedSpinner from "../ui/CustomizedSpinner";

function SettingLayout({ children }) {
  const location = useLocation();

  const { data: myData, isLoading } = useQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
  });

  if (isLoading) return <CustomizedSpinner />;

  const getPathnameLabel = () => {
    switch (location.pathname) {
      case `/${myData.userType}/setting/profile`:
        return "Profile";
      case `/${myData.userType}/setting/password`:
        return "Password";
      case `/${myData.userType}/setting/socialprofile`:
        return "Social Profile";
      case `/${myData.userType}/setting/resume`:
        return "Resume";
      case `/${myData.userType}/setting/deleteprofile`:
        return "Delete Profile";
      case `/${myData.userType}/setting/changetheme`:
        return "Theme";
      default:
        return "";
    }
  };

  return (
    <div className="d-sm-flex justify-content-sm-center mt-sm-5 pt-sm-4 px-4 w-100">
      <div className="col-sm-8">
        <div className="fs-4 fw-semibold d-none d-sm-block">Settings</div>
        <div className="d-flex mt-3 gap-4">
          <div
            className="overflow-hidden align-items-center d-flex justify-content-center rounded-circle"
            style={{ width: "64px", height: "64px" }}
          >
            <img
              className="rounded-circle"
              style={{
                width: "64px",
                height: "64px",
                objectFit: "cover",
                objectPosition: "25% 25%",
              }}
              src={
                myData?.image ||
                "https://robohash.org/set_set5/JohnDoe.png"
              }
              alt="setting"
            />
          </div>

          <div className="align-content-center">
            <div className="fs-5 fw-medium">
              {myData?.name}
              {myData && getPathnameLabel() ? `'s ${getPathnameLabel()}` : ""}
            </div>
            <div>{myData?.userType}</div>
          </div>
        </div>

        <div className="align-content-center mt-5 d-none d-sm-flex">
          <div>
            <SettingSideBar />
          </div>
          <div
            className="col-xl-10 col-9 col-xxl-7 overflow-y-auto container-fluid"
            style={{ maxHeight: "50vh" }}
          >
            {children}
          </div>
        </div>
        <div className="mt-3 w-100 col-12">
          <Dropdown className="d-sm-none">
            <Dropdown.Toggle
              className="text-black w-100 bg-white d-flex justify-content-between align-items-center font fs-sm"
              style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
            >
              {getPathnameLabel()}
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100 border-2">
              <Dropdown.Item>
                <SettingSideBarButton
                  text="Profile"
                  to={`/${myData.userType}/setting/profile`}
                />
              </Dropdown.Item>
              <Dropdown.Item>
                <SettingSideBarButton
                  text="Password"
                  to={`/${myData.userType}/setting/password`}
                />
              </Dropdown.Item>
              <Dropdown.Item>
                <SettingSideBarButton
                  text="Social Profile"
                  to={`/${myData.userType}/setting/socialprofile`}
                />
              </Dropdown.Item>
              <Dropdown.Item>
                <SettingSideBarButton
                  text="Resume"
                  to={`/${myData.userType}/setting/resume`}
                />
              </Dropdown.Item>
              <Dropdown.Item>
                <SettingSideBarButton
                  text="Theme"
                  to={`/${myData.userType}/setting/changetheme`}
                />
              </Dropdown.Item>
              <Dropdown.Item>
                <SettingSideBarButton
                  text="Delete Profile"
                  to={`/${myData.userType}/setting/deleteprofile`}
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-sm-none overflow-auto pt-3">{children}</div>
      </div>
    </div>
  );
}

export default SettingLayout;
