import React, { useEffect } from 'react'
import getSocketInit from '../socket';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChatSocketHandler({ messages, setMessages, selectedUserForChat, userType, onChatPage, setIsNewChat }) {
    userType = userType == "candidate" ? "recruiter" : "candidate";
    const socket = getSocketInit();
    socket.connect();
    const audio = new Audio("/notification-sound.mp3");

    //useEffect for socket message listining
    useEffect(() => {
        // Add event listeners or other socket.io logic here
        socket.on(userType + ' message' + localStorage.getItem("userId"), (message) => {
            let messageByUserId = userType == "candidate" ? message.candidateId : message.recruiterId;
            if (!selectedUserForChat || !onChatPage || (selectedUserForChat && selectedUserForChat.id != messageByUserId)) {
                showNotification(message);
            }
            if (!onChatPage) {
                setIsNewChat(true);
            }
            setMessages([...messages, message]);
        });

        return () => {
            // Clean up event listeners if necessary
            socket.off(userType + ' message' + localStorage.getItem("userId"));
        };
    }, [messages, setMessages]);

    const showNotification = (message) => {
        let messageByUserName = userType == "candidate" ? message.candidateName : message.recruiterName;
        let chatMessage = messageByUserName + ": " + message.message.substr(0, 20) + (message.message.length > 20 ? "..." : "");
        toast.success(chatMessage, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "light",
            icon: () => (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: "25px", height: "25px" }}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                    />
                </svg>
            ),
        });
        try {
            audio.play();
        } catch (error) {
        }
    }

    return (
        <React.Fragment>
            <ToastContainer />
        </React.Fragment>
    )
}

export default ChatSocketHandler