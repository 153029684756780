import React, { useState } from "react";
import AuthButton from "../components/AuthButton.js";
import axios from "axios";
import { baseUrl } from "../services/api.js";

const FeedbackForm = ({ userType }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const token = sessionStorage.getItem("authToken");

  const validateForm = () => {
    let formErrors = {};

    if (!name.trim()) {
      formErrors.name = "Name is required";
    }

    if (!email.trim()) {
      formErrors.email = "Email is required";
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      formErrors.email = "Email is not valid";
    }

    if (!feedback.trim()) {
      formErrors.feedback = "Feedback is required";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    const token = sessionStorage.getItem("authToken");
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    const data = {
      name,
      email,
      feedback,
    };

    await axios
      .post(`${baseUrl}/feedback`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res);
        setIsSubmitted(true);
      });
  };

  return (
    <div
      className="image-wrapper d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/robotfeedback.jpg)`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "50px",
      }}
    >
      <div
        className="card"
        style={{
          width: "480px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <div className="card-body" style={{ padding: "20px" }}>
          <h2 className="card-title text-center mb-4">
            We Value Your Feedback
          </h2>
          {isSubmitted && (
            <div className="alert alert-success" role="alert">
              Thank you for submitting your feedback!
            </div>
          )}
          <div className="mb-3">
            <label className="form-label" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
            />
            {errors.name && (
              <div className="form-text text-danger">{errors.name}</div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            {errors.email && (
              <div className="form-text text-danger">{errors.email}</div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="feedback">
              Feedback
            </label>
            <textarea
              id="feedback"
              className="form-control"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Enter your feedback here"
            />
            {errors.feedback && (
              <div className="form-text text-danger">{errors.feedback}</div>
            )}
          </div>
          <div className="text-center">
            <AuthButton
              label="Submit Feedback"
              onClick={handleSubmit}
              className="btn btn-primary mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;
