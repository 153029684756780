import React, { useState } from "react";
import AuthButton from "../components/AuthButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { resetNewPassword } from "../services/candidateApi";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
const ResetPassword = () => {
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  // const { mutation: setNewPassword, isPending } = useMutation({
  //   mutationFn: ({ id, email }) => resetNewPassword(id, email),
  //   onSuccess: (data) => {
  //     toast.success("Password changed successfully");
  //     navigate(`/signin`);
  //   },
  //   onError: (error) => {
  //     toast.error("Error during changing password : ", error);
  //   },
  // });

  const validateForm = () => {
    let isValid = true;
    if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters long");
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("password is not match with confirm password");
      isValid = false;
    } else {
      setPasswordError("");
      setConfirmPasswordError("");
    }

    return isValid;
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      // console.log(id, password);
      const data = await resetNewPassword({ id, password });

      // console.log(data);
      if (data == "New password cannot be same as old password") {
        toast.error("New password cannot be same as old password");
        setConfirmPassword("");
        setPassword("");
        return;
      }

      if (data == "password changed successfully") {
        toast.success("Password changed successfully");
        setConfirmPassword("");
        setPassword("");
        navigate(`/signin`);
      }
    }
  };
  const handleChangePassword = (event) => {
    setPassword(event);
    setPasswordError("");
  };
  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event);
    setConfirmPasswordError("");
  };
  return (
    <div className="p-lg-3 ">
      <h3>Create New Password</h3>
      <p className="fs-xs">
        Your new password must be different from your previous one.
      </p>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label
            style={{ marginBottom: "8px" }}
            className="fs-xs fw-medium"
            htmlFor="email"
          >
            Enter Password
          </label>
          {passwordError && (
            <div style={{ color: "#F04438" }} className=" fs-xs">
              {passwordError}
            </div>
          )}
        </div>
        <div className="input-group mb-3 input-password">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            className="p-2  rounded-4 fs-sm w-100 shadow-none"
            value={password}
            onChange={(e) => handleChangePassword(e.target.value)}
            placeholder="Enter your password"
            onFocus={(e) => (e.target.style.border = `1px solid {#9D9E9D}`)}
            onBlur={(e) => (e.target.style.border = "1px solid #DADBDA")}
            style={
              passwordError
                ? {
                    outline: "none",
                    border: `1px solid #F04438`,
                  }
                : {
                    outline: "none",
                    border: `1px solid #DADBDA`,
                  }
            }
          />
          <div className="input-group-append">
            <span
              className="input-group-text bg-transparent border-0"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </span>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label
            style={{ marginBottom: "8px" }}
            className="fs-xs fw-medium"
            htmlFor="password1"
          >
            Confirm Password
          </label>

          {confirmPasswordError && (
            <div style={{ color: "#F04438" }} className=" fs-xs">
              {confirmPasswordError}
            </div>
          )}
        </div>
        <div className="input-group mb-3 input-password">
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="password1"
            className="p-2  rounded-4 fs-sm w-100 shadow-none"
            value={confirmPassword}
            onChange={(e) => handleChangeConfirmPassword(e.target.value)}
            placeholder="Enter your password"
            onFocus={(e) => (e.target.style.border = `1px solid {#9D9E9D}`)}
            onBlur={(e) => (e.target.style.border = "1px solid #DADBDA")}
            style={
              confirmPasswordError
                ? {
                    outline: "none",
                    border: `1px solid #F04438`,
                  }
                : {
                    outline: "none",
                    border: `1px solid #DADBDA`,
                  }
            }
          />
          <div className="input-group-append">
            <span
              className="input-group-text bg-transparent border-0"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
              />
            </span>
          </div>
        </div>
      </div>

      <div className="text-center">
        <AuthButton
          label="Confirm"
          onClick={handlePasswordReset}
          className={`mb-3 p-2 w-100 rounded-4 btn-lg fs-6 fw-medium`}
          style={{
            background:
              password === "" || confirmPassword === "" ? "#F8F8F8" : "#29B475",
            color:
              password === "" || confirmPassword === "" ? "#000000" : "#ffffff",
            border: "none",
          }}
        />
      </div>
      <div
        style={{
          fontSize: "14px",
        }}
      >
        {" "}
        If you want to <Link to="/signin">sign in</Link>
      </div>
    </div>
  );
};
export default ResetPassword;
