import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getMe, getMyRate, getUserRate } from "../services/candidateApi";

export const useGetUserRate = (id) => {
  const { data: userRate, isPending } = useQuery({
    queryKey: ["userRate", id],
    queryFn: () => getUserRate(id),
  });

  return { userRate, isPending };
};
