import React from "react";
import { createChat, createConversation } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateConversation = (id) => {
  const queryClient = useQueryClient();
  const { mutate: uploadMessage, isPending } = useMutation({
    mutationFn: ({ conversationData }) =>
      createConversation({ conversationData }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["recruiter", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["userChat"],
      });
    },
  });

  return { uploadMessage, isPending };
};
