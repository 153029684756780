import React from "react";

const NoClickChat = ({ text }) => {
  return (
    <div
      className=" rounded-1 position-relative overflow-hidden  "
      style={{
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          top: "40%",
          left: "40%",
        }}
        className="  position-absolute  d-flex flex-column justify-content-center align-items-center"
      >
        <img
          className="mb-2"
          src="/CareerGPT_Brand_Mark_Green.png"
          alt="logo"
          style={{ height: "28px" }}
        />
        <div
          style={{
            backgroundColor: "#e4e4e4",
          }}
          className=" rounded-4 p-1 px-4 fw-bold"
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default NoClickChat;
