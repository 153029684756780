import React from "react";
import styles from "./../../css/Button.module.css";
import { Link } from "react-router-dom";
import { useGetMe } from "../../hooks/useGetMe";
import CustomizedSpinner from "../../ui/CustomizedSpinner";

// import { CHATLIMIT } from "../../App";
const SenderDetails = ({ recruiterData, userChatData }) => {
  const { myData, isPending } = useGetMe();

  if (isPending) {
    return (
      <div>
        <CustomizedSpinner />
      </div>
    );
  }
  // console.log(userChatData);
  // console.log(userChatData.chatLimit);
  // const CHATLIMIT = myData.subscriptionPlan == "pro" ? 50 : 3;

  return (
    <div
      style={{
        backgroundColor: "#F8F8F8",
        width: "100%",
        // height: "50%",
      }}
      className="shadow-sm  d-flex rounded-3 p-3 "
    >
      <div className="d-flex w-100 justify-content-between">
        <div>
          <div className="d-flex gap-3   align-content-center  ">
            <div>
              <Link
                to={`/${myData.userType}/user/${recruiterData.recruiterData.id}`}
              >
                <img
                  src={recruiterData.recruiterData.image}
                  alt="profile"
                  style={{ width: "40px", height: "40px" }}
                  className=" rounded-circle "
                />
              </Link>
            </div>

            <div className=" justify-content-between w-100">
              <div className="  d-flex justify-content-between  align-items-center w-100 ">
                <Link
                  className="text-decoration-none text-black"
                  to={`/${myData.userType}/user/${recruiterData.recruiterData.id}`}
                >
                  <div className="fw-medium align-items-center d-flex">
                    {recruiterData.recruiterData.name}
                  </div>
                </Link>
              </div>
              <div className=" align-items-center d-flex w-100 justify-content-between  ">
                <div
                  className=" align-items-center d-flex "
                  style={{
                    color: " #3B3D3C",
                    fontSize: "14px",
                  }}
                >
                  {recruiterData.recruiterData.industry}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {userChatData && userChatData.chatLimit >= CHATLIMIT && ( */}
        <div>
          <div
            style={{
              fontSize: "14px",
              color: "#3B3D3C",
            }}
            className="d-flex align-items-end flex-column "
          >
            {userChatData?.ratingTime ? (
              <div className="text-danger w-75">
                The conversation will resume once the other user has also
                provided their rating.
              </div>
            ) : (
              <>
                <div> {recruiterData.recruiterData.userType}</div>
                <div>{recruiterData.recruiterData.country}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SenderDetails;
