import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getMe, getOneUser } from "../services/candidateApi";

export const useGetOneUser = (id) => {
  const { data: userData, isPending } = useQuery({
    queryKey: ["oneUser", id],
    queryFn: () => getOneUser({ id }),
  });

  return { userData, isPending };
};
