import React, { useEffect, useRef, useState } from "react";

import MessageComponent from "./MessageComponent";
import { Link, useParams } from "react-router-dom";
import UploadIcon from "../../assets/icons/Upload-Icon-small.png";
import SendIcon from "../../assets/icons/send-icon.png";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createChat,
  createConversation,
  getMe,
  userChat as userChatApi,
  updateChat as updateChatApi,
} from "../../services/candidateApi";
import Spinner from "../../ui/Spinner";
import { getOneRecruiter } from "../../services/recruiterApi";
import getSocketInit from "../../socket";
import Rating from "react-rating";
import { toast } from "react-toastify";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
// import CustomizedSpinner from "../../ui/CustomizedSpinner";
import { setUserRating } from "../../services/userApi";

import { GrUploadOption } from "react-icons/gr";
import { LuSendHorizonal } from "react-icons/lu";
import { FaRegStopCircle } from "react-icons/fa";
import { uploadFileToS3 } from "../../services/uploadToS3";
import { useGetMe } from "../../hooks/useGetMe";
import NoClickChat from "../../features/chat/NoClickChat";
import SenderDetails from "../../features/chat/SenderDetails";
import Conversation from "../../features/chat/Conversation";
import TypeBox from "../../features/chat/TypeBox";
import { useUserConversation } from "../../hooks/useUserConversation";
import { useOneChat } from "../../hooks/useOneChat";
import RateComponent from "../../features/chat/Rating";
import RateComponentForMobile from "../../features/chat/RateComponentForMobile";
import { useSocket } from "../../context/SocketContext";
// const CHATLIMIT = 3;

function StartChat() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [cvUploading, setCvUploading] = useState(false);
  const { socket } = useSocket();
  const [chatId, setChatId] = useState("");
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isRating, setIsRating] = useState(false);
  const [rate, setRate] = useState(0);
  const queryClient = useQueryClient();
  const params = useParams();
  const id = params.id;
  const scrollableContainerRef = useRef(null);

  const { myData, isPending } = useGetMe();
  const { userChatData, isPending: isUserChat } = useOneChat(id);
  const { recruiterData, isPending: loadingRecruiterData } =
    useUserConversation(id);

  useEffect(() => {
    const handleCreateConversation = (toId, data) => {
      if (!myData) return;
      if (toId == myData.id) {
        setConversation((conversation) => [...conversation, data]);
      }
    };

    socket.on("create-conversation", handleCreateConversation);

    return () => {
      socket.off("create-conversation", handleCreateConversation);
    };
  }, [socket]);

  useEffect(() => {
    if (recruiterData) {
      setConversation(recruiterData.conversationData?.details || []);
    }
  }, [recruiterData]);

  if (loadingRecruiterData || loadingRecruiterData || isPending) {
    return <CustomizedSpinner />;
  }

  if (isUserChat) return <CustomizedSpinner />;
  if (recruiterData.recruiterData.length == 0) {
    return <NoClickChat text="This conversation is no longer available" />;
  }

  return (
    <div
      className="  d-sm-flex d-grid justify-content-sm-between    pt-sm-5  px-4 w-100 overflow-hidden   "
      style={{
        height: "100%",
      }}
    >
      <Link
        className="d-none d-sm-block"
        to={`/${myData.userType}/connect-to-professionals`}
        style={{
          fontSize: "14px",
          cursor: "pointer",
          textDecoration: "none",
          color: "#0A0C0B",
          fontWeight: "bold",
        }}
      >
        Back
      </Link>
      <div className="col-md-10 col-sm-10  col-xl-8 col-xxl-7  gap-3  d-grid ">
        <div className="  p-1">
          <Link
            className="d-sm-none"
            to={`/${myData.userType}/connect-to-professionals`}
            style={{
              fontSize: "14px",
              cursor: "pointer",
              textDecoration: "none",
              color: "#0A0C0B",
              fontWeight: "bold",
            }}
          >
            Back
          </Link>
          <SenderDetails
            recruiterData={recruiterData}
            userChatData={userChatData}
          />
        </div>
        <div className="h-100 d-grid align-content-end overflow-auto  align-self-end  ">
          <Conversation
            conversation={conversation}
            isRating={isRating}
            cvUploading={cvUploading}
            setIsRating={setIsRating}
            recruiterData={recruiterData}
          />

          {userChatData ? (
            !userChatData.ratingTime && (
              <TypeBox
                userChatData={userChatData}
                isRating={isRating}
                message={message}
                id={id}
                setCvUploading={setCvUploading}
                setConversation={setConversation}
                setMessage={setMessage}
                setIsRating={setIsRating}
              />
            )
          ) : (
            <TypeBox
              userChatData={userChatData}
              isRating={isRating}
              message={message}
              id={id}
              setCvUploading={setCvUploading}
              setConversation={setConversation}
              setMessage={setMessage}
              setIsRating={setIsRating}
            />
          )}
        </div>
      </div>
      <div className="  d-none d-sm-block "></div>
    </div>
  );
}

export default StartChat;
