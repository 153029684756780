import React from "react";
import "./Trends.css";

const TrendesAllNews = ({ data, title }) => {
  const trendsData = data.length
    ? data
    : [
        // Fallback array in case data isn't provided
        {
          title: "Trend 1",
          description: "Description of Trend 1",
          url: "Source of Trend 1",
          source: { name: "Fallback Source 1" }, // Example source name
        },
        {
          title: "Trend 2",
          description: "Description of Trend 2",
          url: "Source of Trend 2",
          source: { name: "Fallback Source 2" }, // Example source name
        },
      ];

  return (
    <div className="p-0 justify-content-between w-100 trends-style ">
      {/* <h2 className="trends-title">{title}</h2> */}
      <div className="container  w-100 ">
        <div className="row ">
          {trendsData.map((trend, index) => {
            if (!trend.author) return null;
            const position = index % 3; // This will cycle through 0, 1, 2, 3 for every set of four items

            if (position === 0) {
              // Layout for the first item in each group of four
              return (
                <a
                  href={trend.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <div className="trend p-3 mb-3 col-12">
                    <h5 className="trend-title">{trend.title}</h5>
                    <p className="trend-description">{trend.description}</p>
                  </div>
                </a>
              );
            } else if (position === 1) {
              // Layout for the second item
              return (
                <a
                  href={trend.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-3 col-md-6 col-12 p-0 left-news-card"
                  key={index}
                >
                  <div className="d-flex gap-2 align-items-center">
                    {trend.urlToImage && (
                      <img
                        src={trend.urlToImage}
                        style={{
                          width: "170px",
                          height: "100px",
                        }}
                      />
                    )}

                    <div className="trend p-3 me-2">
                      <h5 className="trend-title">{trend.title}</h5>
                      <p className="trend-description">{trend.description}</p>
                    </div>
                  </div>
                </a>
              );
            } else if (position === 2) {
              // Layout for the third item
              return (
                <a
                  href={trend.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-3 col-md-6 col-12 p-0 right-news-card"
                  key={index}
                >
                  <div className="d-flex gap-2 align-items-center">
                    {trend.urlToImage && (
                      <img
                        src={trend.urlToImage}
                        style={{
                          width: "170px",
                          height: "100px",
                        }}
                      />
                    )}

                    <div className="trend p-3 ms-1">
                      <h5 className="trend-title">{trend.title}</h5>
                      <p className="trend-description">{trend.description}</p>
                    </div>
                  </div>
                </a>
              );
            } else {
              // Layout for the fourth item (and potentially additional layouts for more items)
              // You can extend this else if structure to add more unique layouts for 4th, 5th items, etc.
              return (
                <a
                  href={trend.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <div className="d-flex gap-2 align-items-center">
                    {trend.urlToImage && (
                      <img
                        src={trend.urlToImage}
                        style={{
                          width: "170px",
                          height: "100px",
                        }}
                      />
                    )}

                    <div className="trend p-3 mb-3 col-12">
                      <h5 className="trend-title">{trend.title}</h5>
                      <p className="trend-description">{trend.description}</p>
                    </div>
                  </div>
                </a>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

TrendesAllNews.defaultProps = {
  data: [],
};

export default TrendesAllNews;
