import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getMe, getMyRate } from "../services/candidateApi";

export const useGetMyRate = () => {
  const { data: myRate, isPending } = useQuery({
    queryKey: ["myRate"],
    queryFn: getMyRate,
  });

  return { myRate, isPending };
};
