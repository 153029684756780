import axios from "axios";
import { baseUrl } from "./api";
import { getToken } from "../util/utilityFunction";

export const getUserChat = async () => {
  try {
    const token = await getToken();
    if (!token) return null;

    const response = await axios.get(
      `${baseUrl}/user-conversation/getOneuserConversation`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      // console.log(data.userChatExist);
      return data.userChatExist;
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const setUserRating = async ({ rating }) => {
  try {
    const response = await axios.post(`${baseUrl}/user-conversation/rating`, {
      userId: rating.userId,
      rating: rating.rating,
    });

    if (response.status >= 200 && response.status < 300 && response.data) {
      const data = response.data;
      if (data.message === "rating faild") {
        return null;
      }

      return data;
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
  // console.log(rating);
  // const data = { rating };
  // const token = sessionStorage.getItem("authToken");
};

export const getOneUserRating = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/rate/overall/${id}`);

    if (response.status >= 200 && response.status < 300 && response.data) {
      const data = response.data;
      if (data.message === "rating faild") {
        return null;
      }

      return data;
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateUserFirstSignIn = async (id) => {
  const token = await getToken();
  if (!token) return null;

  try {
    const response = await axios.get(`${baseUrl}/recruiters/firstSignIn`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status >= 200 && response.status < 300 && response.data) {
      const data = response.data;
      if (data) {
        return data;
        // return null;
      }
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
