import { Link,useLocation} from "react-router-dom"
function SettingSideBarButton({text,to}) {
    const location = useLocation();
    return (
        <div>
            
            <Link to={to} className={`d-flex align-items-center justify-content-between  fs-sm text-decoration-none  ${
            location?.pathname === to
              ? " fw-bold text-dark "
              : " fw-thin text-muted"
          }`}>
            {text}
            </Link>

        </div>
    )
}

export default SettingSideBarButton
