import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import SenderDetails from "./SenderDetails";
import NoClickChat from "./NoClickChat";
import Conversation from "./Conversation";
import TypeBox from "./TypeBox";
import { useGetMe } from "../../hooks/useGetMe";
import { useOneChat } from "../../hooks/useOneChat";
import { useUserConversation } from "../../hooks/useUserConversation";
import { useSocket } from "../../context/SocketContext";

export default function UserMobileConversation({ id, setId }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [cvUploading, setCvUploading] = useState(false);

  const { socket } = useSocket();
  const [chatId, setChatId] = useState("");
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isRating, setIsRating] = useState(false);
  const queryClient = useQueryClient();
  const [rate, setRate] = useState(0);

  const scrollableContainerRef = useRef(null);

  const { myData, isPending } = useGetMe();
  const { userChatData, isPending: isUserChat } = useOneChat(id);
  const { recruiterData, isPending: loadingRecruiterData } =
    useUserConversation(id);

  useEffect(() => {
    const handleCreateConversation = (toId, data) => {
      if (toId == myData.id) {
        setConversation((conversation) => [...conversation, data]);
      }
    };

    socket.on("create-conversation", handleCreateConversation);

    return () => {
      socket.off("create-conversation", handleCreateConversation);
    };
  }, [socket]);

  useEffect(() => {
    if (recruiterData) {
      setConversation(recruiterData.conversationData?.details || []);
    }
  }, [recruiterData]);

  if (loadingRecruiterData) {
    return <CustomizedSpinner />;
  }

  const handleBackButton = () => {
    setId("");
  };

  if (isUserChat) return <CustomizedSpinner />;
  if (recruiterData.recruiterData.length == 0) {
    return <NoClickChat text="This conversation is no longer available" />;
  }

  const CHATLIMIT = myData.subscriptionPlan == "pro" ? 50 : 3;

  return (
    <div
      className=" mt-md-3 mt-sm-5  d-sm-flex d-grid justify-content-sm-between     px-4 w-100 overflow-hidden   "
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        className="  d-grid   "
      >
        <div>
          <button
            onClick={handleBackButton}
            style={{
              fontSize: "14px",
              cursor: "pointer",
              textDecoration: "none",
              color: "#0A0C0B",
              fontWeight: "bold",
              backgroundColor: "#F8F8F8",
            }}
            className="mb-3 border-0 border-white"
          >
            Back
          </button>
          <SenderDetails
            recruiterData={recruiterData}
            userChatData={userChatData}
          />

          <Conversation
            conversation={conversation}
            isRating={isRating}
            cvUploading={cvUploading}
            setIsRating={setIsRating}
            recruiterData={recruiterData}
          />
        </div>
        {userChatData ? (
          !userChatData.ratingTime && (
            <TypeBox
              userChatData={userChatData}
              isRating={isRating}
              message={message}
              id={id}
              setCvUploading={setCvUploading}
              setConversation={setConversation}
              setMessage={setMessage}
              setIsRating={setIsRating}
            />
          )
        ) : (
          <TypeBox
            userChatData={userChatData}
            isRating={isRating}
            message={message}
            id={id}
            setCvUploading={setCvUploading}
            setConversation={setConversation}
            setMessage={setMessage}
            setIsRating={setIsRating}
          />
        )}
      </div>
    </div>
  );
}
