import React from "react";
import { createChat } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useSetFreePlan = () => {
  const queryClient = useQueryClient();
  const { mutate: getFreePlan, isPending } = useMutation({
    mutationFn: () => getFreePlan(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getMe"],
      });
    },
  });

  return { getFreePlan, isPending };
};
