import React, { useState, useEffect } from "react";
import axios from "axios";
import ChatInterface from "../../components/ChatInterface";
import Trends from "../../components/Trends";

import { newsData } from "../../services/coachApi";
import { baseUrl } from "../../services/api";
import { Typewriter } from "react-simple-typewriter";
import "../../css/ResumeReview.css"; // Ensure CSS is styled similarly, you might need to create or modify this file
import { useQuery } from "@tanstack/react-query";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
const token = sessionStorage.getItem("authToken");

const ResumeReviewCoach = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [awaitingNextAction, setAwaitingNextAction] = useState(false);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const [uploadVisible, setUploadVisible] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetching resume review news for coaches using React Query
  const { data: resumeNews, isLoading } = useQuery({
    queryKey: ["news", "resume news"],
    queryFn: newsData,
  });

  if (isLoading) {
    return <CustomizedSpinner />;
  }

  const handleFilesUploaded = (uploadedFiles) => {
    setFiles([...files, ...Array.from(uploadedFiles)]);
    setNewsPanelVisible(false);
    setUploadVisible(true);
    setChatMessages([
      ...chatMessages,
      {
        bot: "Thank you for uploading! Please specify the role you're summarizing.",
      },
    ]);
  };

  const handleConversation = async (description) => {
    const token = sessionStorage.getItem("authToken");
    if (awaitingNextAction) {
      handleYesNoResponse(description);
      return;
    }

    if (!description || description.length < 2) {
      setChatMessages([
        ...chatMessages,
        { bot: "Please enter a valid role description." },
      ]);
      return;
    }

    setLoading(true);
    setChatMessages([
      ...chatMessages,
      { bot: "Summarizing your resume and providing insights..." },
    ]);

    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("resume", file);
      });

      const response = await axios.post(
        `${baseUrl}/files/resume/coach/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const reviewData = response.data;
      const botReply = (
        <div className="job-info">
          {reviewData && (
            <>
              {Object.entries(reviewData).map(([title, description]) => (
                <div key={title}>
                  <h4>{title}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description
                        .replace(/\n/g, "<br/>")
                        .replace(/\*{2}(.*?)\*{2}/g, "<strong>$1</strong>"),
                    }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      );

      setChatMessages([...chatMessages, { bot: botReply }]);
      setLoading(false);
    } catch (error) {
      console.error("Error processing request:", error);
      setChatMessages([
        ...chatMessages,
        {
          bot: "We encountered an error while processing your request. Please check your connection and try again later",
        },
      ]);
      setLoading(false);
    }
  };

  const handleYesNoResponse = (userResponse) => {
    if (userResponse.toLowerCase() === "yes") {
      setFiles([]);
      setAwaitingNextAction(false);
      setChatMessages([
        ...chatMessages,
        { bot: "Please upload another document." },
      ]);
      setTimeout(() => setFiles([]), 100);
    } else if (userResponse.toLowerCase() === "no") {
      setChatMessages([
        ...chatMessages,
        { bot: "Thank you for your time. Good luck with your coaching!" },
      ]);
      setAwaitingNextAction(false);
    } else {
      setChatMessages([
        ...chatMessages,
        { bot: "Please respond with 'yes' or 'no'." },
      ]);
    }
  };

  return (
    <div
      className="  d-sm-flex justify-content-sm-center  pt-4 pt-xl-3 px-4 w-100  align-content-center   "
      style={
        {
          // minHeight: "83vh",
          // height: "87vh",
        }
      }
    >
      {/* overflow-y-scroll  */}
      <div
        className="  px-sm-5 gap-3 col-12   d-grid align-content-between  "
        style={{
          height: isMobile ? "83.8vh" : "85vh", //here
          overflowY: "auto", //here
        }}
      >
        <div
          className={`news-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={isMobile ? { height: "60%", overflowY: "scroll" } : null}
        >
          <div className="trendsPageTitle d-flex flex-row justify-content-between">
            <h5 className="trend-page-title d-md-block d-none">
              Resume Review News for Coaches
            </h5>
            <a
              onClick={() => setNewsPanelVisible(false)}
              className="resume-news-title-close-btn"
            >
              Close
            </a>
          </div>
          <Trends data={resumeNews} />
        </div>
        <div
          className="/chat-container  d-grid  "
          style={{
            flex: "2 1 0",
            marginBottom: "0px",
            height: newsPanelVisible ? "40%" : "100%",
          }}
        >
          <ChatInterface
           uploadVisible={uploadVisible}
           setUploadVisible={setUploadVisible}
            setChatMessages={setChatMessages}
            newsPanelVisible={isMobile && newsPanelVisible}
            messages={chatMessages}
            onNewMessage={handleConversation}
            onFilesUploaded={handleFilesUploaded}
            firstMsg={
              <Typewriter
                words={["Need help reviewing resumes?"]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ResumeReviewCoach;
