import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../services/api";
import AuthButton from "../components/AuthButton";
import { useNavigate } from "react-router-dom";

function EmailVerification() {
  const navigate = useNavigate();
  const { email } = useParams();

  useEffect(() => {
    async function emailVerified() {
      try {
        const res = await axios.put(`${baseUrl}/auth/emailVerified`, { email });
        if (res.status === 200) {
          console.log("Email verified successfully");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          console.log("Email not verified");
        } else {
          console.error("An error occurred:", error);
        }
      }
    }
    emailVerified();
  }, [email]);

  return (
    <div
      className="overflow-y-auto overflow-x-hidden vh-100"
      style={{ backgroundColor: "rgba(248, 248, 248, 1)" }}
    >
      <div className="row gx-3 py-3 h-100">
        <div className="col-lg-12 h-100">
          <div className="d-flex flex-column" style={{ height: "100%" }}>
            <div className="logo mt-4 mx-auto mb-4">
              <img src={"/logo.png"} alt="logo" style={{ height: "28px" }} />
            </div>

            <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
              <div
                className="card"
                style={{
                  backgroundColor: "white",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  boxShadow: "0px 30px 50px 0px rgba(0, 0, 0, 0.04)",
                  border: 0,
                  borderRadius: "10px",
                  height: "auto",
                }}
              >
                <div className="card-body p-sm-5 d-flex justify-content-center flex-column gap-2 align-items-center">
                  <img
                    src={"/success.png"}
                    alt="emailverifiedSuccess"
                    className="w-25 opacity-75"
                  />
                  <h3>Email verification success!</h3>

                  <p className="fs-sm text-center" style={{ width: "60%" }}>
                    Please click Back to Sign In button.
                  </p>
                  <div className="text-center" style={{ width: "60%" }}>
                    <AuthButton
                      label="Back to Sign In"
                      onClick={() => navigate("/signin")}
                      className="btn w-100 btn-primary mb-3 w-100 rounded-4 btn-lg fs-6 fw-medium"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mx-auto"
              style={{
                fontSize: "10px",
                color: "#333",
                marginTop: "15px",
              }}
            >
              <p>© {new Date().getFullYear()} CareerGPT. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
