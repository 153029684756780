import React from "react";
import "./../css/Star.css";

const Star = ({ squer }) => {
  return (
    <div className="main">
      <div className={`${squer} w-50 p-4 m-2 bg-primary  `}>
        <div
          className={`squareText   text-white
`}
        >
          current <br /> plan
        </div>
      </div>
    </div>
  );
};

export default Star;
