export function formatTimeAgo(timestamp) {
  const timeDiff = Date.now() - new Date(timestamp).getTime();
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}

export async function getToken() {
  let token = sessionStorage.getItem("authToken");

  for (let attempt = 0; attempt < 3 && !token; attempt++) {
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
    token = sessionStorage.getItem("authToken");
  }

  if (!token) return null;
  return token;
}
