import React from "react";
import { Link } from "react-router-dom";
import { useGetMe } from "../hooks/useGetMe";
import CustomizedSpinner from "./CustomizedSpinner";

const Lock = () => {
  const { myData, isPending } = useGetMe();

  if (isPending) {
    return <CustomizedSpinner />;
  }

  if (myData?.freeTrial || myData?.subscriptionPlan === "pro") {
    return null;
  }
  return (
    <div>
      {/* {localStorage.getItem("isPaid") !== "true" && ( */}
      <div
        className="paywall-overlay"
        style={{
          position: "absolute",
          top: 50,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255,255,255,0.7)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          zIndex: 10,
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            fontSize: "2em",
            color: "gray",
            textShadow: "2px 2px 2px rgba(0,0,0,0.2)",
            marginBottom: "10px",
          }}
        >
          &#128274;
        </div>
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.5)",
            padding: "10px 20px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p
            style={{
              color: "black",
              maxWidth: "80%",
              fontWeight: "bold",
              textShadow: "1px 1px 1px rgba(0,0,0,0.2)",
              margin: 0,
            }}
          >
            By paying a one-time fee of $5, you will gain access to our advanced
            resume analysis feature powered by Google PaLM. This tool will
            provide you with personalized resume guidance based on the role
            you're interested in. If you liked our salary information feature,
            please consider giving this feature a shot!
          </p>
        </div>
        <Link to={`/${myData.userType}/subscription-plan`}>
          <button
            // className="payment-btn"
            // onClick={handlePayClick}
            style={{
              marginTop: "1em",
              backgroundColor: "blue",
              color: "white",
              padding: "0.5em 1em",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            check subscription plan
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Lock;
