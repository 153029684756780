import React from "react";
import Trends from "../components/Trends";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../ui/Spinner";
import { newsData, newsDataAll } from "../services/candidateApi";
import CustomizedSpinner from "../ui/CustomizedSpinner";
import TrendesAllNews from "../components/TrendesAllNews";

const News = () => {
  const { data: allNews, isLoading } = useQuery({
    queryKey: ["news", "allnews"],
    queryFn: newsDataAll,
  });

  if (isLoading) return <CustomizedSpinner />;

  return (
    <div
      className="salary-page-container"
      style={{ height: "85vh", overflow: "scroll" }}
    >
      <div
        className=" home-container d-flex flex-column mt-3"
        style={{
          width: "90%",
          margin: "auto",
          justifyContent: "center",
        }}
      >
        <div className={`trends-container `} style={{}}>
          <div className="trendsPageTitle d-flex flex-row justify-content-between">
            <h5 className="trend-page-title d-md-block d-none">
              News (from NewsAPI.org)
            </h5>
            <h5 className="trend-page-title-min d-md-none d-block">
              Job Market News
            </h5>
          </div>
          <TrendesAllNews data={allNews} />
        </div>
      </div>
    </div>
  );
};

export default News;
