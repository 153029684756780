import React from "react";
import AuthButton from "../components/AuthButton";

// import { useNavigate } from "react-router-dom";
function ErrortTemp({ error, resetErrorBoundary }) {
  console.log("error", error.message);
  <div role="alert">
    <h2>Something went wrong:</h2>
    <pre>{error.message}</pre>
    <button onClick={resetErrorBoundary}>Try again</button>
    <AuthButton />
  </div>;
}

export default ErrortTemp;
