import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ChatInterface.css";
import botIcon from "../assets/icons/bot-icon.png";
import userIcon from "../assets/icons/user-icon.png";
import sendIcon from "../assets/icons/send-icon.png";

const ChatInterface = ({
  messages = [],
  onNewMessage = null,
  firstMsg = "",
  value = "",
  onChange,
  onKeyPress,
  examplePrompts = [], // Add examplePrompts prop
  firstMessageSent = false, // Add firstMessageSent prop
}) => {
  const chatWindowRef = useRef(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      const chatWindow = chatWindowRef.current;
      const shouldScroll = chatWindow.scrollHeight - chatWindow.clientHeight <= chatWindow.scrollTop + 1;
      if (shouldScroll) {
        chatWindow.scrollTop = chatWindow.scrollHeight; // Scroll to the bottom if user is already near the bottom
      }
    }
  }, [messages]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (onNewMessage && value.trim()) {
        onNewMessage(value);
      }
    },
    [onNewMessage, value]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit]
  );

  return (
    <div className="chat-interface d-flex flex-column">
      <div className="intro-message p-3" style={{ flexShrink: 0 }}>
        <p>{firstMsg}</p>
      </div>
      <div ref={chatWindowRef} className="chat-window flex-grow-1 overflow-auto" style={{ flexGrow: 1 }}>
        {messages.map((msg, index) => (
          <div key={index} className={`message d-flex mb-3 ${msg.user ? 'justify-content-end' : 'justify-content-start'}`}>
            <div className="message-content">
              {msg.user ? (
                <div className="d-flex align-items-center">
                  <p className="mb-0 me-2">{DOMPurify.sanitize(msg.user)}</p>
                  <img src={userIcon} alt="User" className="message-icon rounded-circle" />
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <img src={botIcon} alt="Bot" className="message-icon rounded-circle me-2" />
                  <div dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(msg.bot),
                  }} />
                </div>
              )}
            </div>
          </div>
        ))}
        {!firstMessageSent && ( // Conditionally render the example prompts
          <div className="example-prompts p-3">
            <h4>Example Prompts:</h4>
            <div className="d-flex flex-wrap justify-content-center">
              {examplePrompts.map((prompt, index) => (
                <button
                  key={index}
                  className="btn btn-outline-primary m-1"
                  onClick={() => onNewMessage(prompt)}
                >
                  {prompt}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit} className="chat-form mt-3">
        <div className="input-group">
          <textarea
            value={value}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            placeholder="Type your message here..."
            className="form-control chat-input"
            rows="3"
          />
          <button type="submit" className="btn btn-primary send-button">
            <img src={sendIcon} alt="Send" className="send-icon" />
          </button>
        </div>
      </form>
    </div>
  );
};

ChatInterface.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.string,
      bot: PropTypes.string,
    })
  ),
  onNewMessage: PropTypes.func,
  firstMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  examplePrompts: PropTypes.arrayOf(PropTypes.string), // Add prop type for examplePrompts
  firstMessageSent: PropTypes.bool, // Add prop type for firstMessageSent
};

export default ChatInterface;