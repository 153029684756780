import React from "react";
import { createChat, getProPlan } from "../services/candidateApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useSetProPlan = () => {
  const queryClient = useQueryClient();
  const { mutate: setProPlan, isPending } = useMutation({
    mutationFn: () => getProPlan(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getMe"],
      });
      toast.success("You have successfully switched to pro plan");
    },
  });

  return { setProPlan, isPending };
};
