import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomCarousel = ({ length, active, setActive }) => {
  return (
    <div className="d-flex flex-row gap-1 p-2 pt-4">
      {Array.from({ length }).map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faCircle}
          style={{
            fontSize: "6px",
            cursor: "pointer",
          }}
          onClick={() => setActive(index)}
          color={active === index ? "black" : "rgba(10, 12, 11, 0.3)"}
        />
      ))}
    </div>
  );
};
export default CustomCarousel;
