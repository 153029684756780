import React, { useCallback, useEffect, useMemo, useState } from "react";
import AuthButton from "../AuthButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "../common";
import { IoArrowBack } from "react-icons/io5";
const PasswordSection = ({ onClick, passwordInit,setCurrentPage }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    setPassword(passwordInit);
    setConfirmPassword(passwordInit);
  }, [passwordInit]);


  const validateForm = useCallback(() => {
    setPasswordError("");
      setConfirmError("");
    let isValid = true;
    // Password validation
    if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters long");
    
      isValid = false;
    } else if(confirmPassword !== password) {
      isValid = false;
      setConfirmError("Password does not match");
      
    }
    

    return isValid;
  }, [password, confirmPassword]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      onClick(
        {
          password: password,
        },
        2
      );
    }
  };

  const handlePasswordChange = (password) => {
    setPassword(password);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (password) => {
    setConfirmPassword(password);
    setConfirmError("");
  }
 
  return (
    <div className=" py-5" >
      <button className="border-0 bg-white" onClick={() =>setCurrentPage(0)}>
        <IoArrowBack className="my-2" size={20} />
      </button>
      
      <h3>Create your password</h3>
      {/* <p className="fs-sm">Start your 30-days free trial.</p> */}
      <div className="mb-3">
        <div className="d-flex justify-content-between">
        <label
          style={{ marginBottom: "8px" }}
          className="fs-xs fw-medium"
          htmlFor="email"
        >
          Password
        </label>
        {passwordError && (
          <div style={{color:'#F04438'}} className=" fs-xs">{passwordError}</div>
        )}
        </div>
        <div className="input-group mb-3 input-password">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            className={`p-2 w-100 shadow-none fs-sm 
             rounded-4`}
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            placeholder="Enter your password"
            onFocus={(e) => e.target.style.border = '1px solid #9D9E9D'}
            onBlur={(e) => e.target.style.border = '1px solid #DADBDA'} 
            style={passwordError?{
              outline: 'none',
              border: `1px solid #F04438`
            }:{
              outline: 'none',
              border: `1px solid #DADBDA`
            }}
          />
          <div className="input-group-append">
            <span
              className="input-group-text bg-transparent border-0"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </span>
          </div>
        </div>
        
      </div>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
        <label
          style={{ marginBottom: "8px" }}
          className="fs-xs fw-medium"
          htmlFor="password1"
        >
          Confirm Password
        </label>
        {confirmError && (
          <div className=" fs-xs" style={{color:'#F04438'}}>{confirmError}</div>
        )}
        </div>
        <div className="input-group mb-3 input-password">
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="password1"
            className={`p-2 w-100 shadow-none fs-sm 
            rounded-4`}
            value={confirmPassword}
            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
            placeholder="Re-Enter your password"
            style={confirmError ?{
              outline: 'none',
              border: `1px solid #F04438`
            }:{
              outline: 'none',
              border: `1px solid #DADBDA`
            }}
            onFocus={(e) => e.target.style.border = '1px solid #9D9E9D'}
            onBlur={(e) => e.target.style.border = '1px solid #DADBDA'} 
          />
          <div className="input-group-append">
            <span
              className="input-group-text bg-transparent border-0 "
              style={{
                cursor: "pointer",
              }}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
              />
            </span>
          </div>
        </div>

        
      </div>

      <div className="text-center">
        <AuthButton
          label="Continue"
          onClick={handleSubmit}
          className={`btn 
            
          mb-3 w-100 rounded-4 btn-lg fs-6 fw-medium`}
          style={{
            
            background: password === "" || confirmPassword ==="" ? "#F8F8F8" : "#29B475",
            color: password === "" || confirmPassword ==="" ? "#000000" : "#ffffff",
            border: 'none',
          }}
        />
      </div>
      <p className="text-center mb-0">
        Aready have an account? <Link to="/signin" label={"Sign in"} />
      </p>
    </div>
  );
};
export default PasswordSection;
