import React, { useState, useCallback } from "react";
import { LinkedIn, useLinkedIn } from "react-linkedin-login-oauth2";
import axios from "axios";
import { debounce } from "lodash";
import { baseUrl } from "../services/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./../css/LinkedInAuth.css"; // Import custom CSS
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

const LinkedInAuth = ({ logo }) => {
  const navigate = useNavigate();
  const [showUserTypeSelection, setShowUserTypeSelection] = useState(false);
  const [tempUserData, setTempUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccess = useCallback(
    debounce(async (data) => {
      if (loading) return;
      setLoading(true);
      try {
        const response = await axios.post(`${baseUrl}/auth/linkedin`, { data });
        const { token, user, isNewUser } = response.data;

        if (isNewUser || !user.userType) {
          setTempUserData({ token, user });
          setShowUserTypeSelection(true);
        } else {
          completeAuth(token, user);
        }
      } catch (error) {
        console.error("LinkedIn OAuth Error:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast.error(
            `Authentication failed: ${error.response.data.message || "Please try again."}`,
          );
        } else if (error.request) {
          // The request was made but no response was received
          toast.error(
            "No response from server. Please check your connection and try again.",
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error("An unexpected error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }, 300),
    [baseUrl, loading],
  );

  const completeAuth = (token, user, selectedUserType) => {
    sessionStorage.setItem("authToken", token);
    localStorage.setItem("userType", user.userType);
    localStorage.setItem("userId", user.id);
    localStorage.setItem("userName", user.name);
    localStorage.setItem("userObject", JSON.stringify({ token, user }));
    localStorage.setItem("isPaid", user.subscriptionPlan !== "free");

    // setUserInfo(user);
    console.log(user.userType);

    if (user.userType === "recruiter") {
      navigate("/recruiter/connect-to-professionals");
    } else if (user.userType === "coach") {
      navigate("/coach/connect-to-professionals");
    } else {
      navigate("/candidate/connect-to-professionals");
    }
  };

  const handleUserTypeSelection = async (selectedUserType) => {
    // setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/update-user-type`, {
        userId: tempUserData.user.id,
        userType: selectedUserType,
      });
      const updatedUser = response.data;
      console.log(updatedUser);
      completeAuth(tempUserData.token, updatedUser, selectedUserType);
    } catch (error) {
      console.error("Error updating user type:", error);
      toast.error("Failed to update user type. Please try again.");
    } finally {
      // setLoading(false);
    }
  };
  const handleFailure = (error) => {
    console.error("Linkdeln OAuth Error", error);
    toast.error("Linkedln Oauth  failed , Please Try Again");
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "78swfoqklfbkol",
    onFailure: handleFailure,
    onSuccess: handleSuccess,
    // redirectUri: redirectUri,
    redirectUri: `${window.location.origin}/linkedin`,

    scope: "openid profile email", // Request necessary scopes
  });

  return (
    <div className="linkedin-auth">
      {showUserTypeSelection ? (
        <UserTypeSelection
          onSelect={handleUserTypeSelection}
          show={showUserTypeSelection}
        />
      ) : (
        <button onClick={linkedInLogin} className="btn border btn-linkedin">
          <img src={logo} alt="LinkedIn" />
          Continue with LinkedIn
        </button>
      )}
    </div>
  );
};

const UserTypeSelection = ({ onSelect, show }) => {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <h2>Choose Your Role </h2>
          <button
            className="btn"
            style={{ backgroundColor: "#5fcf80", color: "white" }}
            onClick={() => onSelect("recruiter")}
          >
            Recruiter
          </button>
          <button
            className="btn"
            style={{ backgroundColor: "#5fcf80", color: "white" }}
            onClick={() => onSelect("coach")}
          >
            Coach
          </button>
          <button
            className="btn"
            style={{ backgroundColor: "#5fcf80", color: "white" }}
            onClick={() => onSelect("candidate")}
          >
            Candidate
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LinkedInAuth;
