import AWS from 'aws-sdk';

export const uploadFileToS3 = async (file, folderLocation, fileName) => {

    const S3_BUCKET = `${process.env.REACT_APP_S3_BUCKET_NAME}`;
    const REGION = `${process.env.REACT_APP_S3_BUCKET_REGION}`;

    AWS.config.update({
        accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
        secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
    });
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const params = {
        Bucket: S3_BUCKET,
        Key: `${folderLocation}/${fileName}`,
        Body: file,
    };

    var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
            console.log(
                "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
        })
        .promise();

    try {
        const res = await upload;
        const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folderLocation}/${fileName}`;
        return url;
    } catch (err) {
        throw new Error("Failed to upload file to S3");
    }
};