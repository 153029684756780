import React, { useState } from "react";
import LinkedInIcon from "../../assets/linkedin.png";

function LinkedInProfileCard({
  fullName,
  profilePicture,
  profileUrl,
  occupation,
  userType,
  theme,
}) {
  const [hovering, setHovering] = useState(false);

  return (
    <div className="col-md-6 p-1">
      <div
        style={{
          backgroundColor: hovering ? "#EEFBF3" : "#F8F8F8",
        }}
        className="rounded-3 p-3 col-12"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className="d-flex gap-3 w-100 align-content-center">
          <div className="position-relative">
            <a href={profileUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={profilePicture}
                alt="LinkedIn profile"
                style={{ width: "40px", height: "40px" }}
                className="rounded-circle"
              />
            </a>
            <img
              src={LinkedInIcon} // Ensure this path points to your LinkedIn icon
              alt="LinkedIn icon"
              style={{
                width: "15px",
                height: "15px",
                position: "absolute",
                bottom: "0",
                right: "0",
              }}
            />
          </div>

          <a
            href={profileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="justify-content-between w-100 text-black text-decoration-none"
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="fw-medium align-items-center d-flex">
                {fullName}
              </div>
            </div>
            <div className="text-muted">{occupation}</div>
          </a>

          {hovering && (
            <div
              className={`fw-medium text-${theme} justify-content-end d-flex align-items-center`}
            >
              <a
                href={profileUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                <div className="d-flex gap-1">
                  <div>View</div>
                  <div>Profile</div>
                </div>
              </a>
            </div>
          )}
        </div>
        <div className="text-muted small mt-2">{userType}</div>
      </div>
    </div>
  );
}

export default LinkedInProfileCard;
