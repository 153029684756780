import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";

function ThemeSelectingComponent({ text, themeColor,isSelected, setSelectedTheme}) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="col-2 my-2">
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setSelectedTheme(themeColor)}
        className={`bg-${themeColor} rounded d-flex justify-content-center align-items-center`}
        style={{
          width: "40px",
          height: "40px",
          cursor: "pointer",
          border: isHovered ? "4px solid #E6E7E7" : "2px solid transparent",
          transition: 'border 0.3s ease',
        }}
      >
        {isSelected && (<FaCheckCircle size={22} color="#f6f6f6" style={{
            backgroundColor: "rgba(0,0,0,0.8)",
            borderRadius: "50%",
            padding: "2px",
        }} />)}
      </div>
   
    </div>
  );
}

export default ThemeSelectingComponent;
