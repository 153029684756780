import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { updateProfileData } from "../../services/apiSetting";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getMe } from "../../services/candidateApi";
import { useQuery } from "@tanstack/react-query";
import { uploadFileToS3 } from "../../services/uploadToS3";
import Spinner from "../../ui/Spinner";
import countryList from "react-select-country-list";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import { useGetMe } from "../../hooks/useGetMe";
import { useUpdateProfileData } from "../../hooks/useUpdateProfileData";

function Profile() {
  const [tempName, setTempName] = useState("");
  const [tempImg, setTempImg] = useState("");
  const [tempLocation, setTempLocation] = useState("");
  const [tempBio, setTempBio] = useState("");
  const [tempWebsite, setTempWebsite] = useState("");
  const [tempSkill, setTempSkill] = useState("");
  const [skillsArr, setSkillsArr] = useState([]);
  const [tempStartTime, setTempStartTime] = useState("");
  const [tempEndTime, setTempEndTime] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [country, setCountry] = useState("");
  const [options, setOptions] = useState(countryList().getData());
  const [industry, setIndustry] = useState("");
  const industryOptions = [
    { label: "Select", value: "" },
    { label: "Information Technology", value: "IT" },
    { label: "Finance", value: "Finance" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Education", value: "Education" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Retail", value: "Retail" },
    { label: "Automotive", value: "Automotive" },
    { label: "Construction", value: "Construction" },
    { label: "Aerospace", value: "Aerospace" },
    { label: "Telecommunications", value: "Telecommunications" },
    { label: "Energy", value: "Energy" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
    { label: "Hospitality", value: "Hospitality" },
    { label: "Government", value: "Government" },
    { label: "Non-Profit", value: "Non-Profit" },
    { label: "Other", value: "Other" },
  ];
  const [experience, setExperience] = useState("");
  const experienceOptions = [
    { label: "Select", value: "" },
    { label: "0-1 years", value: "1" },
    { label: "1-3 years", value: "3" },
    { label: "3-5 years", value: "5" },
    { label: "5-10 years", value: "10" },
    { label: "10+ years", value: "20" },
  ];

  const { myData, isPending: isMyData } = useGetMe();

  useEffect(() => {
    setTempName(myData?.name);
    setTempImg(myData?.image);
    setTempLocation(myData?.location);
    setTempBio(myData?.bio);
    setTempWebsite(myData?.personalwebsite);
    setTempStartTime(myData?.availableTimeStart);
    setTempEndTime(myData?.availableTimeEnd);
    setSkillsArr(myData?.skill || []); //
    setCountry(myData?.country);
    setIndustry(myData?.industry);
    setExperience(myData?.experience);
  }, [myData]);

  const queryClient = useQueryClient();

  const { updateProfileDetails, isPending: updatingProfileData } =
    useUpdateProfileData();

  if (isMyData) return <CustomizedSpinner />;

  const updateProfile = async () => {
    try {
      const data = {
        name: tempName,
        bio: tempBio,
        location: tempLocation,
        personalwebsite: tempWebsite,
        image: tempImg,
        skill: skillsArr,
        availableTimeStart: tempStartTime,
        availableTimeEnd: tempEndTime,
        country: country,
        industry: industry,
        experience: experience,
      };

      // console.log("nice");
      updateProfileDetails({ data });
    } catch (e) {
      console.error("error in uploading image to s3", e);
    }
  };
  const handleFileChange = async (event) => {
    setIsImageUploading(true);
    try {
      const file = event.target.files[0];
      const fileType = file.name.split(".").pop();
      // console.log(file);

      const newFile = new File([file], `${myData.id}.${fileType}`, {
        type: file.type,
        lastModified: file.lastModified,
      });

      // const newFile = new File([createFile], `${id}.${fileType}`, {
      //   type: file.type,
      // });
      // console.log(newFile);

      const url = await uploadFileToS3(
        newFile,
        `${myData.id}`,
        "profileImages",
        newFile.name
      );
      // console.log(url);
      setIsImageUploading(false);
      setTempImg(url);
      // var reader = new FileReader();
      // reader.readAsDataURL(event.target.files[0]);
      // reader.onload = function () {
      //   console.log(reader.result);
      //   setIsImageChanged(true);
      // };
    } catch (e) {
      console.log(e);
    }
  };

  const addSkill = () => {
    if (skillsArr.length < 3) {
      if (tempSkill.length > 0) {
        setSkillsArr([...skillsArr, tempSkill]);
        setTempSkill("");
      }
    } else {
      toast.error("You can add only 3 skills");
    }
  };

  const removeSkill = (index) => {
    setSkillsArr(skillsArr.filter((_, i) => i !== index));
  };
  // const id = localStorage.getItem("userId");

  return (
    <div className=" ">
      <div className="d-flex flex-row  gap-4 w-100  ">
        <div
          className="overflow-hidden align-items-center d-flex justify-content-center rounded-circle"
          style={{
            width: "64px",
            height: "64px",
          }}
        >
          {isImageUploading ? (
            <img
              src="/Spinner@1x-1.0s-200px-200px.svg"
              style={{
                width: "7rem",
                height: "7rem",
              }}
            />
          ) : (
            <img
              className=" rounded-circle "
              style={{
                width: "64px",
                height: "64px",
                objectFit: "cover",
                objectPosition: "25% 25%",
                //
              }}
              alt="profilePic"
              src={
                tempImg ||
                myData?.image ||
                "https://robohash.org/set_set5/JohnDoe.png"
              }
            />
          )}
        </div>
        <div className=" align-content-center ">
          <label
            style={{
              cursor: "pointer",
            }}
            htmlFor="imageFile"
            className=" bg-info  fw-semibold border-0 py-2 px-4 rounded-4  "
          >
            Upload Image
          </label>
          <input
            style={{ display: "none" }}
            type="file"
            id="imageFile"
            multiple
            onChange={handleFileChange}
          />
        </div>
      </div>
      <div className="mt-3">
        <div className=" fs-xs  ">Name</div>

        <input
          className="w-100  mt-1 py-2   fs-sm px-2"
          value={tempName}
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setTempName(e.target.value)}
          onFocus={(e) => (e.target.style.border = "2px solid #525252")}
          onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
        />
      </div>
      <div className="mt-3">
        <div className=" fs-xs  "> Industry</div>

        <select
          className=" w-100  mt-1 py-2   fs-sm px-1"
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          onChange={(e) => setIndustry(e.target.value)}
          value={industry}
        >
          {industryOptions.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-3">
        <div className=" fs-xs  ">Country</div>
        <select
          className=" w-100  mt-1 py-2   fs-sm px-1"
          style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          <option value="">Select Country</option>
          {options.map((country) => (
            <option key={country.label} value={country.label}>
              {country.label}
            </option>
          ))}
        </select>
      </div>
      <div className="">
        <div className="mt-3">
          <div className=" fs-xs ">Location</div>

          <input
            className="w-100  mt-1 py-2   fs-sm px-2"
            value={tempLocation}
            style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
            onChange={(e) => setTempLocation(e.target.value)}
            onFocus={(e) => (e.target.style.border = "2px solid #525252")}
            onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
          />
        </div>
        <div className="mt-3">
          <div className=" fs-xs ">Bio</div>

          <textarea
            rows={5}
            className="w-100  mt-1 py-2 px-2  fs-sm"
            value={tempBio}
            style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
            onChange={(e) => setTempBio(e.target.value)}
            onFocus={(e) => (e.target.style.border = "2px solid #525252")}
            onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
          />
          <div className="fs-xs text-black-50 ">
            Brief description for your profile. URLs are hyperlinked.
          </div>
        </div>
        <div className="mt-3">
          <div className=" fs-xs ">Personal website</div>

          <input
            className="w-100  mt-1 py-2   fs-sm px-2"
            value={tempWebsite}
            style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
            onChange={(e) => setTempWebsite(e.target.value)}
            onFocus={(e) => (e.target.style.border = "2px solid #525252")}
            onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
          />
        </div>
        <div className="mt-3">
          <div className=" fs-xs ">Skills</div>

          <div className="input-group  input-password">
            <input
              className="w-100  mt-1 py-2   fs-sm px-2 "
              value={tempSkill}
              style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
              onChange={(e) => setTempSkill(e.target.value)}
              onFocus={(e) => (e.target.style.border = "2px solid #525252")}
              onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
            />
            <div className="input-group-append">
              <button
                onClick={addSkill}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                  top: "27%",
                }}
                className="  px-3  bg-white rounded-3 fw-sm-medium fs-sm py-auto border-0 "
              >
                add skill
              </button>
            </div>
          </div>
          <div>
            {skillsArr.map((skill, index) => {
              return (
                <div
                  key={index}
                  className="bg-info  rounded-4 ps-3 pe-2  py-2  d-inline-block fs-sm m-1"
                >
                  {skill}

                  <MdCancel
                    className="bg-info-subtle mx-1 rounded-circle  "
                    style={{ cursor: "pointer" }}
                    onClick={() => removeSkill(index)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-3">
          <div className=" fs-xs  ">User Experience</div>

          <select
            className=" w-100  mt-1 py-2   fs-sm px-1"
            style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
            onChange={(e) => setExperience(e.target.value)}
            value={experience}
          >
            {experienceOptions.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>

        {/* <div className="mt-3">
          <div className=" fs-xs ">Available time range </div>

          <div className="d-flex gap-2">
            <input
              className="w-100  mt-1 py-2   fs-sm px-2"
              value={tempStartTime}
              placeholder="start time"
              style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
              onChange={(e) => setTempStartTime(e.target.value)}
              onFocus={(e) => (e.target.style.border = "2px solid #525252")}
              onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
            />
            <input
              className="w-100  mt-1 py-2   fs-sm px-2"
              value={tempEndTime}
              placeholder="end time"
              style={{ border: "2px solid #DADBDA", borderRadius: "14px" }}
              onChange={(e) => setTempEndTime(e.target.value)}
              onFocus={(e) => (e.target.style.border = "2px solid #525252")}
              onBlur={(e) => (e.target.style.border = "2px solid #DADBDA")}
            />
          </div>
        </div> */}

        <div className=" d-flex  justify-content-end  my-4">
          {updatingProfileData ? (
            <button
              onClick={updateProfile}
              className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3  col-sm-auto  col-12 py-2   mb-4 px-4  `}
            >
              {/* <img
              src="/assets/Eclipse@1x-1.0s-200px-200px.svg"
              ></img> */}
              {/* Updating... */}
              <img
                src="/Rolling@1x-1.1s-200px-200px.svg"
                alt="loading.."
                style={{
                  width: "20px",
                  height: "20px",
                }}
              ></img>
            </button>
          ) : (
            <button
              onClick={updateProfile}
              className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12   mb-4  `}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
