import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const renderTooltip = (props, text) => (
  <Tooltip id="button-tooltip rounded-pill" {...props}>
    {text}
  </Tooltip>
);

const SidebarBtn = ({
  renderTooltipTest,
  to,
  text,
  iconeName,
  isSidebarOpen,
  iconeFolder,
  isTemparySidebarOpen,
}) => {
  const isMobile = window.innerWidth <= 768; // Set your mobile breakpoint here
  const tooltipPlacement = isMobile ? "bottom" : "right";
  const location = useLocation();
  
  const linkContent = (
    <Link
      to={to}
      className={
        location?.pathname === to
          ? "focus-bar-item-active"
          : "focus-bar-item"
      }
    >
      <div className="d-flex gap-2 justify-content-left align-items-center">
        <img
          src={`/icons/${iconeFolder}/${iconeName}`}
          alt="icon"
          style={{ height: "24px", width: "24px" }}
        />
        <div
          style={{
            fontSize: "16px",
          }}
          className="d-block d-lg-block d-md-none"
        >
          {isSidebarOpen && isTemparySidebarOpen && text}
        </div>
      </div>
    </Link>
  );

  return (
    <div className="col-12 mt-2">
      {renderTooltipTest ? (
        <OverlayTrigger
          placement={tooltipPlacement}
          overlay={(props) => renderTooltip(props, renderTooltipTest)}
        >
          {linkContent}
        </OverlayTrigger>
      ) : (
        linkContent
      )}
    </div>
  );
};

export default SidebarBtn;
