import React from "react";
import styles from "./../css/SubscriptionNewModal.module.css";
// import { useUpdateFirstSignIn } from "../hooks/useUpdateFirstSignIn";
// import { Link, useNavigate } from "react-router-dom";
import { useGetMe } from "../hooks/useGetMe";
import { useUpdateFirstSignIn } from "../hooks/useUpdateFirstSignIn";
import { updateUserFirstSignIn } from "../services/userApi";
import Star from "./Star";

const SubscriptionModal = ({
  show,
  handleClosebtn,
  handleSuccess,
  title,
  children,
}) => {
  // const { updateFirstSignIn, isPending } = useUpdateFirstSignIn();
  //   const navigate = useNavigate();

  const { myData } = useGetMe();

  // async function handleClosebtn() {
  //   handleClose();
  //   const update = await updateUserFirstSignIn();
  // }

  // const handleSubscription = async () => {
  //   const update = await updateUserFirstSignIn();
  //   navigateToSubscription();
  // };

  return (
    <div className={`${styles.modal} ${show ? styles.show : ""}`}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h5 className={styles.modalTitle}>{title}</h5>
        </div>
        <div className={styles.modalBody}>
          {children}
          {/* <ul>
            <li>Feature 1</li>
            <li>Feature 2</li>
            <li>Feature 3</li>
            <li>And many more...</li>
          </ul> */}
        </div>
        <div className={` w-100 justify-content-end ${styles.modalFooter}`}>
          <div className="">
            <button
              onClick={() => handleSuccess()}
              className=" bg-primary text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0"
              //   style={{ backgroundColor: "#126e12" }}
            >
              {/* <Link to={`${}`}> */}
              <div className="mx-1">Subscription Tier</div>
              {/* </Link> */}
            </button>{" "}
            <button
              onClick={() => handleClosebtn()}
              className=" text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0"
              style={{ backgroundColor: "#dc3545" }}
            >
              <div className="mx-1">close</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
