import ThemeSelectingComponent from "./ThemeSelectingComponent";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateTheme } from "../../services/apiSetting";
import { getMe } from "../../services/candidateApi";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import CustomizedSpinner from "../../ui/CustomizedSpinner";

function Theme() {
  const { data: myData, isPending: gettingMe } = useQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
  });

  const themes = [
    "primary",
    "success",
    "danger",
    "light",
    "warning",
    "secondary",
    "dark",
  ];
  const [selectedTheme, setSelectedTheme] = useState(myData?.theme);
  const saveTheme = async () => {
    const data = {
      theme: selectedTheme,
    };
    updateThemeData({ data });
  };

  const queryClient = useQueryClient();
  const { mutate: updateThemeData, isPending: updatingThemeData } = useMutation(
    {
      mutationFn: ({ data }) => updateTheme(data),
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ["getMe"],
        });
        // toast.success("Theme Updated Successfully");
      },
      onError: (error) => {
        toast.error("Error during update theme: ", error);
      },
    }
  );
  {
    gettingMe && <CustomizedSpinner />;
  }

  return (
    <div>
      <div className="">Select a theme as your preference</div>
      <div className="container pt-4">
        <div className="row">
          {themes.map((theme, index) => (
            <ThemeSelectingComponent
              key={index}
              themeColor={theme}
              isSelected={theme === selectedTheme}
              setSelectedTheme={setSelectedTheme}
            />
          ))}
        </div>
      </div>

      <div className=" d-flex  justify-content-end  mt-4">
        {updatingThemeData ? (
          <button
            className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0`}
          >
            <img
              src="/Rolling@1x-1.1s-200px-200px.svg"
              alt="loading.."
              style={{
                width: "20px",
                height: "20px",
              }}
            ></img>
          </button>
        ) : (
          <button
            onClick={saveTheme}
            className={`bg-${myData.theme} text-white fs-xs border-0 rounded-3 py-2 px-4 col-sm-auto  col-12 fixed-sm-bottom  mb-4  mx-auto mx-sm-0`}
          >
            <div className="mx-1">Save</div>
          </button>
        )}
      </div>
    </div>
  );
}

export default Theme;
