import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

// import UserChat from "../features/chat/UesrChat";
import UserChat from "./../features/chat/UesrChat";
import UserConversation from "../features/chat/UserConversation";
import UserMobileConversation from "../features/chat/UserMobileConversation";
import UserMobileChat from "../features/chat/UserMobileChat";

const Chat = ({ children }) => {
  const [id, setId] = useState("");
  return (
    // <div
    //   style={{
    //     height: "50vh",
    //   }}
    // >
    <>
      <div
        style={{
          height: "88vh",
        }}
        className=" overflow-hidden  d-sm-none d-md-block d-none d-lg-block"
      >
        <Row
          style={{
            height: "92%",
          }}
        >
          <Col md={4} lg={4}>
            <UserChat />
          </Col>
          <Col
            lg={8}
            md={8}
            style={{
              height: "100%",
            }}
          >
            {children}
          </Col>
        </Row>
      </div>

      <div
        style={{
          height: "88vh",
        }}
        className=" overflow-hidden  d-sm-block d-md-none d-block d-lg-none"
      >
        <Row
          style={{
            height: "92%",
          }}
        >
          {id ? (
            <Col md={4} lg={4}>
              <UserMobileConversation setId={setId} id={id} />
            </Col>
          ) : (
            <Col md={4} lg={4}>
              <UserMobileChat setId={setId} />
            </Col>
          )}
          {/* <Col md={4} lg={4}>
            <UserMobileChat setId={setId} />
          </Col>

          <Col md={4} lg={4}>
            <UserMobileConversation id={id} />
          </Col> */}

          {/* <Col
            lg={8}
            md={8}
            style={{
              height: "100%",
            }}
          >
            {children}
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default Chat;
