import React, { useState, useEffect } from "react";
import ChatInterface from "../../components/ChatInterface";
import axios from "axios";
import CustomizedSpinner from "../../ui/CustomizedSpinner";
import { FAST_API_PYTHON_SERVER_URI } from "../../services/api";
import "../../css/CareerCoach.css"; // Adjust the path based on your directory structure

const token = sessionStorage.getItem("authToken");

const CareerCoach = () => {
    const [chatMessages, setChatMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [firstMessageSent, setFirstMessageSent] = useState(false); // New state variable

    const examplePrompts = [
        "I'm not sure what career to pursue",
        "What are some tips for a successful job interview?",
        "Can you help me find job openings in my area?",
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCareerAdvice = async (query) => {
        if (!query) {
            return;
        }

        const updatedHistory = [...conversationHistory, { role: "user", content: query }];

        setChatMessages(prevMessages => [
            ...prevMessages,
            { user: query },
            { bot: "One second please..." },
        ]);
        setLoading(true);
        setFirstMessageSent(true); // Set the state to true after the first message

        try {
            const res = await axios.post(
                `${FAST_API_PYTHON_SERVER_URI}/career-coach`,
                {
                    conversation: updatedHistory,
                    prompt: query,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const advice = res.data.advice;
            const botResponse = { role: "bot", content: advice };
            setConversationHistory([...updatedHistory, botResponse]);

            setChatMessages(prevMessages => [
                ...prevMessages.filter(msg => msg.bot !== "Let me find the best career advice for you..."),
                { bot: advice },
            ]);

        } catch (err) {
            console.error("Error processing request:", err);
            setChatMessages(prevMessages => [
                ...prevMessages,
                {
                    bot: "We encountered an error while processing your request. Please check your connection and try again later.",
                },
            ]);
        } finally {
            setLoading(false);
            setUserInput("");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (userInput.trim()) {
            handleCareerAdvice(userInput);
        }
    };

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                height: "100vh",
                width: "100%",
                padding: "20px",
                boxSizing: "border-box",
            }}
        >
            <div
                className="chat-container"
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflow: "hidden",
                }}
            >
                <ChatInterface
                    messages={chatMessages}
                    onNewMessage={handleCareerAdvice}
                    uploadVisible={false}
                    setChatMessages={setChatMessages}
                    firstMsg="I'm your AI-powered Career Coach. How can I help you?"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyPress={handleSubmit}
                    examplePrompts={examplePrompts} // Pass examplePrompts to ChatInterface
                    firstMessageSent={firstMessageSent} // Pass firstMessageSent to ChatInterface
                />

                {loading && <CustomizedSpinner />}
            </div>
        </div>
    );
};

export default CareerCoach;