import React, { useState, useEffect } from "react";
import ChatInterface from "../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import Trends from "../components/Trends";
import Spinner from "../ui/Spinner";
import { newsData } from "../services/candidateApi";
import "../css/JobSearch.css";
import { useQuery } from "@tanstack/react-query";
import CustomizedSpinner from "../ui/CustomizedSpinner";
import ChatInterfaceWithoutUpload from "../components/ChatInterfaceWithoutUpload";
import { useGetMe } from "../hooks/useGetMe";

const JobSearch = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [awaitingNextAction, setAwaitingNextAction] = useState(false);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const [uploadVisible, setUploadVisible] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { data: jobNews, isPending: isLoading } = useQuery({
    queryKey: ["news", "job news"],
    queryFn: newsData,
  });

  const { myData, isPending } = useGetMe();
  if (isLoading) return <CustomizedSpinner />;

  // const handleFilesUploaded = (uploadedFiles) => {
  //   setFiles([...files, ...Array.from(uploadedFiles)]);
  //   setChatMessages([
  //     ...chatMessages,
  //     {
  //       bot: "Thank you for uploading! Now, please specify the role you're interested in for job listings tailored to your profile.",
  //     },
  //   ]);
  //   setShowFileUpload(false);
  // };

  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };

  const handleJobSearch = async (query) => {
    const token = sessionStorage.getItem("authToken");
    if (!query || query.length < 2) {
      return;
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("cvs", files[i]);
    }
    setChatMessages([...chatMessages, { bot: "searching for jobs..." }]);
    setLoading(true);

    try {
      const res = await axios.post(
        `${baseUrl}/files/job/candidate/?query=${query}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const jobQuery = res.data.data;
      // const insights = res.data.salary_insights;
      // console.log("Salary insights:", insights[roles]);
      // setSalaryInsights(insights);
      // setInitialAdviceContext(insights);
      // setConversationHistory([
      //   ...conversationHistory,
      //   { user: roles, bot: insights[roles] },
      // ]);

      const length = jobQuery.length;

      const botReply = (
        <div className="job-info">
          {jobQuery && (
            <div className="container">
              {jobQuery.map((job) => (
                <div key={job.job_id} className="card mb-3">
                  <div className="card-body">
                    <h3 className="card-title">
                      {isMobile?(job.job_title.slice(0,20)):job.job_title}{isMobile && job.job_title.length > 20 && "..."} - {job.employer_name}
                      
                    </h3>

                    {job.employer_logo && (
                      <img
                        src={job.employer_logo}
                        alt={`${job.employer_name} logo`}
                        className="img-fluid rounded mb-2"
                        style={{ width: "100px" }}
                      />
                    )}
                    <p className=" fs-sm">
                      <strong>Description:</strong>{" "}
                      {job.job_description
                        ? job.job_description.split("\n").map((line, index) => (
                            <span key={index}>
                              {line}
                              <br />
                            </span>
                          ))
                        : "No description available."}
                    </p>
                    <p className=" fs-sm">
                      <strong>Type:</strong> {job.job_employment_type}
                    </p>
                    <p className=" fs-sm">
                      <strong>Publisher:</strong> {job.job_publisher}
                    </p>
                    <p className=" fs-sm">
                      <strong>Location:</strong>{" "}
                      {job.job_is_remote
                        ? "Remote"
                        : `${job?.job_city ||""}${job?.job_city ?",":''} ${job?.job_state || ""} ${job?.job_state ? ",":''} ${job?.job_country || ""}`}
                    </p>
                    {job.employer_website && (
                      <p>
                        <strong>Website:</strong>{" "}
                        <a href={job.employer_website} className="link-primary">
                          {job.employer_website}
                        </a>
                      </p>
                    )}
                    <p className=" fs-sm">
                      <strong>Is Direct Apply:</strong>{" "}
                      {job.job_apply_is_direct ? "Yes" : "No"}
                    </p>
                    <a
                      href={job.job_apply_link}
                      className={`btn btn-${myData.theme}`}
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );

      setChatMessages([...chatMessages, { user: query }, { bot: botReply }]);
      setLoading(false);
    } catch (err) {
      console.error("Error processing request:", err);
      setChatMessages([
        ...chatMessages,
        {
          bot: "We encountered an error while processing your request. Please check your connection and try again later",
        },
      ]);
      setLoading(false);
    }

    // if (awaitingNextAction) {
    //   handleYesNoResponse(query);
    //   return;
    // }

    // if (!query || query.length < 2) {
    //   return;
    // }

    // query = query.replace(/•|–|—|\*/g, "");

    // setLoading(true);
    // console.log("ashan");
    // console.log(query)

    // try {
    //   const response = await axios.post(
    //     `${baseUrl}/files/job/candidate/?query=${query}`,
    //     FormData,

    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );

    //   // Handle the response as needed
    //   console.log(response.data);

    //   setLoading(false);
    // } catch (error) {
    //   console.error("Error processing request:", error);
    //   setChatMessages([
    //     ...chatMessages,
    //     {
    //       bot: "We encountered an error while processing your request. Please check your connection and try again later",
    //     },
    //   ]);
    //   setLoading(false);
    // }
  };

  const handleYesNoResponse = (userResponse) => {
    if (userResponse.toLowerCase() === "yes") {
      setShowFileUpload(false);
      setChatMessages([
        ...chatMessages,
        { bot: "Please upload another resume." },
      ]);
      setFiles([]);
      setAwaitingNextAction(false);
      setTimeout(() => setShowFileUpload(true), 100);
    } else if (userResponse.toLowerCase() === "no") {
      setChatMessages([
        ...chatMessages,
        { bot: "Thank you for your time, hope you found this service useful!" },
      ]);
      setAwaitingNextAction(false);
    } else {
      setChatMessages([
        ...chatMessages,
        { bot: "Please respond with 'yes' or 'no'." },
      ]);
    }
  };

  return (
    <div
      className="  d-sm-flex justify-content-sm-center  pt-4 pt-xl-3  px-4 w-100  align-content-center "
      style={
        {
          // minHeight: "83vh",
          // height: "87vh",
        }
      }
    >
      <div
        className="  px-sm-5 gap-3 col-12  d-grid align-content-between   "
        style={{
          height: isMobile ? "83.8vh" : "85vh",
          overflowY: "auto",
        }}
      >
        <div
          className={`trends-container ${!newsPanelVisible ? "d-none" : ""}`}
          style={isMobile  ? { height: "100%", overflowY: "scroll" } : null}
        >
          <div className="trendsPageTitle d-flex flex-row justify-content-between">
            <h5 className="trend-page-title d-md-block d-none" >Job Search News (from recruiting API)</h5>
            <a onClick={hideNewsPanel} className="job-news-title-close-btn">
              Close
            </a>
          </div>
          <Trends data={jobNews} />
        </div>
        <div
          className="chat-container"
          style={{
            flex: "2 1 0",
            marginBottom: "0px",
            height: newsPanelVisible ? "40%" : "100%",
          }}
        >
          <ChatInterface
            hideNewsPanel={hideNewsPanel}
            uploadVisible={uploadVisible}
            newsPanelVisible={isMobile && newsPanelVisible}
            setUploadVisible={setUploadVisible}
            messages={chatMessages}
            onNewMessage={(message) => {
              handleJobSearch(message);
            }}
            setChatMessages={setChatMessages}
            onFilesUploaded={(uploadedFiles) => {
              const newMessages = [
                ...chatMessages,
                {
                  bot: "Thank you for uploading! Let AI help you find your next role! Enter the job title you're interested in below to uncover tailored job opportunities.",
                },
              ];
              setChatMessages(newMessages);
              setFiles([...files, ...uploadedFiles]);
              hideNewsPanel();
              setUploadVisible(true);
            }}
            firstMsg={
              <Typewriter
                words={["Please select your resume in PDF form!"]}
                cursor
                cursorStyle="_"
                deleteSpeed={-1}
                typeSpeed={30}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default JobSearch;
